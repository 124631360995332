<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Available Users</span>
    <i class="far fa-times-circle cp" (click)="close()"></i>
</h2>

<div class="scroll-holder">
    <div class="d-flex justify-content-end m-b-15">
        <button type="button" class="ion-theme-btn primary" [disabled]="userIdsArrayToTeams.user_ids.length === 0" (click)="onAddUsersToTeam()">Add Selected Users</button>
    </div>
    <div class="table-responsive">
        <table class="table ion-table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Role</th>
                    <th class="text-end">Select</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="allUsersList.length > 0; else showNoData">
                    <tr *ngFor="let user of allUsersList">
                        <td>{{ user.first_name }} {{ user.last_name }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.country_code }}-{{ user.phone }}</td>
                        <td>
                            {{ user.is_verified ? 'Active' : 'Not Verified'}}
                        </td>
                        <td>
                            <ng-container [ngSwitch]="user.role">
                                <ng-container *ngSwitchCase="'CENTRAL_SUPPORT_EXECUTIVE'">
                                    Customer Support
                                </ng-container>
                                <span *ngSwitchCase="'CLIENT_ADMIN'" class="ion-anchor">
                                    ADMINISTRATOR
                                </span>
                                <span *ngSwitchCase="'TEAM_MANAGER'" class="ion-anchor">
                                    TEAM MANAGER
                                </span>
                            </ng-container>
                        </td>
                        <td class="text-end">
                            <mat-checkbox *ngIf="user.role !== 'CLIENT_ADMIN'" (click)="onCheckUnCheckUsers(user._id)" [checked]="false"></mat-checkbox>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #showNoData>
                    <tr>
                        <td colspan="6" class="text-center">No data available!</td>
                    </tr>
                </ng-template>
            </tbody>
        </table>
    </div>
</div>