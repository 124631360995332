<div class="system-usage-holder">
  <div class="row m-b-20">
    <div class="col-lg">
      <h2 class="section-title m-b-20">
        Today's Missed Calls ({{ totalmissedCalls_rows }})
      </h2>
      <div class="table-holder table-responsive">
        <table class="table ion-table">
          <thead>
            <tr>
              <th style="width: 12%" *ngIf="currentUser.data.user.role === 'SYS_ADMIN'">
                Client
              </th>
              <th style="width: 10%">Teams</th>
              <th style="width: 8%">Job Id</th>
              <th style="width: 12%">Time</th>
              <th style="width: 10%">Caller</th>
              <th style="width: 42%">Location</th>
              <th style="width: 6%; text-align: right" *ngIf="
                  currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE'
                ">
                Call Back
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="todaysMissedCallsData.length > 0; else showNoMissedCallData">
              <ng-container *ngFor="let item of todaysMissedCallsData">
                <tr>
                  <td *ngIf="currentUser.data.user.role === 'SYS_ADMIN'">
                    <ul class="list-data-set">
                      <li *ngFor="let client of item.clients">
                        <span>{{ client.name }}</span>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <ul class="list-data-set">
                      <li *ngFor="let team of item.teams">
                        <span>{{ team.name }}</span>
                      </li>
                    </ul>
                  </td>
                  <td>
                    <span (click)="onNavigateToJobDetails(item.auth_key)" [ngClass]="{ 'ion-anchor': item.auth_key }">
                      {{ item.auth_key ? item.auth_key : "NA" }}
                    </span>
                  </td>
                  <td style="color: red; font-weight: 400">
                    {{ item.time_stamp | date: "short" }}
                  </td>
                  <td style="color: red; font-weight: 400">
                    {{ item.requested_user.full_name }}<br />
                    {{ item.requested_user.user_name === "anonymous@webbasedcall.com" ? "Web Based Call": item.requested_user.user_name}}
                  </td>
                  <td>
                    <div class="data-section m-t-0 m-b-0" *ngIf="
                        item['additional_informaton']['location']['place'] &&
                        item['additional_informaton']['location']['place']
                          .length > 0
                      ">
                      <label style="font-weight: 700">Location</label>
                      <p style="font-weight: 400">
                        {{ item["additional_informaton"]["location"]["place"] }}
                      </p>
                    </div>
                  </td>
                  <td *ngIf="
                      currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE'
                    ">
                    <app-initiatecallfromweb *ngIf="
                        item.requested_user.id !== currentUser.data.user._id &&
                        item.requested_user.role !== 'ANONYMOUS'
                      " [auth_key]="item.auth_key" [callTo]="item['requested_user']['full_name']"
                      [targetUserId]="item['requested_user']['id']"></app-initiatecallfromweb>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <ng-template #showNoMissedCallData>
              <tr>
                <td colspan="9" class="text-center">No missed calls</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-end m-t-20">
        <app-tablepagination (change)="onPageChange($event, 'missedcall')" [totalNumberOfRows]="totalmissedCalls_rows"
          [pageSize]="missedCallDataPayload.page_size"></app-tablepagination>
      </div>
    </div>
  </div>
  <div class="date-filter-search-holder d-flex">
    <div class="row justify-content-between w-100 align-items-end m-r-0">
      <div class="col-lg">
        <h2 class="section-title m-b-10">All transactions</h2>
        <div class="d-flex align-items-center flex-wrap">
          <div class="d-flex align-items-center m-b-10 m-t-10">
            <div class="date-holder d-flex align-items-center">
              <app-custommatdatepicker [calendarType]="'range'" [from]="historyPayload.from" [to]="historyPayload.to"
                (rangeChange)="onDatePickerChange($event)">
              </app-custommatdatepicker>
            </div>
            <div class="search-holder">
              <i class="fas fa-search"></i>
              <input (keyup)="onSearchFilter($event)" type="text" class="search-filter m-l-20"
                placeholder="type to search for transactions..." />
            </div>
          </div>
          <div class="btns-holder">
            <!-- <button [ngClass]="{'active': !isShowingMissedCalls}" class="ion-theme-btn primary m-r-20"
                            (click)="onShowMissedCallsorAllTransaction(true)" type="button">Only Missed Calls</button>
                        <button [ngClass]="{'active': isShowingMissedCalls}" class="ion-theme-btn primary m-r-20"
                            (click)="onShowMissedCallsorAllTransaction(false)" type="button">All Transactions</button> -->
            <button class="ion-theme-btn primary filter-btn" (click)="toggleFilterCollapse()" type="button">
              <app-filtericon></app-filtericon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-lg-3 p-r-0 m-b-10 m-t-10">
        <div class="export-filter d-flex justify-content-end align-items-center">
          <!-- <p class="ion-anchor m-r-30 m-b-0" [routerLink]="['/my-exports']">
            View My Exports
            <span class="alert-badge" *ngIf="exportsYetToDownload > 0">{{
              exportsYetToDownload
              }}</span>
          </p> -->
          <button class="ion-theme-btn primary" (click)="onOpenExportDataDialog()">
            Export Data
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-element-holder" [ngStyle]="{ overflow: collapsed ? 'hidden' : '' }" [@collapse]="collapsed">
    <h3 class="m-b-20">Filter By</h3>
    <div class="row">
      <div class="col-lg-2" *ngIf="currentUser.data.user.role === 'SYS_ADMIN'">
        <app-iontypeahead [isStacked]="true" [dropDownData]="typAheadData" [keyToSend]="'_id'" (userSelectedPills)="
            onGetUserSelectedTypeAheadPills($event, 'clients')
          " (userTypeAheadKey)="onUserTypeAheadSearch($event, 'clients')" [labelText]="'Clients'"
          [placeHolderString]="'Search Clients'"></app-iontypeahead>
      </div>
      <div class="col-lg-2">
        <app-iontypeahead [isStacked]="true" [isDisabled]="historyPayload.client_ids.length === 0"
          [dropDownData]="typAheadData" [keyToSend]="'_id'"
          (userSelectedPills)="onGetUserSelectedTypeAheadPills($event, 'teams')"
          (userTypeAheadKey)="onUserTypeAheadSearch($event, 'teams')" [labelText]="'Teams'"
          [placeHolderString]="'Search Teams'"></app-iontypeahead>
      </div>
      <div class="col-lg-2" *ngIf="
          currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE' ||
          currentUser.data.user.role === 'CLIENT_ADMIN'
        ">
        <app-iontypeahead [isStacked]="true" [isDisabled]="historyPayload.team_ids.length === 0"
          [dropDownData]="typAheadData" [keyToSend]="'id'"
          (userSelectedPills)="onGetUserSelectedTypeAheadPills($event, 'users')"
          (userTypeAheadKey)="onUserTypeAheadSearch($event, 'users')" [labelText]="'Users'"
          [placeHolderString]="'Search Users'"></app-iontypeahead>
      </div>
      <div class="col-lg-2">
        <div class="ion-form-group">
          <label class="label-text">Main Tag</label>
          <select (change)="onChangeTag(true)" [(ngModel)]="mainTagModel">
            <option value="null">Select Main Tag</option>
            <option [value]="onStringifyTagIds(maintag.ids)" *ngFor="let maintag of allMainTagListDropdown">
              {{ maintag.name | titlecase }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-lg-2">
        <div class="ion-form-group">
          <label class="label-text">Sub Tag</label>
          <select [(ngModel)]="subTagModel" (change)="onChangeTag(false)">
            <option value="null">Select Sub Tag</option>
            <option [value]="onStringifyTagIds(subtag.ids)" *ngFor="let subtag of allSubTagListDropdown">
              {{ subtag.name | titlecase }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="checkbox-apply-holder d-flex justify-content-between flex-wrap">
      <ul class="d-flex flex-wrap">
        <li *ngFor="let check of payloadItems" class="m-b-10 m-r-0">
          <mat-checkbox [(ngModel)]="check.checked" [checked]="check.checked"
            (click)="onAddRemovePayloadItems(check.id)">{{ check.name }}
          </mat-checkbox>
        </li>
      </ul>
      <div class="apply-all-holder d-flex">
        <button class="ion-theme-btn primary round sm m-r-20" (click)="onResetFilter()">
          Reset Filter
        </button>
        <button class="ion-theme-btn primary round sm m-r-20" (click)="toggleFilterCollapse()">
          Close
        </button>
        <button class="ion-theme-btn primary round sm" type="button" (click)="onApplyFilter()">
          Apply Filter
        </button>
      </div>
    </div>
  </div>
  <div class="table-holder table-responsive">
    <table class="table ion-table" [ngClass]="{ 'm-t-30': !collapsed }">
      <thead>
        <tr>
          <th style="width: 15%;">Details</th>
          <th style="width: 16%;">Time / Location</th>
          <th style="width: 15%;">Participants</th>
          <th style="width: 10%;">Info</th>
          <th style="width: 22%;">End User Inputs</th>
          <th style="width: 22%;">Customer Support Inputs</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="systemUsage.length > 0; else showNoData">
          <ng-container *ngFor="let item of systemUsage">
            <tr>
              <td>
                <div class="data-section">
                  <label class="d-block">JOB ID</label>
                  <p [ngClass]="{ 'ion-anchor': item.auth_key }" (click)="onNavigateToJobDetails(item.auth_key)">
                    {{ item.auth_key ? item.auth_key : "NA" }}
                  </p>
                </div>
                <div class="data-section" *ngIf="currentUser.data.user.role === 'SYS_ADMIN'">
                  <label class="d-block">CLIENT</label>
                  <div class="d-flex flex-wrap comma-separated-list">
                    <p *ngFor="let client of item.clients">
                      {{ client.name }}<span class="m-r-5">,</span>
                    </p>
                  </div>
                </div>
                <div class="data-section">
                  <label class="d-block">Team</label>
                  <div class="d-flex flex-wrap comma-separated-list">
                    <p *ngFor="let team of item.teams">
                      {{ team.name }}<span class="m-r-5">,</span>
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <div class="data-section">
                  <label class="d-block">Time</label>
                  <p>{{ item.time_stamp | date: "short" }}</p>
                </div>
                <div class="data-section" *ngIf="
                    item['additional_informaton']['location']['place'] &&
                    item['additional_informaton']['location']['place'].length >
                      0">
                  <label class="d-block">location</label>
                  <p>{{ item["additional_informaton"]["location"]["place"] }}</p>
                </div>
              </td>
              <td>
                <div class="data-section">
                  <label class="d-block">{{ (item.type === 'INCOMING_CALL' || item.type === 'OUTGOING_CALL') ? 'Caller'
                    : 'Initiated By' }}</label>
                  <p>
                    {{ item.requested_user.full_name }}<br>
                    {{ item.user_name }}
                  </p>
                </div>
                <div class="data-section" *ngIf="item.accepted_user">
                  <label class="d-block">Answered By</label>
                  <p>
                    {{ item.accepted_user.user_full_name }}<br>
                    <!-- Team - Pending Int -->
                  </p>
                </div>
                <div class="data-section" *ngIf="item.merged_users && item.merged_users.length > 0">
                  <label class="d-block">merged with</label>
                  <p *ngFor="let merged of item.merged_users">
                    {{ merged.user_full_name }}<br>
                    <!-- Team - Pending Int -->
                  </p>
                </div>
              </td>
              <td>
                <div class="data-section m-b-10" *ngIf="item.type === 'INCOMING_CALL' || item.type === 'OUTGOING_CALL'">
                  <label class="d-block">Type</label>
                  <ng-container [ngSwitch]="item.type">
                    <span *ngSwitchCase="'INCOMING_CALL'">Incoming Call</span>
                    <span *ngSwitchCase="'OUTGOING_CALL'">Outgoing Call</span>
                  </ng-container>
                </div>
                <div class="view-actions d-flex flex-column">
                  <ng-container [ngSwitch]="item.type">
                    <ng-container *ngSwitchCase="'INCOMING_CALL'">
                      <ng-container [ngSwitch]="item.additional_informaton.is_attended">
                        <ng-container *ngSwitchCase="true">
                          <ul class="action-items flex-column">
                            <li *ngIf="
                                                item.additional_informaton.artifact.recording
                                                  .count > 0
                                              ">
                              <span class="ion-anchor" (click)="
                                                  onViewCallInformations(
                                                    'View Recordings',
                                                    item.additional_informaton.artifact
                                                      .recording.urls,
                                                    'RECORDINGS',
                                                    item.id
                                                  )
                                                ">
                                {{
                                item.additional_informaton.artifact.recording
                                .count
                                }}
                                Recording{{
                                item.additional_informaton.artifact.recording
                                .count === 1
                                ? ""
                                : "s"
                                }}
                              </span>
                            </li>
                            <li *ngIf="
                                                item.additional_informaton.artifact.snapshot
                                                  .count > 0
                                              ">
                              <span class="ion-anchor" (click)="
                                                  onViewCallInformations(
                                                    'View Screenshots',
                                                    item.additional_informaton.artifact.snapshot
                                                      .urls,
                                                    'SCREENSHOTS'
                                                  )
                                                ">
                                {{
                                item.additional_informaton.artifact.snapshot
                                .count
                                }}
                                Screenshot{{
                                item.additional_informaton.artifact.snapshot
                                .count === 1
                                ? ""
                                : "s"
                                }}
                              </span>
                            </li>
                          </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                          <span style="color: red">Missed</span>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'OUTGOING_CALL'">
                      <ng-container [ngSwitch]="item.additional_informaton.is_attended">
                        <ng-container *ngSwitchCase="true">
                          <ul class="action-items flex-column">
                            <li *ngIf="
                                                item.additional_informaton.artifact.recording
                                                  .count > 0
                                              ">
                              <span class="ion-anchor" (click)="
                                                  onViewCallInformations(
                                                    'View Recordings',
                                                    item.additional_informaton.artifact
                                                      .recording.urls,
                                                    'RECORDINGS',
                                                    item.id
                                                  )
                                                ">
                                {{
                                item.additional_informaton.artifact.recording
                                .count
                                }}
                                Recording{{
                                item.additional_informaton.artifact.recording
                                .count === 1
                                ? ""
                                : "s"
                                }}
                              </span>
                            </li>
                            <li *ngIf="
                                                item.additional_informaton.artifact.snapshot
                                                  .count > 0
                                              ">
                              <span class="ion-anchor" (click)="
                                                  onViewCallInformations(
                                                    'View Screenshots',
                                                    item.additional_informaton.artifact.snapshot
                                                      .urls,
                                                    'SCREENSHOTS'
                                                  )
                                                ">
                                {{
                                item.additional_informaton.artifact.snapshot
                                .count
                                }}
                                Screenshot{{
                                item.additional_informaton.artifact.snapshot
                                .count === 1
                                ? ""
                                : "s"
                                }}
                              </span>
                            </li>
                          </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                          <ul class="action-items">
                            <li>
                              <span style="color: red">Missed</span>
                            </li>
                          </ul>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ul *ngIf="item.additional_informaton.chat_found" class="action-items flex-column">
                      <li>
                        <span class="ion-anchor" (click)="
                                            onViewCallInformations(
                                              'Chats',
                                              null,
                                              'CHAT',
                                              item.id
                                            )
                                          ">
                          Chat
                        </span>
                      </li>
                    </ul>
                    <ng-container *ngSwitchCase="'VIDEO'">
                      <ul class="action-items flex-column">
                        <li *ngIf="item.additional_informaton.artifact.count > 0">
                          <span class="ion-anchor" (click)="
                                              onViewCallInformations(
                                                'View Video',
                                                item.additional_informaton.artifact.urls,
                                                'VIDEO'
                                              )
                                            ">
                            {{ item.additional_informaton.artifact.count }}
                            {{
                            item.additional_informaton.artifact.type ===
                            "defective"
                            ? "Issue"
                            : (item.additional_informaton.artifact.type
                            | titlecase)
                            }}{{
                            item.additional_informaton.artifact.count > 1
                            ? "s"
                            : ""
                            }}
                          </span>
                        </li>
                      </ul>
                    </ng-container>
                    <ng-container *ngSwitchCase="'IMAGE'">
                      <ul class="action-items flex-column">
                        <li *ngIf="item.additional_informaton.artifact.count > 0">
                          <span class="ion-anchor" (click)="
                                              onViewCallInformations(
                                                'View Images',
                                                item.additional_informaton.artifact.urls,
                                                'IMAGE'
                                              )
                                            ">
                            {{ item.additional_informaton.artifact.count }}
                            {{
                            item.additional_informaton.artifact.type ===
                            "defective"
                            ? "Issue"
                            : (item.additional_informaton.artifact.type
                            | titlecase)
                            }}{{
                            item.additional_informaton.artifact.count > 1
                            ? "s"
                            : ""
                            }}
                          </span>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
              <td>
                <div class="data-section"
                  *ngIf="item.additional_informaton.advanced_tag && item.additional_informaton.advanced_tag !== null">
                  <label class="d-block">Category/Sub Category</label>
                  <!-- <label class="d-block" style="font-weight: 400; font-size: 12px;">{{
                    item.additional_informaton.advanced_tag.main }}</label>
                  <p (click)="onViewCallInformations('View Item', item.additional_informaton.artifact.urls, item.type)"
                    class="ion-anchor">
                    {{ item.additional_informaton.advanced_tag.sub }}
                  </p> -->
                  <div class="ion-anchor"
                    (click)="onViewCallInformations('View Item', item.additional_informaton.artifact.urls, item.type)">
                    {{ item.additional_informaton.advanced_tag.main }} / {{
                    item.additional_informaton.advanced_tag.sub }}
                  </div>
                </div>
                <ng-container *ngFor="let comment of item['additional_informaton']['comments']">
                  <div class="data-section" *ngIf="comment.comment.length > 0">
                    <label for="">Comments</label>
                    <p>{{ comment.comment }}</p>
                  </div>
                </ng-container>
              </td>
              <td>
                <ng-container *ngIf="item.type === 'IMAGE' || item.type === 'VIDEO'">
                  <div class="d-flex justify-content-end add-edit-btn-holder">
                    <button
                      *ngIf="currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE' && item['additional_informaton']['feedbacks'] === null"
                      class="ion-theme-btn primary sm add"
                      (click)="onGetCSGrades(item.teams[item.teams.length - 1]['id'], $event, item.type, item['id'])">
                      <i class="fas fa-plus"></i>
                    </button>
                  </div>
                  <form class="d-none" [formGroup]="attachGradeToTransactionForm"
                    (ngSubmit)="onAttachGradeToTransaction()">
                    <div class="ion-form-group m-b-10">
                      <div class="dropdown-holder">
                        <div class="current-value" [matMenuTriggerFor]="categoryDropdown">
                          <p>{{ selectedCSCategory }}</p>
                          <div class="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.967" height="6.884"
                              viewBox="0 0 11.967 6.884">
                              <path id="icon_dropdown_arrow" d="M-3.289,0-8,4.711l4.711,4.711"
                                transform="translate(1.273 -2.016) rotate(-90)" fill="none" stroke="#4a85c5"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                            </svg>
                          </div>
                        </div>
                        <mat-menu class="profile-dropdown-items" #categoryDropdown="matMenu" [overlapTrigger]="false"
                          yPosition="below" xPosition="before">
                          <button mat-menu-item
                            (click)="onChooseCSGradeCategory(null, 'Select Category', item.type, item.id)">
                            Select Category
                          </button>
                          <button *ngFor="let category of gradeCategory"
                            (click)="onChooseCSGradeCategory(category['_id'], category['display_en'], item.type, item.id)"
                            mat-menu-item>
                            {{ category['display_en'] }}
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                    <div class="ion-form-group m-b-10"
                      [ngClass]="{'disabled': selectedCSCategory === 'Select Category'}">
                      <div class="dropdown-holder">
                        <div class="current-value" [matMenuTriggerFor]="subCategoryDropdown">
                          <p>{{ selectedCSSubCategory }}</p>
                          <div class="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="11.967" height="6.884"
                              viewBox="0 0 11.967 6.884">
                              <path id="icon_dropdown_arrow" d="M-3.289,0-8,4.711l4.711,4.711"
                                transform="translate(1.273 -2.016) rotate(-90)" fill="none" stroke="#4a85c5"
                                stroke-linecap="round" stroke-linejoin="round" stroke-width="1.8" />
                            </svg>
                          </div>
                        </div>
                        <mat-menu class="profile-dropdown-items" #subCategoryDropdown="matMenu" [overlapTrigger]="false"
                          yPosition="below" xPosition="before">
                          <button *ngFor="let subcat of gradeSubCategory"
                            (click)="onSelectCSSubCategory(subcat['_id'], subcat['display_en'])" mat-menu-item>
                            {{ subcat['display_en'] }}
                          </button>
                        </mat-menu>
                      </div>
                    </div>
                    <div class="ion-form-group m-b-10">
                      <textarea placeholder="Comments" formControlName="comment"
                        style="height: 70px; resize: none; font-weight: 600;"></textarea>
                    </div>
                    <!-- <div class="ion-form-group m-b-30" style="margin-top: -10px;">
                      <bar-rating [max]="5" formControlName="rating"></bar-rating>
                    </div> -->
                    <div class="d-flex">
                      <button type="submit" [disabled]="isSaveGradeDisable"
                        class="ion-theme-btn primary sm m-r-10">SAVE</button>
                      <button type="button" class="ion-theme-btn primary sm outline"
                        (click)="onCancelGradingForm($event)">CANCEL</button>
                    </div>
                  </form>
                </ng-container>
                <div class="data-section">
                  <ng-container *ngIf="item.additional_informaton.feedbacks">
                    <ng-container *ngIf="item.additional_informaton.feedbacks['grades'].length > 0">
                      <label class="d-block">Category/Sub Category</label>
                      <div class="grades">
                        <ng-container *ngFor="let grade of item.additional_informaton.feedbacks['grades']">{{
                          grade.grade }}<span> / </span></ng-container>
                      </div>
                      <span class="d-block m-b-10"
                        *ngIf="item.additional_informaton.feedbacks.comment && item.additional_informaton.feedbacks.comment.length > 0"></span>
                    </ng-container>
                  </ng-container>
                  <ng-container *ngIf="item.additional_informaton.feedbacks">
                    <ng-container
                      *ngIf="item.additional_informaton.feedbacks.comment && item.additional_informaton.feedbacks.comment.length > 0">
                      <label class="d-block">Comment</label>
                      {{ item.additional_informaton.feedbacks.comment }}
                    </ng-container>
                  </ng-container>
                  <span style="opacity: 0.65;" class="d-block"
                    *ngIf="item.additional_informaton.feedbacks && (item.additional_informaton.feedbacks['grades'].length > 0 || (item.additional_informaton.feedbacks.comment && item.additional_informaton.feedbacks.comment.length > 0))">
                    {{ item.additional_informaton.feedbacks.user.full_name }},<br>
                    {{ item.additional_informaton.feedbacks.time_stamp | date:'medium' }}
                  </span>
                </div>
                <div class="d-flex justify-content-end add-edit-btn-holder"
                  *ngIf="item.type === 'IMAGE' || item.type === 'VIDEO'">
                  <button
                    (click)="onEditCSGrades(item.teams[item.teams.length - 1]['id'], $event, item.additional_informaton, item.type, item['id'])"
                    type="button" class="ion-theme-btn primary sm outline edit"
                    *ngIf="currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE' && item['additional_informaton']['feedbacks'] !== null">
                    <i class="fas fa-edit"></i>
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-template #showNoData>
          <tr>
            <td colspan="6" class="text-center">No transactions available</td>
          </tr>
        </ng-template>
      </tbody>
    </table>
    <!-- <table class="table ion-table" [ngClass]="{ 'm-t-30': !collapsed }">
      <thead>
        <tr>
          <th style="width: 12%" *ngIf="currentUser.data.user.role === 'SYS_ADMIN'">
            Client
          </th>
          <th style="width: 10%">Teams</th>
          <th style="width: 8%">Job Id</th>
          <th style="width: 12%">Time</th>
          <th style="width: 10%">Type</th>
          <th style="width: 18%">Caller</th>
          <th style="width: 11%">Participants</th>
          <th style="width: 16%">Comments</th>
          <th style="width: 10%">Info</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="systemUsage.length > 0; else showNoData">
          <ng-container *ngFor="let item of systemUsage">
            <tr>
              <td *ngIf="currentUser.data.user.role === 'SYS_ADMIN'">
                <ul class="list-data-set">
                  <li *ngFor="let client of item.clients">
                    <span>{{ client.name }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ul class="list-data-set">
                  <li *ngFor="let team of item.teams">
                    <span>{{ team.name }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <span (click)="onNavigateToJobDetails(item.auth_key)" [ngClass]="{ 'ion-anchor': item.auth_key }">
                  {{ item.auth_key ? item.auth_key : "NA" }}
                </span>
              </td>
              <td>{{ item.time_stamp | date: "short" }}</td>
              <td>
                <ng-container [ngSwitch]="item.type">
                  <span *ngSwitchCase="'INCOMING_CALL'">Incoming Call</span>
                  <span *ngSwitchCase="'OUTGOING_CALL'">Outgoing Call</span>
                  <span *ngSwitchCase="'IMAGE'">Image</span>
                  <span *ngSwitchCase="'VIDEO'">Video</span>
                </ng-container>
              </td>
              <td>
                {{ item.requested_user.full_name }}<br />
                {{ item.requested_user.user_name }}
                <div class="data-section m-t-5" *ngIf="
                    item['additional_informaton']['location']['place'] &&
                    item['additional_informaton']['location']['place'].length >
                      0
                  ">
                  <label style="font-weight: 700">Location</label>
                  <p style="font-weight: 400">
                    {{ item["additional_informaton"]["location"]["place"] }}
                  </p>
                </div>
              </td>
              <td>
                <ul class="list-data-set">
                  <li *ngFor="let user of item.accepted_users">
                    <span>{{ user.user_full_name }}</span>
                  </li>
                </ul>
              </td>
              <td>
                <ng-container *ngFor="let comment of item['additional_informaton']['comments']">
                  <div class="data-section" *ngIf="comment.comment.length > 0">
                    <p class="">
                      <span style="font-weight: 400;">{{ comment.comment }}</span>
                    </p>
                    <label style="opacity: 0.6;">
                      {{ comment.user.full_name === ' ' ? comment.user.user_name : comment.user.full_name }}, {{
                      comment.time_stamp | date:'short' }}
                    </label>
                  </div>
                </ng-container>
                <p *ngIf="item['additional_informaton']['comments'].length > 6">
                  <span class="ion-anchor" (click)="onViewCallInformations(
                              'Comments',
                              item['additional_informaton']['comments'],
                              'COMMENT')">View More</span>
                </p>
              </td>
              <td>
                <div class="view-actions d-flex flex-column">
                  <ng-container [ngSwitch]="item.type">
                    <ng-container *ngSwitchCase="'INCOMING_CALL'">
                      <ng-container [ngSwitch]="item.additional_informaton.is_attended">
                        <ng-container *ngSwitchCase="true">
                          <ul class="action-items flex-column">
                            <li *ngIf="
                                item.additional_informaton.artifact.recording
                                  .count > 0
                              ">
                              <span class="ion-anchor" (click)="
                                  onViewCallInformations(
                                    'View Recordings',
                                    item.additional_informaton.artifact
                                      .recording.urls,
                                    'RECORDINGS',
                                    item.id
                                  )
                                ">
                                {{
                                item.additional_informaton.artifact.recording
                                .count
                                }}
                                Recording{{
                                item.additional_informaton.artifact.recording
                                .count === 1
                                ? ""
                                : "s"
                                }}
                              </span>
                            </li>
                            <li *ngIf="
                                item.additional_informaton.artifact.snapshot
                                  .count > 0
                              ">
                              <span class="ion-anchor" (click)="
                                  onViewCallInformations(
                                    'View Screenshots',
                                    item.additional_informaton.artifact.snapshot
                                      .urls,
                                    'SCREENSHOTS'
                                  )
                                ">
                                {{
                                item.additional_informaton.artifact.snapshot
                                .count
                                }}
                                Screenshot{{
                                item.additional_informaton.artifact.snapshot
                                .count === 1
                                ? ""
                                : "s"
                                }}
                              </span>
                            </li>
                          </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                          <span style="color: red">Missed</span>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'OUTGOING_CALL'">
                      <ng-container [ngSwitch]="item.additional_informaton.is_attended">
                        <ng-container *ngSwitchCase="true">
                          <ul class="action-items flex-column">
                            <li *ngIf="
                                item.additional_informaton.artifact.recording
                                  .count > 0
                              ">
                              <span class="ion-anchor" (click)="
                                  onViewCallInformations(
                                    'View Recordings',
                                    item.additional_informaton.artifact
                                      .recording.urls,
                                    'RECORDINGS',
                                    item.id
                                  )
                                ">
                                {{
                                item.additional_informaton.artifact.recording
                                .count
                                }}
                                Recording{{
                                item.additional_informaton.artifact.recording
                                .count === 1
                                ? ""
                                : "s"
                                }}
                              </span>
                            </li>
                            <li *ngIf="
                                item.additional_informaton.artifact.snapshot
                                  .count > 0
                              ">
                              <span class="ion-anchor" (click)="
                                  onViewCallInformations(
                                    'View Screenshots',
                                    item.additional_informaton.artifact.snapshot
                                      .urls,
                                    'SCREENSHOTS'
                                  )
                                ">
                                {{
                                item.additional_informaton.artifact.snapshot
                                .count
                                }}
                                Screenshot{{
                                item.additional_informaton.artifact.snapshot
                                .count === 1
                                ? ""
                                : "s"
                                }}
                              </span>
                            </li>
                          </ul>
                        </ng-container>
                        <ng-container *ngSwitchCase="false">
                          <ul class="action-items">
                            <li>
                              <span style="color: red">Missed</span>
                            </li>
                          </ul>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                    <ul *ngIf="item.additional_informaton.chat_found" class="action-items flex-column">
                      <li>
                        <span class="ion-anchor" (click)="
                            onViewCallInformations(
                              'Chats',
                              null,
                              'CHAT',
                              item.id
                            )
                          ">
                          Chat
                        </span>
                      </li>
                    </ul>
                    <ng-container *ngSwitchCase="'VIDEO'">
                      <ul class="action-items flex-column">
                        <li *ngIf="item.additional_informaton.artifact.count > 0">
                          <span class="ion-anchor" (click)="
                              onViewCallInformations(
                                'View Video',
                                item.additional_informaton.artifact.urls,
                                'VIDEO'
                              )
                            ">
                            {{ item.additional_informaton.artifact.count }}
                            {{
                            item.additional_informaton.artifact.type ===
                            "defective"
                            ? "Issue"
                            : (item.additional_informaton.artifact.type
                            | titlecase)
                            }}{{
                            item.additional_informaton.artifact.count > 1
                            ? "s"
                            : ""
                            }}<br />
                            <span *ngIf="
                                item.additional_informaton.advanced_tag &&
                                item.additional_informaton.advanced_tag !== null
                              " style="
                                font-weight: 400;
                                display: block;
                                line-height: 18px;
                              ">{{
                              item.additional_informaton.advanced_tag.main
                              }}/{{
                              item.additional_informaton.advanced_tag.sub
                              }}</span>
                          </span>
                        </li>
                      </ul>
                    </ng-container>
                    <ng-container *ngSwitchCase="'IMAGE'">
                      <ul class="action-items flex-column">
                        <li *ngIf="item.additional_informaton.artifact.count > 0">
                          <span class="ion-anchor" (click)="
                              onViewCallInformations(
                                'View Images',
                                item.additional_informaton.artifact.urls,
                                'IMAGE'
                              )
                            ">
                            {{ item.additional_informaton.artifact.count }}
                            {{
                            item.additional_informaton.artifact.type ===
                            "defective"
                            ? "Issue"
                            : (item.additional_informaton.artifact.type
                            | titlecase)
                            }}{{
                            item.additional_informaton.artifact.count > 1
                            ? "s"
                            : ""
                            }}<br />
                            <span *ngIf="
                                item.additional_informaton.advanced_tag &&
                                item.additional_informaton.advanced_tag !== null
                              " style="
                                font-weight: 400;
                                display: block;
                                line-height: 18px;
                              ">{{
                              item.additional_informaton.advanced_tag.main
                              }}/{{
                              item.additional_informaton.advanced_tag.sub
                              }}</span>
                          </span>
                        </li>
                      </ul>
                    </ng-container>
                  </ng-container>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
        <ng-template #showNoData>
          <tr>
            <td colspan="9" class="text-center">No transactions available</td>
          </tr>
        </ng-template>
      </tbody>
    </table> -->
  </div>
  <div class="d-flex justify-content-end m-t-20">
    <app-tablepagination (change)="onPageChange($event, 'allcalls')" [totalNumberOfRows]="total_rows"
      [pageSize]="historyPayload.page_size"></app-tablepagination>
  </div>
</div>