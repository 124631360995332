import { UserInfo } from './../../routes/account/models/user.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsertokenService {

  public currentUserSubject: BehaviorSubject<UserInfo>;
  public currentUser: Observable<UserInfo>;

  constructor() { 
    let userData=JSON.parse(sessionStorage.getItem('userInfo'));
    if(userData){
      console.log("jabcs",userData);
      let user = userData.data.user_token ? JSON.parse(atob(userData.data.user_token.split('.')[1])) : {
        user_verified: null,
        _id: null,
        user_name: null,
        email: null,
        permission_profile: null,
        role: null,
        user_name_type: null,
        client_id: null,
        first_name: null,
        last_name: null,
        language_preference: {
          code: null,
        },
      };
      userData={...userData,data:{...userData.data,user}}
    }
    this.currentUserSubject = new BehaviorSubject<UserInfo>(userData);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): UserInfo {
    return this.currentUserSubject.value;
  }
}