import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { UserInfo } from 'src/app/routes/account/models/user.model';
import { ManageclientsService } from 'src/app/shared/http/manageclients.service';
import { ManageteamsService } from 'src/app/shared/http/manageteams.service';
import { ManagetransactionsService } from 'src/app/shared/http/managetransactions.service';
import { UsertokenService } from 'src/app/shared/http/usertoken.service';

@Component({
  selector: 'app-remaptransactiondialog',
  templateUrl: './remaptransactiondialog.component.html',
  styleUrls: ['./remaptransactiondialog.component.scss']
})
export class RemaptransactiondialogComponent implements OnInit {

  public receivedData = {};
  public currentUser: UserInfo;
  public clientListData = [];
  public teamListData = [];
  public remapForm: UntypedFormGroup;
  public isDisableConfirmBtn = true;
  public validateMsg = '';

  constructor(
    public dialogRef: MatDialogRef<RemaptransactiondialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedData,
    private userTokenSVC: UsertokenService,
    private manageClientsSVC: ManageclientsService,
    private toastr: ToastrService,
    private manageTransactionSVC: ManagetransactionsService
  ) {
    this.receivedData = ReceivedData;
    this.remapForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.receivedData['id']),
      to_client_id: new UntypedFormControl(null),
      to_team_id: new UntypedFormControl(null)
    })
    this.userTokenSVC.currentUser.subscribe(x => this.currentUser = x);
    if (this.currentUser.data.user.role === 'SYS_ADMIN') {
      this.onGetClientsDropdown();
    } else {
      let teamPayload = {
        client_ids: [this.receivedData['clientId']],
        search: ''
      }
      this.onGetTeamsDropdown(teamPayload);
    }
  }

  ngOnInit(): void {
  }

  close(isRefresh: boolean) {
    this.dialogRef.close(isRefresh);
  }

  onGetClientsDropdown(): void {
    this.manageClientsSVC.onGetClientsDropdown('').subscribe(res => {
      this.clientListData = [...res['data']];
    })
  }

  onGetTeamsDropdown(payload: object): void {
    this.manageClientsSVC.onGetTeamsDropdown(payload).subscribe(res => {
      this.teamListData = [...res['data']];
    })
  }

  onValidateTransactionRemapping(): void {
    this.manageTransactionSVC.onValidateTransactionRemapping(this.receivedData['id']).subscribe(res => {
      let data = {
        ...res['data']
      }
      let tempArray = [];
      if (data['calls'] > 0) {
        tempArray.push(' ' + data['calls'] + ' calls');
      }
      if (data['videos'] > 0) {
        tempArray.push(' ' + data['videos'] + ' videos');
      }
      if (data['images'] > 0) {
        tempArray.push(' ' + data['images'] + ' images');
      }
      this.validateMsg = `There are ${tempArray.join(',')} under this transaction for old client and the historical records will be persisted as it is. Please click on Proceed to confirm!`;
      if (data['calls'] === 0 && data['videos'] === 0 && data['images'] === 0) {
        this.validateMsg = '';
      }
    })
  }

  onClientChange(event): void {
    this.teamListData = [];
    this.remapForm.patchValue({
      to_team_id: null
    })
    if (event.target.value !== 'null') {
      let payload = {
        client_ids: [event.target.value],
        search: ''
      }
      this.isDisableConfirmBtn = false;
      this.onValidateTransactionRemapping();
      this.onGetTeamsDropdown(payload);
    } else {
      this.isDisableConfirmBtn = true;
      this.validateMsg = '';
    }
  }

  onTeamsChange(event): void {
    if (this.currentUser.data.user.role === 'CLIENT_ADMIN') {
      if (event.target.value !== 'null') {
        this.isDisableConfirmBtn = false;
        this.onValidateTransactionRemapping();
      } else {
        this.isDisableConfirmBtn = true;
        this.validateMsg = '';
      }
    }
  }

  onProceedRemap(): void {
    let payload = {
      ...this.remapForm.value
    };
    this.manageTransactionSVC.onRemapTransactionKey(payload).subscribe(res => {
      this.toastr.success('Transaction key remapping done succfully!');
      this.close(true);
    })
  }

}
