import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-filtericon',
  template: `
    <svg xmlns="http://www.w3.org/2000/svg" width="14.313" height="15" viewBox="0 0 14.313 15">
      <g id="filter" transform="translate(43.063 -27.15) rotate(90)">
        <circle id="Ellipse_21" data-name="Ellipse 21" cx="1.53" cy="1.53" r="1.53" transform="translate(38.257 36.672)" fill="#fff"/>
        <path id="Ellipse_21_-_Outline" data-name="Ellipse 21 - Outline" d="M1.113-1.25A2.363,2.363,0,1,1-1.25,1.113,2.366,2.366,0,0,1,1.113-1.25Zm0,3.06a.7.7,0,1,0-.7-.7A.7.7,0,0,0,1.113,1.81Z" transform="translate(38.673 37.088)" fill="#fff"/>
        <path id="Line_1" data-name="Line 1" d="M-.417,2.883A.833.833,0,0,1-1.25,2.05V-.417A.833.833,0,0,1-.417-1.25a.833.833,0,0,1,.833.833V2.05A.833.833,0,0,1-.417,2.883Z" transform="translate(40.203 40.18)" fill="#fff"/>
        <path id="Line_2" data-name="Line 2" d="M-.417,7.5a.833.833,0,0,1-.833-.833V-.417A.833.833,0,0,1-.417-1.25a.833.833,0,0,1,.833.833V6.672A.833.833,0,0,1-.417,7.5Z" transform="translate(40.203 30)" fill="#fff"/>
        <circle id="Ellipse_22" data-name="Ellipse 22" cx="1.53" cy="1.53" r="1.53" transform="translate(33.136 32.238)" fill="#fff"/>
        <path id="Ellipse_22_-_Outline" data-name="Ellipse 22 - Outline" d="M1.113-1.25A2.363,2.363,0,1,1-1.25,1.113,2.366,2.366,0,0,1,1.113-1.25Zm0,3.06a.7.7,0,1,0-.7-.7A.7.7,0,0,0,1.113,1.81Z" transform="translate(33.552 32.654)" fill="#fff"/>
        <path id="Line_3" data-name="Line 3" d="M-.417,7.349a.833.833,0,0,1-.833-.833V-.417A.833.833,0,0,1-.417-1.25a.833.833,0,0,1,.833.833V6.515A.833.833,0,0,1-.417,7.349Z" transform="translate(35.082 35.714)" fill="#fff"/>
        <path id="Line_4" data-name="Line 4" d="M-.417,3.071a.833.833,0,0,1-.833-.833V-.417A.833.833,0,0,1-.417-1.25a.833.833,0,0,1,.833.833V2.238A.833.833,0,0,1-.417,3.071Z" transform="translate(35.082 30)" fill="#fff"/>
        <circle id="Ellipse_23" data-name="Ellipse 23" cx="1.53" cy="1.53" r="1.53" transform="translate(27.983 36.672)" fill="#fff"/>
        <path id="Ellipse_23_-_Outline" data-name="Ellipse 23 - Outline" d="M1.113-1.25A2.363,2.363,0,1,1-1.25,1.113,2.366,2.366,0,0,1,1.113-1.25Zm0,3.06a.7.7,0,1,0-.7-.7A.7.7,0,0,0,1.113,1.81Z" transform="translate(28.4 37.088)" fill="#fff"/>
        <path id="Line_5" data-name="Line 5" d="M-.417,2.883A.833.833,0,0,1-1.25,2.05V-.417A.833.833,0,0,1-.417-1.25a.833.833,0,0,1,.833.833V2.05A.833.833,0,0,1-.417,2.883Z" transform="translate(29.93 40.18)" fill="#fff"/>
        <path id="Line_6" data-name="Line 6" d="M-.417,7.5a.833.833,0,0,1-.833-.833V-.417A.833.833,0,0,1-.417-1.25a.833.833,0,0,1,.833.833V6.672A.833.833,0,0,1-.417,7.5Z" transform="translate(29.93 30)" fill="#fff"/>
      </g>
    </svg>
  `
})
export class FiltericonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
