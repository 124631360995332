import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  public clientName = new Subject<{ name: string; id: string }>();
  public teamName = new Subject<{ name: string; id: string }>();
  public muteUnmuteRing = new Subject<boolean>();
  public isHowlAudioPlaying = new Subject<boolean>();
  public isCallStarted = new Subject<boolean>();
  public doRefreshMissedCallList = new Subject<boolean>();

  constructor() {}

  onSetClientTeamNames(
    type: string,
    values: { name: string; id: string }
  ): void {
    if (type === 'client') {
      this.clientName.next(values);
    } else {
      this.teamName.next(values);
    }
  }
  onGetClientTeamName(type: string): Observable<{ name: string; id: string }> {
    if (type === 'client') {
      return this.clientName.asObservable();
    } else {
      return this.teamName.asObservable();
    }
  }

  onSetIncomingRingStatus(type: boolean): void {
    this.muteUnmuteRing.next(type);
  }
  onGetIncomingRingStatus(): Observable<boolean> {
    return this.muteUnmuteRing.asObservable();
  }

  onSetHowlAudioPlayingStatus(type: boolean): void {
    this.isHowlAudioPlaying.next(type);
  }
  onGetHowlAudioPlayingStatus(): Observable<boolean> {
    return this.isHowlAudioPlaying.asObservable();
  }

  onSetCallStartedStatus(type: boolean): void {
    this.isCallStarted.next(type);
  }
  onGetCallStartedStatus(): Observable<boolean> {
    return this.isCallStarted.asObservable();
  }

  onSetRefreshMissedCallStatus(type: boolean): void {
    this.doRefreshMissedCallList.next(type);
  }
  onGetRefreshMissedCallStatus(): Observable<boolean> {
    return this.doRefreshMissedCallList.asObservable();
  }
}
