<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Manage Unmapped Transaction</span>
    <i class="far fa-times-circle cp" (click)="close(false)"></i>
</h2>

<form [formGroup]="forwardVideoAndImageForm" (ngSubmit)="onForwardVideoAndImage()">
    <div class="ion-form-group">
        <label class="label-text"></label>
        <div class="d-flex" style="width: 100%;">
            <input (keyup)="onChangeAuthKey($event)" type="text" formControlName="auth_key"
                style=" border-right: 0; border-top-right-radius: 0; border-bottom-right-radius: 0;">
            <button style=" width: 40%;border-top-left-radius: 0; border-bottom-left-radius: 0;"
                (click)="onValidateUpdatedAuthKey()" class="ion-theme-btn primary" type="button"
                [disabled]="isUpdateAuthKeyEnabled">UPDATE KEY</button>
        </div>
    </div>
    <div class="ion-form-group" *ngIf="currentUser.data.user.role === 'SYS_ADMIN'">
        <label class="label-text">Choose Client</label>
        <select [attr.disabled]="forwardVideoAndImageForm.get('auth_key').value !== selectedAuthKey ? true : null"
            formControlName="to_client_id" class="form-control" #clientDropdown
            (change)="onChangeClientDropdown(clientDropdown.value)"
            >
            <ng-container *ngFor="let client of clientListDropdown"  >
                <option *ngIf="client._id !== '60088942b22eefab8bf21a1d'" value="{{ client._id }}">{{ client.name }}
                </option>
            </ng-container>
        </select>
    </div>
    <div class="ion-form-group">
        <label class="label-text">Choose Team</label>
        <select [attr.disabled]="(forwardVideoAndImageForm.get('auth_key').value !== selectedAuthKey) ? true : null"
            formControlName="to_team_id" class="form-control">
            <option *ngFor="let team of clientTeamListDropdown" value="{{ team._id }}">{{ team.name }}</option>
        </select>
    </div>
    <div class="d-flex m-t-30 justify-content-end">
        <button type="submit" class="ion-theme-btn primary" style="text-transform: capitalize;"
            [disabled]="!forwardVideoAndImageForm.valid || forwardVideoAndImageForm.get('auth_key').value !== selectedAuthKey">
            Forward</button>
    </div>
</form>