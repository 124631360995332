<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>{{ receivedDataObj.dialogTitle }}</span>
    <i class="far fa-times-circle cp" (click)="close()"></i>
</h2>
<ng-container *ngIf="receivedDataObj.type !== null">
    <div class="slider-holder" *ngIf="receivedDataObj.type === 'SCREENSHOTS'">
        <div class="d-flex justify-content-end m-b-15">
            <img class="cp icon prev m-r-20" src="./assets/img/prev-slide.png" alt=""
                (click)="imageUploadsSlider.prev()">
            <img class="cp icon next" src="./assets/img/next-slide.png" alt="" (click)="imageUploadsSlider.next()">
        </div>
        <owl-carousel-o [options]="owlCarouselOption" #imageUploadsSlider>
            <ng-container *ngFor="let slide of receivedDataObj.data">
                <ng-template carouselSlide>
                    <img class="cp" [src]="slide" />
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
    <video *ngIf="receivedDataObj.type === 'VIDEO'" [src]="recordedVideoBlobUrl" controls style="width: 100%; height: 70vh;"></video>
    <ng-container *ngIf="receivedDataObj.type === 'IMAGE'">
        <div class="d-flex justify-content-end m-b-15" *ngIf="receivedDataObj.data.length > 1">
            <img [ngClass]="{'disabled': currentImageIndex === 0}" class="cp icon prev m-r-20"
                src="./assets/img/prev-slide.png" alt="" (click)="onNextPrevImages('prev')">
            <img [ngClass]="{'disabled': receivedDataObj.data.length - 1 === currentImageIndex}" class="cp icon next"
                src="./assets/img/next-slide.png" alt="" (click)="onNextPrevImages('next')">
        </div>
        <div class="scroll-holder">
            <img [src]="capturedImageBlobUrl" alt="" class="img-fluid">
        </div>
    </ng-container>
    <div class="scroll-holder" *ngIf="receivedDataObj.type === 'RECORDINGS'">
        <div class="d-flex justify-content-end m-b-15">
            <img [ngClass]="{'disabled': currentVideoIndex === 0}" class="cp icon prev m-r-20"
                src="./assets/img/prev-slide.png" alt="" (click)="onNextPrevVideo('prev')">
            <img [ngClass]="{'disabled': listOfRecordings.length - 1 === currentVideoIndex}" class="cp icon next"
                src="./assets/img/next-slide.png" alt="" (click)="onNextPrevVideo('next')">
        </div>
        <video id="cloudRecordingVideo" src="" controls></video>
    </div>
    <div class="scroll-holder" *ngIf="receivedDataObj.type === 'CALLLOG'">
        <div class="forward-log-holder">
            <ul class="d-flex forward-meta-data m-b-15" *ngFor="let data of receivedDataObj.forwardLog">
                <li>
                    <div class="data-section m-b-0">
                        <label>Forwarded By</label>
                        <p>{{ data.forwarded_by.full_name.trim() == '' ? data.forwarded_by.user_name : data.forwarded_by.full_name }}</p>
                    </div>
                </li>
                <li>
                    <div class="data-section m-b-0">
                        <label>Forwarded To</label>
                        <p>{{ data.target_team.name }}({{ data.target_client.name }})</p>
                    </div>
                </li>
                <ng-container *ngIf="receivedDataObj.type === 'CALLLOG'">
                    <li>
                        <div class="data-section m-b-0">
                            <label>Answered By</label>
                            <p>{{ data.answered_by ? data.answered_by.full_name.trim() == '' ? data.answered_by.user_name : data.answered_by.full_name : 'NA'}}</p>
                        </div>
                    </li>
                    <li *ngIf="data.duration">
                        <div class="data-section m-b-0">
                            <label>Duration</label>
                            <p>{{ data.duration.minute }} Minute(s) : {{ data.duration.second }} Second(s)
                            </p>
                        </div>
                    </li>
                </ng-container>
            </ul>
        </div>
    </div>
    <div class="scroll-holder">
        <div class="chat-history" *ngIf="chatData.length > 0">
            <ng-container *ngFor="let chat of chatData">
                <div 
                    *ngIf="chat.author_role === 'CENTRAL_SUPPORT_EXECUTIVE'; else showDriver" 
                    class="d-flex flex-column chat-items">
                    <p>{{ chat.payload }}</p>
                    <span class="align-self-end">{{ chat.author_name }} (Customer Support)</span>
                </div>
                <ng-template #showDriver>
                    <div class="d-flex flex-column chat-items">
                        <p class="their">{{ chat.payload }}</p>
                        <span>{{ chat.author_name }} (Field Support)</span>
                    </div>
                </ng-template>
            </ng-container>
        </div>
        <div class="comment" *ngIf="receivedDataObj.type === 'COMMENT'">
            <div class="data-section" *ngFor="let comment of receivedDataObj['data']">
                <label>
                    <b>Commented by:</b> {{ comment.user.full_name === '  ' ? comment.user.user_name : comment.user.full_name }}, {{ comment.time_stamp | date:'short' }}
                </label>
                <p class="m-t-10">
                    Comment: 
                    <span style="font-weight: 400;">{{ comment.comment }}</span>
                </p>
            </div>
        </div>
    </div>
</ng-container>