import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ManagetagsService } from 'src/app/shared/http/managetags.service';

@Component({
  selector: 'app-managetagsdialog',
  templateUrl: './managetagsdialog.component.html',
  styleUrls: ['./managetagsdialog.component.scss'],
})
export class ManagetagsdialogComponent implements OnInit {
  public receivedDataObj = {};
  public manageTagForm: UntypedFormGroup;
  public parentTagList = [];
  public enableParentTagsDropdown = true;

  constructor(
    public dialogRef: MatDialogRef<ManagetagsdialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedDataObj,
    private manageTagSVC: ManagetagsService
  ) {
    this.manageTagForm = new UntypedFormGroup({
      client_id: new UntypedFormControl(null, Validators.required),
      team_id: new UntypedFormControl(null, Validators.required),
      isParentActive: new UntypedFormControl(true),
      display_en: new UntypedFormControl(null, Validators.required),
      display_fr: new UntypedFormControl(null, Validators.required),
      display_esp: new UntypedFormControl(null, Validators.required),
      display_de: new UntypedFormControl(null, Validators.required),
      parent_id: new UntypedFormControl({ value: null, disabled: false }),
      is_active: new UntypedFormControl(true),
    });
    if (ReceivedDataObj !== null) {
      // console.log(ReceivedDataObj);
      this.receivedDataObj = ReceivedDataObj;
      this.manageTagForm.patchValue({
        client_id: this.receivedDataObj['client_id'],
        team_id: this.receivedDataObj['team_id'],
      });
      if (this.receivedDataObj['id']) {
        if (!this.receivedDataObj['isParent']) {
          this.manageTagForm.patchValue({
            isParentActive: false,
          });
          this.onGetTagDropdown(
            this.receivedDataObj['team_id'],
            this.receivedDataObj['isShowCustomerTagEnabled']
          );
        } else {
          this.manageTagForm.patchValue({
            parent_id: this.receivedDataObj['parent_id'],
            display_en: this.receivedDataObj['display_en'],
            display_fr: this.receivedDataObj['display_fr'],
            display_esp: this.receivedDataObj['display_esp'],
            display_de: this.receivedDataObj['display_de'],
            is_active: this.receivedDataObj['is_active'],
          });
        }
        this.manageTagForm.controls['isParentActive'].disable();
        this.manageTagForm.controls['parent_id'].disable();
      }
    }
  }

  ngOnInit(): void {}

  close(isRefresh: boolean) {
    this.dialogRef.close(isRefresh);
  }

  onGetTagDropdown(team_id: string, isShowCustomerTagEnabled): void {
    let payload = {
      client_id: this.receivedDataObj['client_id'],
      team_id,
    };
    this.manageTagSVC
      .onGetTagDropdown(payload, isShowCustomerTagEnabled)
      .subscribe((res) => {
        this.parentTagList = [...res['data']];
        this.manageTagForm.patchValue({
          parent_id: this.receivedDataObj['parent_id'],
          display_en: this.receivedDataObj['display_en'],
          display_fr: this.receivedDataObj['display_fr'],
          display_esp: this.receivedDataObj['display_esp'],
          display_de: this.receivedDataObj['display_de'],
        });
      });
  }

  onAddEditTag(): void {
    let payload = { ...this.manageTagForm.value };
    if (payload.parent_id === null) {
      delete payload.parent_id;
    }
    if (this.receivedDataObj['id']) {
      payload['id'] = this.receivedDataObj['id'];
      if (this.receivedDataObj['isParent']) {
        delete payload.parent_id;
      } else {
        payload['parent_id'] = this.receivedDataObj['parent_id'];
      }
    }
    delete payload['isParentActive'];
    this.manageTagSVC
      .onAddEditTag(payload, this.receivedDataObj['isShowCustomerTagEnabled'])
      .subscribe((res) => {
        this.close(true);
      });
  }

  onDisableEnableParentTagAdding(event) {
    if (!event.checked) {
      this.onGetTagDropdown(
        this.receivedDataObj['team_id'],
        this.receivedDataObj['isShowCustomerTagEnabled']
      );
      this.manageTagForm.controls['display_en'].disable();
      this.manageTagForm.controls['display_fr'].disable();
      this.manageTagForm.controls['display_esp'].disable();
      this.manageTagForm.controls['display_de'].disable();
    } else {
      this.manageTagForm.controls['display_en'].enable();
      this.manageTagForm.controls['display_fr'].enable();
      this.manageTagForm.controls['display_esp'].enable();
      this.manageTagForm.controls['display_de'].enable();
    }
  }

  onParentTagChange(selectedValue: any) {
    if (selectedValue === 'null') {
      this.manageTagForm.controls['display_en'].disable();
      this.manageTagForm.controls['display_fr'].disable();
      this.manageTagForm.controls['display_esp'].disable();
      this.manageTagForm.controls['display_de'].disable();
    } else {
      this.manageTagForm.controls['display_en'].enable();
      this.manageTagForm.controls['display_fr'].enable();
      this.manageTagForm.controls['display_esp'].enable();
      this.manageTagForm.controls['display_de'].enable();
    }
  }
}
