import { Component, Inject, OnInit } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-usernotificationdialog',
  templateUrl: './usernotificationdialog.component.html',
  styleUrls: ['./usernotificationdialog.component.scss'],
})
export class UsernotificationdialogComponent implements OnInit {
  public receivedNotificationData = {
    message: null,
  };

  constructor(
    public dialogRef: MatDialogRef<UsernotificationdialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedDataObj
  ) {
    if (ReceivedDataObj !== null) {
      this.receivedNotificationData = ReceivedDataObj;
    }
  }

  ngOnInit(): void {}

  close() {
    this.dialogRef.close();
  }
}
