import { ManageteamsService } from './../../../http/manageteams.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-userlistforteamsdialog',
  templateUrl: './userlistforteamsdialog.component.html',
  styleUrls: ['./userlistforteamsdialog.component.scss']
})
export class UserlistforteamsdialogComponent implements OnInit {

  public receivedObj = {};
  public userIdsArrayToTeams = {
    team_id: null,
    user_ids: []
  };
  public allUsersList = [];

  constructor(
    public dialogRef: MatDialogRef<UserlistforteamsdialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedObj,
    private manageTeamsSVC: ManageteamsService
  ) {
    if (ReceivedObj !== null) {
      this.receivedObj = ReceivedObj;
      this.userIdsArrayToTeams.team_id = this.receivedObj['team_id'];
      this.onGetUsersToAddToTeam();
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  onAddUsersToTeam(): void {
    this.manageTeamsSVC.onAddUsersToTeams(this.userIdsArrayToTeams).subscribe(res => {
      this.close();
    })
  }

  onCheckUnCheckUsers(id: string): void {
    let addFlag: boolean;
    this.userIdsArrayToTeams.user_ids.forEach((item) => {
      if (id === item) {
        addFlag = true;
      }
    });
    if (addFlag === true) {
      this.userIdsArrayToTeams.user_ids = this.userIdsArrayToTeams.user_ids.filter(item => item !== id);
      return;
    } else {
      this.userIdsArrayToTeams.user_ids.push(id);
      return;
    }
  }

  onGetUsersToAddToTeam(): void {
    this.manageTeamsSVC.onGetUsersToAddToTeam(this.receivedObj['client_id']).subscribe(res => {
      this.allUsersList = [...res['data']];
    })
  }

}
