import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tablepagination',
  templateUrl: './tablepagination.component.html',
  styleUrls: ['./tablepagination.component.scss']
})
export class TablepaginationComponent implements OnInit {

  public numberOfRecords: number;
  public recordsPerPage: number;
  public totalPages: number;
  public currentPage: number = 1;

  @Input() set pageSize(val: number) {
    this.recordsPerPage = val;
    this.setPaging();
  }
  @Input() set totalNumberOfRows(val: number) {
    if (this.numberOfRecords != val) {
      this.numberOfRecords = val;
      this.setPaging();
    }
  }
  @Output() change: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  setPaging() {
    if (this.numberOfRecords && this.recordsPerPage) {
      let tempTotalPages = Math.ceil(this.numberOfRecords / this.recordsPerPage)
      if (this.totalPages && this.totalPages != tempTotalPages) { //checkes the page count changed
        this.totalPages = tempTotalPages;
        if (this.currentPage > this.totalPages && this.totalPages != 0) { //checks if the selected page is greater than total pages.Required when the last page contents are deleted from table
          (this.currentPage - 1) == this.totalPages ? this.onPrevious() : this.switchPage(1) // switches to previous page is page count is one less otherwise will be moving to first page.
        }
        else
          this.switchPage(1);
      }
      else {
        this.totalPages = tempTotalPages;
      }
    }
  }

  onPrevious() {
    this.change.emit((--this.currentPage - 1) * this.recordsPerPage); //changes the current page and calculates the start index of data to fetch
    if (this.currentPage == 1) {
      return false
    } else {
      return true;
    }
  }

  onNext() {
    this.change.emit((++this.currentPage - 1) * this.recordsPerPage); //changes the current page and calculates the start index of data to fetch
    if (this.currentPage == this.totalPages) { 
      return false
    } else {
      return true
    }
  }

  switchPage(page) {
    this.change.emit((page - 1) * this.recordsPerPage); //switches to a specific page
  }

}
