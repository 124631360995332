import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JobdetailsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onGetJobDetails(payload: object,page,size): Observable<object> {
    return this.httpClient.post(`authkey/history?page=${page}&size=${size}`, payload);
  }

  onGetChatDetails(id: string): Observable<object> {
    return this.httpClient.get(`session/id/${id}/chat`);
  }

}
