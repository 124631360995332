import { ToastrService } from 'ngx-toastr';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import * as $ from "jquery";

@Component({
  selector: 'app-iontypeahead',
  templateUrl: './iontypeahead.component.html',
  styleUrls: ['./iontypeahead.component.scss']
})
export class IontypeaheadComponent implements OnInit, OnChanges {

  @ViewChild(MatMenuTrigger) triggerTypeAheadList: MatMenuTrigger;
  @Input() maxSelections: number = 0;
  @Input() labelText = '';
  @Input() placeHolderString = '';
  @Input() dropDownData: Array<{ name: string; id: number; }>;
  @Output() userTypeAheadKey: EventEmitter<any> = new EventEmitter();
  @Output() userSelectedPills: EventEmitter<any> = new EventEmitter();
  @Input() public existPills: Array<{ id: string, name: string }> = [];
  public selectedPillsArray: Array<{ id: string, name: string }> = [];
  @Input() keyToSend = '_id';
  @Input() isDisabled = false;
  @Input() isStacked = false;
  public typeAheadSearch = '';
  @Input() isEnableClearAll = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.existPills?.currentValue !== undefined) {
      this.selectedPillsArray = changes.existPills.currentValue;
    }
  }

  constructor(
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }

  onFocusinOfInput() {
    this.dropDownData = [];
  }

  onTypeAhead(event: any) {
    if (event.target.value.length > 0) {
      this.userTypeAheadKey.emit(event.target.value);
      this.triggerTypeAheadList.openMenu();
      event.target.focus();
    } else {
      this.triggerTypeAheadList.closeMenu();
    }
  }

  hideTypeAheadList(event: any) {
    if (event.srcElement.value.length === 0) {
      this.triggerTypeAheadList.closeMenu();
    }
  }

onBindSelectedValue(event, data: any): void {
  event.stopPropagation();

  if (this.maxSelections > 0 && this.selectedPillsArray.length >= this.maxSelections) {
    this.toastr.warning(`Maximum ${this.maxSelections} items can be selected.`);
    return;
  }

  let addFlag: boolean;
  this.selectedPillsArray.forEach((item) => {
    if (item.id === data[$(event.target).attr('id')] || item['_id'] === data[$(event.target).attr('id')]) {
      addFlag = true;
    }
  });

  if (addFlag === true) {
    this.toastr.warning('Item is already in the list');
  } else {
    this.selectedPillsArray.push({
      id: data[$(event.target).attr('id')],
      name: $(event.target).text(),
    });
    this.userSelectedPills.emit(this.selectedPillsArray);
  }
}


  onFocusOutSearch(): void {
    this.typeAheadSearch = '';
  }

  public deletePillsFromList(pill: any): void {
    const index = this.selectedPillsArray.indexOf(pill, 0);
    if (index > -1) {
      this.selectedPillsArray.splice(index, 1);
      this.userSelectedPills.emit(this.selectedPillsArray);
    }
  }

  onClearAllPills(): void {
    this.selectedPillsArray = [];
    this.userSelectedPills.emit(this.selectedPillsArray);
  }

}
