import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onGetDashboardUsage(payload: object): Observable<object> {
    return this.httpClient.post('dashboard/usage/summary', payload);
  }

  onGetDashboardActionCenter(payload: object): Observable<object> {
    return this.httpClient.post('dashboard/actioncenter', payload);
  }

}
