<header>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-lg-2">
                <img src="./assets/img/logo.png" class="img-fluid" alt="">
            </div>
        </div>
    </div>
</header>

<div class="verify-holder">
    <h1>Welcome to Quikstep. Please wait for the verification to be completed...</h1>
</div>