<header>
    <div class="container-fluid">
        <div class="row justify-content-between">
            <div class="col-lg-6">
                <div class="d-flex align-items-center">
                    <img src="{{ brandLogo }}" class="img-fluid cp logo" alt="" (click)="onNavigateToLandingPage()">
                </div>
            </div>
            <div class="col-lg">
                <div class="pro-details">
                    <div class="name-role-icon-holder d-flex align-items-center">
                        <div *ngIf="currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE'"
                            class="mute-unmute-icons d-flex aligc-items-center m-r-20"
                            [ngClass]="{'show-mute': showMute}">
                            <i class="fas fa-volume-mute mute" (click)="onMuteUnmuteCallerRing()"></i>
                            <i class="fas fa-volume-up unmute" (click)="onMuteUnmuteCallerRing()"></i>
                        </div>
                        <p class="m-b-0 m-r-15">
                            <ng-container *ngIf="currentUser.data.user.first_name; else showUserName">
                                {{ currentUser.data.user.first_name }} {{ currentUser.data.user.last_name }}
                            </ng-container>
                            <ng-template #showUserName>
                                {{ currentUser.data.user.email }}
                            </ng-template>
                            <br>
                            <ng-container [ngSwitch]="currentUser.data.user.role">
                                <span *ngSwitchCase="'SYS_ADMIN'">System Administrator</span>
                                <span *ngSwitchCase="'CLIENT_ADMIN'">Client Administrator</span>
                                <span *ngSwitchCase="'CENTRAL_SUPPORT_EXECUTIVE'">Customer Support Executive</span>
                                <span *ngSwitchCase="'TEAM_MANAGER'">Team Manager</span>
                            </ng-container>
                        </p>
                        <i class="fas fa-user-circle cp" [matMenuTriggerFor]="menu"></i>
                        <mat-menu class="profile-dropdown-items" #menu="matMenu" [overlapTrigger]="false"
                            yPosition="below" xPosition="before">
                            <button (click)="onOpenLanguageSelectionDialog()" mat-menu-item
                                *ngIf="currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE'">
                                Preferred Language: <b>{{ currentUser.data.user.language_preference ?
                                    currentUser.data.user.language_preference.code : '' }}</b>
                            </button>
                            <!-- <button [routerLink]="['account/reset-password']" mat-menu-item> Reset Password</button> -->
                            <button mat-menu-item (click)="onLogout()">Logout</button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="container-fluid m-t-80"
    *ngIf="!location.path().includes('active-call') && (this.currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE' || this.currentUser.data.user.role === 'TEAM_MANAGER')">
    <div class="row">
        <div class="col-lg">
            <div class="incoming-call-wrapper">
                <div class="d-flex justify-content-end w-100 m-b-20" *ngIf="location.path() !== '/call-management'">
                    <button class="ion-theme-btn sm primary d-flex align-items-center" (click)="location.back()">
                        <app-longarrowicon class="m-r-10"></app-longarrowicon>
                        <span>Back</span>
                    </button>
                </div>
                <div class="incoming-call" [ngClass]="{'show-full': location.path() === '/my-exports'}">
                    <div *ngIf="currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE'"
                        class="incoming-calls-count cp m-r-25" [routerLink]="['/administrator/users']"
                        [queryParams]="{'type': 'field'}">
                        <span class="count">
                            {{ actionCenterData.field_agent }}
                        </span>
                        <span class="label">Field Agents</span>
                    </div>
                    <div class="incoming-calls-count">
                        <span class="count">
                            {{ incomingCallCount + incomingCallOneToOneCount }}
                        </span>
                        <span class="label">Incoming Calls</span>
                    </div>
                    <div [ngClass]="{'show-full': currentUser.data.user.role === 'TEAM_MANAGER'}"
                        class="active-call-list-wrapper">
                        <ul>
                            <ng-container *ngIf="showhideCallQueue">
                                <ng-container *ngFor="let queue of incomingCallQueue">
                                    <li *ngIf="queue['status'] === 'WAITING'"
                                        class="d-flex justify-content-between align-items-center">
                                        <span>
                                            <ng-container *ngIf="!queue['forwarded_user_name']">
                                                Call from
                                                <b>{{ queue['requested_user_full_name'] }} {{
                                                    queue['requested_user_name']
                                                    }}</b>
                                            </ng-container>
                                            (Team: <b>{{ queue['team_name'] }}</b> Transaction: <b>{{
                                                queue['auth_key'] }}</b>)
                                            <ng-container *ngIf="queue['forwarded_user_name']">
                                                Forwarded by <b>{{ queue['forwarded_user_name'] }}</b>
                                            </ng-container>
                                        </span>
                                        <i class="fas fa-phone-alt" (click)="startCall(
                            queue['session_id'],
                            queue['requested_user_name'],
                            queue['lattitude'],
                            queue['longitude'],
                            queue['team_id'],
                            queue['auth_key'],
                            queue['team_name'],
                            queue['requested_user_full_name'],
                            queue['sub_session_id'])"></i>
                                    </li>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="showhideOneToOneCallQueue">
                                <ng-container *ngFor="let queue of incomingCallQueueOneToOne">
                                    <li *ngIf="queue['status'] === 'WAITING'"
                                        class="d-flex justify-content-between align-items-center">
                                        <span>
                                            <ng-container>
                                                Call from
                                                <b>{{ queue['requested_user_full_name'] }} {{
                                                    queue['requested_user_name']
                                                    }}</b>
                                            </ng-container>
                                            (Team: <b>{{ queue['team_name'] }}</b> Transaction: <b>{{
                                                queue['auth_key'] }}</b>)
                                            <ng-container *ngIf="queue['forwarded_user_name']">
                                                Forwarded by <b>{{ queue['forwarded_user_name'] }}</b>
                                            </ng-container>
                                        </span>
                                        <i class="fas fa-phone-alt" (click)="startCall(
                            queue['session_id'],
                            queue['requested_user_name'],
                            queue['lattitude'],
                            queue['longitude'],
                            queue['team_id'],
                            queue['auth_key'],
                            queue['team_name'],
                            queue['requested_user_full_name'],
                            queue['sub_session_id'])"></i>
                                    </li>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!showhideOneToOneCallQueue && !showhideCallQueue">
                                <li style="border: 0; color: #000;">
                                    <span>No active calls!</span>
                                </li>
                            </ng-container>
                        </ul>
                    </div>
                </div>
                <div class="team-client-info d-flex align-items-center"
                    *ngIf="clientInfo !== null && location.path() !== '/my-exports'">
                    <div>
                    <div class="data-section m-r-20 m-b-0">
                        <label>Client</label>
                        <p sclass="textrow">{{ clientInfo.name }}</p>
                    </div>
                    <div class="data-section m-r-20 m-b-0 m-t-15">
                        <label class="textrow">Admin Email</label>
                        <p class="textrow">{{ clientInfo.email }}</p>
                    </div>
                </div>
                    <div class="data-section m-r-20 m-b-0 displayTeamHeader">
                        <div>
                        <label>Teams</label>
                        <div class="" style="max-height: 60px; overflow-y: auto;">
                            <p *ngFor="let team of associatedTeamList; let i = index" class="m-r-5">
                                {{ team.name }}<ng-container *ngIf="i !== associatedTeamList.length - 1">,
                                </ng-container>
                            </p>
                        </div>
                       </div>
                        <div>
                            <a>Show More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>