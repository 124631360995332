import { UsertokenService } from './../../shared/http/usertoken.service';
import { UserInfo } from 'src/app/routes/account/models/user.model';
import { ManageexportsService } from './../../shared/http/manageexports.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-myexports',
  templateUrl: './myexports.component.html',
  styleUrls: ['./myexports.component.scss']
})
export class MyexportsComponent implements OnInit {

  public exportList = [];
  public currentUser: UserInfo;

  constructor(
    public location: Location,
    private manageExportsSVC: ManageexportsService,
    private userTokenSVC: UsertokenService
  ) { 
    this.userTokenSVC.currentUser.subscribe((x) => this.currentUser = x);
  }

  ngOnInit(): void {
    this.onGetExportList();
  }

  onGetExportList(): void {
    this.manageExportsSVC.onGetExportList().subscribe(res => {
      this.exportList = [...res['data']];
    })
  }

  onDownloadExport(id: string): void {
    this.manageExportsSVC.onDownloadExport(id).subscribe(res => {
      window.open(environment.baseUrl + res['data']['download_url'], "_blank");
    })
  }

}
