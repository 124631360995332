<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Manage Client</span>
    <i class="far fa-times-circle cp" (click)="close()"></i>
</h2>

<div class="scroll-holder">
    <form [formGroup]="clientForm" (ngSubmit)="onManageClient()">
        <div class="container-fluid">
            <div class="ion-form-group">
                <label class="label-text">Name</label>
                <input type="text" formControlName="name">
            </div>
            <div class="ion-form-group">
                <label class="label-text">Email</label>
                <input type="email" formControlName="email">
            </div>
            <div class="ion-form-group">
                <label class="label-text">Phone</label>
                <div class="d-flex">
                    <div class="holder m-r-15">
                        <input ype="search" list="countryCodes" autocomplete="off" formControlName="country_code">
                        <datalist id="countryCodes">
                            <option *ngFor="let item of countryCodesList" [value]="item.dial_code">{{item.name}}
                            </option>
                        </datalist>
                    </div>
                    <input type="text" formControlName="phone">
                </div>
            </div>
            <div class="ion-form-group">
                <label class="label-text">Description</label>
                <textarea formControlName="description"></textarea>
            </div>
            <div class="row flex-wrap ion-form-group" formGroupName="color_palatte">
                <div class="rowstyle">
                    <div class="column">
                        <label class="label-text">Web & app Primary button and icons color</label>
                        <div class="input-wrapper">
                            <div class="color-circle" [ngStyle]="{'background-color': clientForm.get('color_palatte.primary').value}"></div>
                            <input formControlName="primary">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">App page Titles</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.primary_dark').value}"></div>
                            <input formControlName="primary_dark">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">App Background color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.background').value}"></div>
                            <input formControlName="background">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">App chat Background color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.background_light').value}"></div>
                            <input formControlName="background_light">
                        </div>
                    </div>
                </div>
                <div class="rowstyle">
                    <div class="column">
                        <label class="label-text">App Call notification color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.gradient_light').value}"></div>
                            <input formControlName="gradient_light">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">App call notification text color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.gradient_dark').value}"></div>
                            <input formControlName="gradient_dark">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">App Profile page Text color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.phone_text').value}"></div>
                            <input formControlName="phone_text">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">App Upload icon border</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.progress_border').value}"></div>
                            <input formControlName="progress_border">
                        </div>
                    </div>
                </div>
                <div class="rowstyle">
                    <div class="column">
                        <label class="label-text">App Settings page Text color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.settings_info_text').value}"></div>
                            <input formControlName="settings_info_text">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">Web Table Alternate Row color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.table_row_color').value}"></div>
                            <input formControlName="table_row_color">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">Web Header Color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.web_header_color').value}"></div>
                            <input formControlName="web_header_color">
                        </div>
                    </div>
                    <div class="column">
                        <label class="label-text">Web Header Text color</label>
                        <div class="input-wrapper">
                            <div class="color-circle"[ngStyle]="{'background-color': clientForm.get('color_palatte.web_header_text_color').value}"></div>
                            <input formControlName="web_header_text_color">
                        </div>
                    </div>
                </div>
            </div>
            

            <div class="row" style="display: flex;justify-content: space-between;">
                <div class="col-lg-3">
                    <div class="ion-form-group">
                        <label class="label-text">Client Logo</label>
                        <input type="file" (change)="onClientAssetsUpload($event, 'logo')" accept=".png,.jpg">
                        <img [src]="clientForm.get('logo_path').value" alt="" class="m-t-20 img-fluid">
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="ion-form-group">
                        <label class="label-text">Client Square Logo</label>
                        <input type="file" (change)="onClientAssetsUpload($event, 'square')" accept=".png,.jpg">
                        <img [src]="clientForm.get('square_logo_path').value" alt="" class="m-t-20 img-fluid">
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="ion-form-group">
                        <label class="label-text">Client Favicon</label>
                        <input type="file" (change)="onClientAssetsUpload($event, 'fav')" accept=".png,.jpg">
                        <img [src]="clientForm.get('fav_icon_path').value" alt="" class="m-t-20 img-fluid">
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button type="submit" class="ion-theme-btn primary" [disabled]="!clientForm.valid">Submit</button>
            </div>
        </div>
    </form>
</div>