import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from './../../../http/authentication.service';
import { UsertokenService } from './../../../http/usertoken.service';
import { UserInfo } from 'src/app/routes/account/models/user.model';
import { ManageusersService } from 'src/app/shared/http/manageusers.service';
import { ManageclientsService } from './../../../http/manageclients.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as countryCodes from '../../../countryCode.json';

@Component({
  selector: 'app-manageuserdialog',
  templateUrl: './manageuserdialog.component.html',
  styleUrls: ['./manageuserdialog.component.scss']
})
export class ManageuserdialogComponent implements OnInit {

  public receivedDataObj = {};
  public userForm: UntypedFormGroup;
  public countryCodesList: any;
  public clientListData = [];
  public userRoles = [
    { value: 'CENTRAL_SUPPORT_EXECUTIVE', disValue: 'Customer Support' },
    { value: 'TEAM_MANAGER', disValue: 'Team Manager' },
  ];
  public currentUser: UserInfo;

  constructor(
    public dialogRef: MatDialogRef<ManageuserdialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedDataObj,
    private manageClientsSVC: ManageclientsService,
    private manageUserSVC: ManageusersService,
    private userTokenSVC: UsertokenService,
    private authenticationSVC: AuthenticationService,
    private toastr: ToastrService
  ) {
    this.userTokenSVC.currentUser.subscribe(x => this.currentUser = x);
    if (this.currentUser.data.user.role === 'SYS_ADMIN') {
      this.onGetClientsDropdown();
    }
    this.userForm = new UntypedFormGroup({
      first_name: new UntypedFormControl(null, Validators.required),
      last_name: new UntypedFormControl(''),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      country_code: new UntypedFormControl(null, Validators.required),
      phone: new UntypedFormControl('', Validators.required),
      client_id: new UntypedFormControl(this.currentUser.data.user.role === 'SYS_ADMIN' ? null : this.currentUser.data.user.client_id, Validators.required),
      role: new UntypedFormControl(null, Validators.required),
    })
    if (ReceivedDataObj !== null) {
      this.receivedDataObj = { ...ReceivedDataObj };
      this.userForm.patchValue({
        first_name: this.receivedDataObj['first_name'],
        last_name: this.receivedDataObj['last_name'],
        email: this.receivedDataObj['email'],
        country_code: this.receivedDataObj['country_code'],
        phone: this.receivedDataObj['phone'],
        client_id: this.receivedDataObj['client']['id'],
        role: this.receivedDataObj['role']
      });
      this.userForm.get('client_id').disable();
    }
  }

  ngOnInit(): void {
    this.countryCodesList = countryCodes['default'];
    this.userForm.get('email').valueChanges.subscribe((event) => {
      this.userForm.get('email').setValue(event.toLowerCase(), {emitEvent: false});
    })
  }

  close() {
    this.dialogRef.close();
  }

  onCreateUpdateUser(): void {
    let payload = this.userForm.value;
    if (this.receivedDataObj['_id']) {
      payload = { ...payload, id: this.receivedDataObj['_id'], is_active: true }
    }
    this.manageUserSVC.onCreateUpdateUser(payload).subscribe(res => {
      this.close();
    })
  }

  onGetClientsDropdown(): void {
    this.manageClientsSVC.onGetClientsDropdown('').subscribe(res => {
      this.clientListData = [...res['data']];
    })
  }

  onResendInvite(userId: string) {
    this.authenticationSVC.onResendInvitation(userId).subscribe(
      res => {
        this.toastr.success(
          'Invitation has been send to the user'
        );
        this.close();
      });
  }

}
