import { UsertokenService } from './usertoken.service';
import { UserInfo } from './../../routes/account/models/user.model';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public currentUser: UserInfo;

  constructor(
    private httpClient: HttpClient,
    private userTokenSVC: UsertokenService,
  ) {
    this.userTokenSVC.currentUser.subscribe((x) => (this.currentUser = x));
  }

  onLogin(credentials: any): Observable<object> {
    return this.httpClient.post('account/v2/login', credentials);
  }

  onGetNewAccessToken(): Observable<object> {
    return this.httpClient.post('account/token', {
      refresh_token: this.currentUser.data.refresh_token
    }).pipe(
      tap((res) => {
        let tempUserInfo: UserInfo = JSON.parse(sessionStorage.getItem('userInfo'));
        tempUserInfo.data.access_token = res['data'].access_token;
        sessionStorage.setItem('userInfo', JSON.stringify(tempUserInfo));
        let data = JSON.parse(atob(tempUserInfo.data.user_token.split('.')[1]));
        tempUserInfo={...tempUserInfo,data:{...tempUserInfo.data,user:data}};
        this.userTokenSVC.currentUserSubject.next(tempUserInfo);
      })
    )
  }

  onResetPassword(recievedToken: any, updatedPasswordObj: object): Observable<object> {
    return this.httpClient.post('user/resetPassword?t=' + recievedToken, updatedPasswordObj);
  }

  onForgotPassword(receivedObj: object): Observable<object> {
    return this.httpClient.post('user/requestResetPassword', receivedObj);
  }

  onResendInvitation(userId: string): Observable<object> {
    return this.httpClient.post(`user/id/${userId}/requestResetPassword`, '');
  }

}
