import { ToastrService } from 'ngx-toastr';
import { ManageauthkeysService } from './../../http/manageauthkeys.service';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-initiatecallfromweb',
  templateUrl: './initiatecallfromweb.component.html',
  styleUrls: ['./initiatecallfromweb.component.scss']
})
export class InitiatecallfromwebComponent implements OnInit {

  public initiateCallFromWebForm: UntypedFormGroup;
  @Input() auth_key = null;
  @Input() targetUserId = null;
  @Input() callTo = null;

  constructor(
    private router: Router,
    private manageAuthKeySVC: ManageauthkeysService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initiateCallFromWebForm = new UntypedFormGroup({
      auth_key: new UntypedFormControl(null, Validators.required)
    })
  }

  onOpenInititateCallFromWebForm(): void {
    this.initiateCallFromWebForm.patchValue({
      auth_key: this.auth_key
    })
  }

  onInitiateCallFromWeb() {
    this.manageAuthKeySVC.onValidateAuthKey(this.initiateCallFromWebForm.get('auth_key').value).subscribe(res => {
      if (!res['data']['is_valid']) {
        this.toastr.error(res['data']['error'])
      } else {
        this.router.navigate(['call-management/active-call'], {
          queryParams: {
            targetUserId: this.targetUserId,
            receivedKey: this.initiateCallFromWebForm.get('auth_key').value,
            callTo: this.callTo
          }
        });
      }
    })
  }

}
