import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManageteamsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onGetTeams(id: string): Observable<object> {
    return this.httpClient.get(`client/id/${id}/team`);
  }

  onGetTeamUsers(id: string): Observable<object> {
    return this.httpClient.get(`team/id/${id}/user`);
  }

  onCreateUpdate(payload: object): Observable<object> {
    if (payload['id']) {
      return this.httpClient.put('team', payload);
    } else {
      return this.httpClient.post('team', payload);
    }
  }

  onGetUsersToAddToTeam(id: string): Observable<object> {
    return this.httpClient.get(`client/id/${id}/user?load_only_customer=false`);
  }

  onAddUsersToTeams(userAddedObj: object): Observable<object> {
    return this.httpClient.post('team/user', userAddedObj);
  }

  onRemoveUserFromTeam(teamId: string, userId: string): Observable<object> {
    return this.httpClient.delete(`team/id/${teamId}/user/${userId}`);
  }

  onGetTeamDetails(id: string): Observable<object> {
    return this.httpClient.get(`team/id/${id}/details`);
  }

}
