import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManageusersService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onGetAssociatedTeams(): Observable<object> {
    return this.httpClient.get('user/me/organization');
  }

  onGetUserList(payload: object): Observable<object> {
    return this.httpClient.post('dashboard/user/list', payload);
  }

  onCreateUpdateUser(payload: object): Observable<object> {
    if (payload['id']) {
      return this.httpClient.put('user', payload);
    } else {
      return this.httpClient.post('user', payload);
    }
  }

  onSetPreferedLanguage(languageObj: object) {
    return this.httpClient.patch('user', languageObj);
  }
  onblockUser(payload: object) {
    return this.httpClient.post('account/unblock', payload);
  }
  

}
