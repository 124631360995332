<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Change Language</span>
    <i class="far fa-times-circle cp" (click)="close()"></i>
</h2>

<form [formGroup]="prefferedLangForm" (ngSubmit)="onSavePrefLanguage()">
    <div class="ion-form-group">
        <label>Choose you preffered language</label>
        <select id="selectedLanguage" formControlName="langauge_code">
            <option [value]="lang.language" *ngFor="let lang of availableLangPref">{{ lang.name }}</option>
        </select>
    </div>
    <div class="row">
        <div class="col-md text-end">
            <button type="submit" [disabled]="!prefferedLangForm.valid" class="ion-theme-btn primary">Save</button>
        </div>
    </div>
</form>