<i class="fas fa-phone-alt call-back float-end"
    [matMenuTriggerFor]="menu" (click)="onOpenInititateCallFromWebForm()"></i>
<mat-menu #menu="matMenu">
    <div class="one-to-one-form-holder">
        <form (ngSubmit)="onInitiateCallFromWeb()" [formGroup]="initiateCallFromWebForm"
            (click)="$event.stopPropagation();">
            <div class="ion-form-group m-b-10">
                <label class="label-text">Job ID</label>
                <input type="text" placeholder="" formControlName="auth_key">
            </div>
            <div class="d-flex justify-content-end">
                <button [disabled]="!initiateCallFromWebForm.valid" class="ion-theme-btn primary sm" type="submit">Call</button>
            </div>
        </form>
    </div>
</mat-menu>