import { GoogleapiService } from './../../../http/googleapi.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UsertokenService } from './../../../http/usertoken.service';
import { UserInfo } from './../../../../routes/account/models/user.model';
import { ManageusersService } from 'src/app/shared/http/manageusers.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-changelanguagedialog',
  templateUrl: './changelanguagedialog.component.html',
  styleUrls: ['./changelanguagedialog.component.scss']
})
export class ChangelanguagedialogComponent implements OnInit {

  public currentUser: UserInfo;
  public prefferedLangForm: UntypedFormGroup;
  public availableLangPref: any;

  constructor(
    public dialogRef: MatDialogRef<ChangelanguagedialogComponent>,
    private manageUserSVC: ManageusersService,
    private userTokenSVC: UsertokenService,
    private toastr: ToastrService,
    private googleApiSVC: GoogleapiService
  ) { 
    this.userTokenSVC.currentUser.subscribe((x) => this.currentUser = x);
  }

  ngOnInit(): void {
    this.prefferedLangForm = new UntypedFormGroup({
      langauge_code: new UntypedFormControl(null, Validators.required)
    });
    this.onGetSupportedLanguages();
  }

  close() {
    this.dialogRef.close();
  }

  onGetSupportedLanguages() {
    this.googleApiSVC.onGetSupportedLanguages()
      .subscribe(
        res => {
          this.availableLangPref = res['data']['languages']
        }
      );
  }

  onSavePrefLanguage() {
    const tempObj = {
      id: this.currentUser.data.user._id,
      language_preference: {
        code: this.prefferedLangForm.get('langauge_code').value
      }
    }
    this.manageUserSVC.onSetPreferedLanguage(tempObj)
      .subscribe(res => {
        let tempObj = JSON.parse(sessionStorage.getItem('userInfo'));
        let data = JSON.parse(atob(tempObj.data.user_token.split('.')[1]));
        tempObj={...tempObj,data:{...tempObj.data,user:data}};
        tempObj.data.user.language_preference.code = this.prefferedLangForm.get('langauge_code').value;
        this.userTokenSVC.currentUserSubject.next(tempObj);
        delete tempObj.data.user;
        sessionStorage.setItem('userInfo', JSON.stringify(tempObj));
        this.close();
        this.toastr.success('Your preffered language is added');
      });
  }

}
