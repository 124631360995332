import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SystemusageService {
  constructor(private httpClient: HttpClient) {}

  onGetSystemUsage(payload: object): Observable<object> {
    return this.httpClient.post('log/v8/customer-executive', payload);
  }

  onGetExportSummary(): Observable<object> {
    return this.httpClient.get('dashboard/me/export/summary');
  }

  onGetAllTags(payload: object): Observable<object> {
    return this.httpClient.post('dashboard/tag', payload);
  }

  onGetCSGrades(teamId: string): Observable<object> {
    return this.httpClient.get(`grade/team_id/${teamId}`);
  }

  onAttachGradeToTransaction(payload: object): Observable<object> {
    return this.httpClient.post('grade/attach', payload);
  }
}
