<div class="container-fluid">
    <div class="row">
        <div class="col-lg">
            <div class="manage-clients-actions d-flex justify-content-between align-items-center m-b-20">
                <h2 class="m-b-0">My Exports</h2>
            </div>
            <div class="table-responsive">
                <table class="table ion-table">
                    <thead>
                        <tr>
                            <th>File</th>
                            <th>Requested Time</th>
                            <th style="width: 20%;">Filters</th>
                            <th>Expiry</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="exportList.length > 0; else showNodata">
                            <tr *ngFor="let export of exportList">
                                <td>
                                    <ng-container [ngSwitch]="export.status">
                                        <ng-container *ngSwitchCase="'COMPLETED'">
                                            <span class="ion-anchor" (click)="onDownloadExport(export.id)">{{
                                                export.file
                                                }}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'DOWNLOADED'">
                                            <span class="ion-anchor" (click)="onDownloadExport(export.id)">{{
                                                export.file
                                                }}</span>
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'REQUEST_RECIEVED'">
                                            Request Received
                                        </ng-container>
                                        <ng-container *ngSwitchCase="'INPROGRESS'">
                                            In Progress...
                                        </ng-container>
                                    </ng-container>
                                </td>
                                <td>{{ export.requested_at | date: 'short' }}</td>
                                <td>
                                    <div class="view-actions">
                                        <ul class="action-items d-flex flex-wrap m-b-10"
                                            *ngIf="export.filters_applied.clients.length > 0">
                                            <li *ngFor="let client of export.filters_applied.clients; let i = index"
                                                class="m-r-5">
                                                {{ client.name }}<ng-container
                                                    *ngIf="i !== export.filters_applied.clients.length - 1">,
                                                </ng-container>
                                            </li>
                                        </ul>
                                        <ul class="action-items d-flex flex-wrap m-b-10"
                                            *ngIf="export.filters_applied.items.length > 0">
                                            <li *ngFor="let filterItem of export.filters_applied.items; let i = index"
                                                class="m-r-5">
                                                <ng-container [ngSwitch]="filterItem">
                                                    <span *ngSwitchCase="'MISSED_CALL'">Missed Call</span>
                                                    <span *ngSwitchCase="'INCOMING_CALL'">Incoming Call</span>
                                                    <span *ngSwitchCase="'OUTGOING_CALL'">Outgoing Call</span>
                                                    <span *ngSwitchCase="'IMAGE'">Image</span>
                                                    <span *ngSwitchCase="'VIDEO'">Video</span>
                                                    <span *ngSwitchCase="'POD'">POD</span>
                                                    <span *ngSwitchCase="'DEFECTIVE'">Issue</span>
                                                </ng-container>
                                                <ng-container *ngIf="i !== export.filters_applied.items.length - 1">,</ng-container>
                                            </li>
                                        </ul>
                                        <ul class="action-items d-flex flex-wrap"
                                            *ngIf="export.filters_applied.teams.length > 0">
                                            <li *ngFor="let team of export.filters_applied.teams; let i = index"
                                                class="m-r-5">
                                                {{ team.name }}<ng-container
                                                    *ngIf="i !== export.filters_applied.teams.length - 1">,
                                                </ng-container>
                                            </li>
                                        </ul>
                                        <ul class="action-items d-flex flex-wrap"
                                            *ngIf="export.filters_applied.main_tags.length > 0">
                                            <li *ngFor="let mainTag of export.filters_applied.main_tags; let i = index"
                                                class="m-r-5">
                                                {{ mainTag }}
                                            </li>
                                        </ul>
                                        <ul class="action-items d-flex flex-wrap"
                                            *ngIf="export.filters_applied.sub_tags.length > 0">
                                            <li *ngFor="let subTag of export.filters_applied.sub_tags; let i = index"
                                                class="m-r-5">
                                                {{ subTag }}
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td>{{ export.expiry_at | date: 'short' }}</td>
                                <td>
                                    <ng-container [ngSwitch]="export.status">
                                        <ng-container *ngSwitchCase="'DOWNLOADED'">Downloaded</ng-container>
                                        <ng-container *ngSwitchCase="'COMPLETED'">Completed</ng-container>
                                        <ng-container *ngSwitchDefault>In Progress</ng-container>
                                    </ng-container>
                                </td>
                            </tr>
                        </ng-container>
                        <ng-template #showNodata>
                            <tr>
                                <td colspan="6" class="text-center">No data available!</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>