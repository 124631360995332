import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ManagetagsService {
  constructor(private httpClient: HttpClient) {}

  onGetTagList(
    payload: object,
    isCustomerTagEnabled = false
  ): Observable<object> {
    return this.httpClient.post(
      `${isCustomerTagEnabled ? 'grade' : 'tag'}/list`,
      payload
    );
  }

  onGetTagDropdown(
    payload: object,
    isCustomerTagEnabled = false
  ): Observable<object> {
    return this.httpClient.post(
      `${isCustomerTagEnabled ? 'grade' : 'tag'}/query`,
      payload
    );
  }

  onAddEditTag(
    payload: object,
    isCustomerTagEnabled = false
  ): Observable<object> {
    if (payload['id']) {
      return this.httpClient.put(
        `${isCustomerTagEnabled ? 'grade' : 'tag'}`,
        payload
      );
    } else {
      return this.httpClient.post(
        `${isCustomerTagEnabled ? 'grade' : 'tag'}`,
        payload
      );
    }
  }
}
