import { ManageusersService } from './../../http/manageusers.service';
import { ExportdatadialogComponent } from './../matdialogs/exportdatadialog/exportdatadialog.component';
import { ViewCallInformationDialogComponent } from './../matdialogs/viewcallinformationdialog/viewcallinformationdialog.component';
import { UsertokenService } from './../../http/usertoken.service';
import { SystemusageService } from './../../http/systemusage.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {
  state,
  trigger,
  style,
  AUTO_STYLE,
  animate,
  transition,
} from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserInfo } from 'src/app/routes/account/models/user.model';
import { ManageclientsService } from '../../http/manageclients.service';
import * as moment from 'moment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import * as $ from 'jquery';
import { UtilityService } from '../../http/utility.service';
import { Subscription } from 'rxjs';

const DEFAULT_DURATION = 300;

@Component({
  selector: 'app-systemusage',
  templateUrl: './systemusage.component.html',
  styleUrls: ['./systemusage.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('true', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate(DEFAULT_DURATION + 'ms ease-in')),
      transition('true => false', animate(DEFAULT_DURATION + 'ms ease-out')),
    ]),
  ],
})
export class SystemusageComponent implements OnInit {
  public currentUser: UserInfo;
  public rangeFilter: UntypedFormGroup;
  public collapsed = true;
  public historyPayload = {
    client_ids: [],
    team_ids: [],
    user_ids: [],
    from: null,
    to: null,
    items: ['INCOMING_CALL', 'OUTGOING_CALL', 'MISSED_CALL', 'VIDEO', 'IMAGE'],
    main_tags: [],
    sub_tags: [],
    start_index: 0,
    page_size: 20,
    search_key: '',
  };
  public missedCallDataPayload = {
    client_ids: [],
    team_ids: [],
    user_ids: [],
    from: null,
    to: null,
    items: ['MISSED_CALL'],
    main_tags: [],
    sub_tags: [],
    start_index: 0,
    page_size: 5,
    search_key: '',
  };
  public total_rows = 0;
  public systemUsage = [];
  public todaysMissedCallsData = [];
  public totalmissedCalls_rows = 0;
  public typAheadData = [];
  public payloadItems = [
    { checked: true, name: 'Include incoming Calls', id: 'INCOMING_CALL' },
    { checked: true, name: 'Include outgoing Calls', id: 'OUTGOING_CALL' },
    { checked: true, name: 'Include missed Calls', id: 'MISSED_CALL' },
    { checked: true, name: 'Include uploaded videos', id: 'VIDEO' },
    { checked: true, name: 'Include uploaded images', id: 'IMAGE' },
  ];
  public associatedTeamList = [];
  public exportsYetToDownload = 0;
  public isShowingOnlyMissedCall = true;
  public isFirstTimeLoading = true;
  public isShowingMissedCalls = false;
  public tagsQueryPayload = {
    client_id: null,
    team_id: null,
    parent_id: null,
  };
  public getAllTagsPayload = {
    client_ids: [],
    parent_ids: [],
  };
  public allMainTagListDropdown = [];
  public mainTagModel = null;
  public allSubTagListDropdown = [];
  public subTagModel = null;
  public doRefreshMissedCallSubscription: Subscription;
  // CS Grading Feature
  public gradeCategorySubCategories = [];
  public gradeCategory = [];
  public gradeSubCategory = [];
  public isCSCategoryDropdownVisible = false;
  public isCSSubCategoryDropdownVisible = false;
  public attachGradeToTransactionForm: UntypedFormGroup;
  public selectedCSCategory = 'Select Category';
  public selectedCSSubCategory = 'Select Sub Category';
  public isSaveGradeDisable = true;
  // CS Grading Feature

  constructor(
    private systemUsageSVC: SystemusageService,
    private router: Router,
    private userTokenSVC: UsertokenService,
    private manageClientsSVC: ManageclientsService,
    public dialog: MatDialog,
    private manageUserSVC: ManageusersService,
    private utilitySVC: UtilityService
  ) {
    this.userTokenSVC.currentUser.subscribe((x) => (this.currentUser = x));
    if (
      this.currentUser.data.user.role === 'CLIENT_ADMIN' ||
      this.currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE' ||
      this.currentUser.data.user.role === 'TEAM_MANAGER'
    ) {
      this.historyPayload.client_ids.push(this.currentUser.data.user.client_id);
      this.missedCallDataPayload.client_ids.push(
        this.currentUser.data.user.client_id
      );
      this.getAllTagsPayload.client_ids = [...this.historyPayload.client_ids];
    }
    this.doRefreshMissedCallSubscription = this.utilitySVC
      .onGetRefreshMissedCallStatus()
      .subscribe((status) => {
        if (status) {
          this.onGetTodaysMissedCalls(this.missedCallDataPayload);
        }
      });
    // CS Grading Feature
    this.attachGradeToTransactionForm = new UntypedFormGroup({
      entry_id: new UntypedFormControl(null),
      entry_type: new UntypedFormControl(null),
      comment: new UntypedFormControl(''),
      rating: new UntypedFormControl(0),
      category: new UntypedFormControl('Select Category', Validators.required),
      subCategory: new UntypedFormControl('Select Sub Catgeory', Validators.required),
    });
    this.attachGradeToTransactionForm.valueChanges.subscribe((change) => {
      if (
        change.category === null &&
        change.subCategory === null &&
        change.comment === ''
      ) {
        this.isSaveGradeDisable = true;
      } else {
        this.isSaveGradeDisable = false;
      }
      if (change.category !== null && change.subCategory === null) {
        this.isSaveGradeDisable = true;
      }
    });
    // CS Grading Feature
  }

  ngOnInit(): void {
    this.historyPayload.from = moment().format('YYYY-MM-DD');
    this.historyPayload.to = moment().format('YYYY-MM-DD');
    this.missedCallDataPayload.from = moment().format('YYYY-MM-DD');
    this.missedCallDataPayload.to = moment().format('YYYY-MM-DD');
    if (
      this.currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE' ||
      this.currentUser.data.user.role === 'TEAM_MANAGER'
    ) {
      this.onGetAssociatedTeams();
    } else {
      this.onGetTodaysMissedCalls(this.missedCallDataPayload);
      this.onGetSystemUsage(this.historyPayload);
    }
    this.onGetAllTags(true);
  }

  onGetTodaysMissedCalls(historyPayload: object): void {
    this.systemUsageSVC.onGetSystemUsage(historyPayload).subscribe((res) => {
      this.todaysMissedCallsData = [...res['data']['rows']];
      this.totalmissedCalls_rows = res['data']['total_rows'];
    });
  }

  toggleFilterCollapse(): void {
    this.collapsed = !this.collapsed;
  }

  onAddRemovePayloadItems(value: string): void {
    let addFlag: boolean;
    this.historyPayload.items.forEach((item) => {
      if (value === item) {
        addFlag = true;
      }
    });
    if (addFlag === true) {
      this.historyPayload.items = this.historyPayload.items.filter(
        (item) => item !== value
      );
      return;
    } else {
      this.historyPayload.items.push(value);
      return;
    }
  }

  onGetSystemUsage(historyPayload: object): void {
    this.systemUsageSVC.onGetSystemUsage(historyPayload).subscribe((res) => {
      this.systemUsage = [...res['data']['rows']];
      this.total_rows = res['data']['total_rows'];
      this.onGetExportSummary();
    });
  }

  onNavigateToJobDetails(authKey: string): void {
    if (authKey) {
      this.router.navigate(['/job-details'], { queryParams: { authKey } });
    }
  }

  onUserTypeAheadSearch(event: string, type: string): void {
    this.typAheadData = [];
    switch (type) {
      case 'clients':
        this.manageClientsSVC.onGetClientsDropdown(event).subscribe((res) => {
          this.typAheadData = [...res['data']];
        });
        break;
      case 'teams':
        let payload = {
          client_ids: [...this.historyPayload.client_ids],
          search_key: event,
        };
        this.manageClientsSVC.onGetTeamsDropdown(payload).subscribe((res) => {
          this.typAheadData = [...res['data']];
        });
        break;
      case 'users':
        let usersPayload = {
          client_ids: [...this.historyPayload.client_ids],
          team_ids: [...this.historyPayload.team_ids],
          search_key: event,
        };
        this.manageClientsSVC
          .onGetUsersDropdown(usersPayload)
          .subscribe((res) => {
            this.typAheadData = [...res];
          });
        break;
      default:
        break;
    }
  }

  onGetUserSelectedTypeAheadPills(event, type: string): void {
    switch (type) {
      case 'clients':
        let clientIds = [];
        for (const item of event) {
          clientIds.push(item['id']);
        }
        this.historyPayload.client_ids = [...clientIds];
        this.mainTagModel = null;
        this.subTagModel = null;
        this.getAllTagsPayload.client_ids = [];
        this.getAllTagsPayload.parent_ids = [];
        if (this.historyPayload.client_ids.length > 0) {
          this.getAllTagsPayload.client_ids = [
            ...this.historyPayload.client_ids,
          ];
          this.onGetAllTags(true);
        } else {
          this.allMainTagListDropdown = [];
          this.allSubTagListDropdown = [];
          this.historyPayload.main_tags = [];
          this.historyPayload.sub_tags = [];
        }
        break;
      case 'teams':
        let teamIds = [];
        for (const item of event) {
          teamIds.push(item['id']);
        }
        this.historyPayload.team_ids = [...teamIds];
        break;
      case 'users':
        let userIds = [];
        for (const item of event) {
          userIds.push(item['id']);
        }
        this.historyPayload.user_ids = [...userIds];
        break;
      default:
        break;
    }
    // this.typAheadData = [];
  }

  onApplyFilter(): void {
    this.systemUsage = [];
    this.onGetSystemUsage(this.historyPayload);
  }

  onDatePickerChange(event) {
    this.historyPayload.from = moment(event.from).clone().format('YYYY-MM-DD');
    if (event.to !== this.historyPayload.to && event.to !== null) {
      this.historyPayload.to = moment(event.to).clone().format('YYYY-MM-DD');
      this.onGetSystemUsage(this.historyPayload);
    }
  }

  onPageChange(startIndex: number, type: string) {
    if (type === 'allcalls') {
      this.historyPayload.start_index = startIndex;
      this.systemUsage = [];
      this.onGetSystemUsage(this.historyPayload);
    } else {
      this.missedCallDataPayload.start_index = startIndex;
      this.todaysMissedCallsData = [];
      this.onGetTodaysMissedCalls(this.missedCallDataPayload);
    }
  }

  onSearchFilter(event): void {
    let searchKey = event.target.value;
    if (searchKey.length > 1) {
      this.historyPayload.search_key = searchKey;
      this.onGetSystemUsage(this.historyPayload);
    } else if (searchKey.length === 0) {
      this.historyPayload.search_key = '';
      this.onGetSystemUsage(this.historyPayload);
    }
  }

  onViewCallInformations(
    title: string,
    data: Array<any>,
    type: string,
    sessionId: string = null
  ): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width =
      type === 'VIDEO' || type === 'RECORDINGS' || type === 'CALLLOG'
        ? '60%'
        : '30%';
    dialogConfig.panelClass = 'ion-dialog-wrapper';
    dialogConfig.data = {
      dialogTitle: title,
      data,
      type,
    };
    if (type === 'RECORDINGS' || type === 'CHAT') {
      dialogConfig.data.sessionId = sessionId;
    }
    if (type === 'CALLLOG') {
      dialogConfig.data.forwardLog = data;
    }
    this.dialog.open(ViewCallInformationDialogComponent, dialogConfig);
  }

  onOpenExportDataDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '50%';
    dialogConfig.panelClass = 'ion-dialog-wrapper';
    dialogConfig.data = { ...this.historyPayload };
    this.dialog.open(ExportdatadialogComponent, dialogConfig);
  }

  onGetAssociatedTeams(): void {
    this.manageUserSVC.onGetAssociatedTeams().subscribe((res) => {
      this.associatedTeamList = res['data'].teams;
      this.associatedTeamList.map((data) => {
        this.historyPayload.team_ids.push(data['_id']);
        this.missedCallDataPayload.team_ids.push(data['_id']);
      });
      this.onGetSystemUsage(this.historyPayload);
      this.onGetTodaysMissedCalls(this.missedCallDataPayload);
    });
  }

  onGetExportSummary(): void {
    this.systemUsageSVC.onGetExportSummary().subscribe((res) => {
      this.exportsYetToDownload = res['data']['alert'];
    });
  }

  onConvertPODToPOQ(value: string, dataCount: number): string {
    if (value === 'pod') {
      return `Proof${dataCount === 1 ? '' : 's'} of Quality`;
    } else {
      return value + `${dataCount === 1 ? '' : 's'}`;
    }
  }

  onShowMissedCallsorAllTransaction(isMissedCallOnly: boolean): void {
    this.isShowingMissedCalls = isMissedCallOnly;
    this.historyPayload.items = [];
    this.payloadItems.map((item) => {
      if (!this.isShowingMissedCalls) {
        item.checked = true;
        this.historyPayload.items.push(item.id);
      } else {
        if (item.id === 'MISSED_CALL') {
          item.checked = true;
          this.historyPayload.items.push(item.id);
        } else {
          item.checked = false;
        }
      }
    });
    this.onGetSystemUsage(this.historyPayload);
  }

  onToggleMissedCallFilter(event: MatSlideToggleChange): void {
    this.isShowingOnlyMissedCall = event.checked;
    this.historyPayload.items = [];
    this.payloadItems.map((item) => {
      if (!this.isShowingOnlyMissedCall) {
        if (item.id !== 'MISSED_CALL') {
          item.checked = true;
          this.historyPayload.items.push(item.id);
        } else {
          item.checked = false;
        }
      } else {
        if (item.id === 'MISSED_CALL') {
          item.checked = true;
          this.historyPayload.items.push(item.id);
        } else {
          item.checked = false;
        }
      }
    });
  }

  onGetAllTags(isParent: boolean): void {
    this.systemUsageSVC
      .onGetAllTags(this.getAllTagsPayload)
      .subscribe((res) => {
        if (isParent) {
          this.allMainTagListDropdown = [...res['data']];
        } else {
          this.allSubTagListDropdown = [...res['data']];
        }
      });
  }

  onStringifyTagIds(ids: Array<string>): string {
    return JSON.stringify(ids);
  }

  onChangeTag(isParent: boolean): void {
    if (isParent) {
      if (this.mainTagModel !== 'null') {
        let mainTags = JSON.parse(this.mainTagModel);
        this.getAllTagsPayload.parent_ids = [...mainTags];
        this.historyPayload.main_tags = [...mainTags];
        this.onGetAllTags(!isParent);
      } else {
        this.historyPayload.main_tags = [];
      }
    } else {
      if (this.subTagModel !== 'null') {
        let subTags = JSON.parse(this.subTagModel);
        this.historyPayload.sub_tags = [...subTags];
      } else {
        this.historyPayload.sub_tags = [];
      }
    }
  }

  onResetFilter(): void {
    $('app-iontypeahead').find('.pill-holder').find('li i').trigger('click');
    this.typAheadData = [];
    this.historyPayload = {
      client_ids: [],
      team_ids: [],
      user_ids: [],
      from: moment().format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      items: [
        'INCOMING_CALL',
        'OUTGOING_CALL',
        'MISSED_CALL',
        'VIDEO',
        'IMAGE',
      ],
      main_tags: [],
      sub_tags: [],
      start_index: 0,
      page_size: 20,
      search_key: '',
    };
    this.mainTagModel = null;
    this.subTagModel = null;
    this.payloadItems.map((item) => {
      item.checked = true;
    });
    this.historyPayload.client_ids.push(this.currentUser.data.user.client_id);
    this.onGetSystemUsage(this.historyPayload);
  }

  // CS Grading Feature
  onGetCSGrades(
    teamId: string,
    event: any,
    type: string,
    entry_id: string
  ): void {
    this.systemUsageSVC.onGetCSGrades(teamId).subscribe((res) => {
      this.gradeCategorySubCategories = [...res['data']];
      this.gradeCategory = [];
      this.gradeCategory = res['data']
        .filter((item) => !item.parent_id)
        .sort((a, b) => {
          if (a.display_en < b.display_en) {
            return -1;
          } else if (a.display_en > b.display_en) {
            return 1;
          } else {
            return 0;
          }
        });
      $(event.target).addClass('d-none');
      $(event.target).parent().next().removeClass('d-none');
      this.attachGradeToTransactionForm.patchValue({
        entry_type: type.toLowerCase(),
        entry_id,
        comment: '',
        rating: 0,
        category: null,
        subCategory: null,
      });
    });
  }

  onEditCSGrades(
    teamId: string,
    event: any,
    additionalInfo: object,
    type: string,
    entry_id: string
  ): void {
    this.systemUsageSVC.onGetCSGrades(teamId).subscribe((res) => {
      this.gradeCategorySubCategories = [...res['data']];
      this.gradeCategory = [];
      this.gradeCategory = res['data']
        .filter((item) => !item.parent_id)
        .sort((a, b) => {
          if (a.display_en < b.display_en) {
            return -1;
          } else if (a.display_en > b.display_en) {
            return 1;
          } else {
            return 0;
          }
        });
      $(event.target).addClass('d-none');
      $(event.target).parent().prev().addClass('d-none');
      $(event.target).parent().prev().prev().removeClass('d-none');
      // console.log(additionalInfo);
      this.attachGradeToTransactionForm.patchValue({
        entry_type: type.toLowerCase(),
        entry_id,
        comment: additionalInfo['feedbacks'].comment,
        rating: additionalInfo['feedbacks'].rating,
        category:
          additionalInfo['feedbacks']['grades'].length > 0
            ? additionalInfo['feedbacks']['grades'][0]['grade_id']
            : null,
        subCategory:
          additionalInfo['feedbacks']['grades'].length > 0
            ? additionalInfo['feedbacks']['grades'][1]['grade_id']
            : null,
      });
      this.selectedCSCategory =
        additionalInfo['feedbacks']['grades'].length > 0
          ? additionalInfo['feedbacks']['grades'][0]['grade']
          : 'Select Category';
      this.selectedCSSubCategory =
        additionalInfo['feedbacks']['grades'].length > 0
          ? additionalInfo['feedbacks']['grades'][1]['grade']
          : 'Select Sub Category';
      this.gradeSubCategory = [];
      if (additionalInfo['feedbacks']['grades'].length > 0) {
        this.gradeSubCategory = this.gradeCategorySubCategories.filter(
          (item) =>
            item.parent_id ===
            additionalInfo['feedbacks']['grades'][0]['grade_id']
        );
      }
    });
  }

  onChooseCSGradeCategory(
    parent_id: string,
    categoryName: string,
    type: string,
    id: string
  ): void {
    this.gradeSubCategory = [];
    this.gradeSubCategory = this.gradeCategorySubCategories.filter(
      (item) => item.parent_id === parent_id
    );
    this.selectedCSCategory = categoryName;
    this.isCSCategoryDropdownVisible = false;
    this.selectedCSSubCategory = 'Select Sub Category';
    this.attachGradeToTransactionForm.patchValue({
      entry_id: id,
      entry_type: type.toLowerCase(),
      category: parent_id,
      subCategory: null,
    });
  }

  onSelectCSSubCategory(id: string, subCatName: string) {
    this.selectedCSSubCategory = subCatName;
    this.isCSSubCategoryDropdownVisible = false;
    this.attachGradeToTransactionForm.patchValue({
      subCategory: id,
    });
  }

  onAttachGradeToTransaction(): void {
    let payload = {
      ...this.attachGradeToTransactionForm.value,
    };
    let grades = [];
    if (payload.category !== null && payload.category !== 'Select Category') {
      grades = [
        { level: 0, grade: payload.category },
        { level: 1, grade: payload.subCategory },
      ];
    }
    payload = {
      ...payload,
      grades,
    };
    delete payload.category;
    delete payload.subCategory;
    this.systemUsageSVC.onAttachGradeToTransaction(payload).subscribe((res) => {
      this.onGetSystemUsage(this.historyPayload);
    });
  }

  onCancelGradingForm(event): void {
    this.attachGradeToTransactionForm.reset();
    this.selectedCSSubCategory = 'Select Sub Category';
    this.selectedCSCategory = 'Select Category';
    $(event.target).parent().parent().prev().find('.add').removeClass('d-none');
    $(event.target)
      .parent()
      .parent()
      .next()
      .next()
      .find('.edit')
      .removeClass('d-none');
    $(event.target).parent().parent().next().removeClass('d-none');
    $(event.target).parent().parent().addClass('d-none');
  }

  // CS Grading Feature
}
