import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-custommatdatepicker',
  templateUrl: './custommatdatepicker.component.html',
  styleUrls: ['./custommatdatepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: CustommatdatepickerComponent
    },
    DatePipe
  ],
})
export class CustommatdatepickerComponent implements ControlValueAccessor, OnInit {

  value = null;
  onChange = (value: any) => { };
  onTouched = () => { };
  touched = false;
  disabled = false;
  max=new Date();
  min=new Date();
  writeValue(value: any) {
    this.value = value;
  }
  @Output() change: EventEmitter<Date> = new EventEmitter();
  @Output() rangeChange: EventEmitter<{}> = new EventEmitter();
  @Input() isInlinePicker = false;
  @Input() calendarType = 'normal';
  @Input() from = null;
  @Input() to = null
  public range = new UntypedFormGroup({
    from: new UntypedFormControl(),
    to: new UntypedFormControl()
  });

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  @Input() labelText = '';
  @Input() dateRangeRestricted:boolean = false;


  constructor(
    public datepipe: DatePipe,
  ) {
  }

  ngOnInit(): void {
    this.range.patchValue({
      from: this.from,
      to: this.to,
    })
    if(this.dateRangeRestricted){
      this.min=new Date(new Date().setMonth(new Date().getMonth()-2));
      this.max=new Date();
    }
    else{
      this.max=null;
      this.min=null;
    }
    
  }

  onFocusDatePickerInput(event: any) {
    this.markAsTouched();
  }

  onDatevalueChange(event: MatDatepickerInputEvent<any>): void {
    this.markAsTouched();
    this.onChange(event.value);
    this.change.emit(event.value);
  }

  onRangeChange(): void {
    this.rangeChange.emit(this.range.value);
  }

}
