<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Manage Tag</span>
    <i class="far fa-times-circle cp" (click)="close(false)"></i>
</h2>

<div class="scroll-holder">
    <form [formGroup]="manageTagForm" (ngSubmit)="onAddEditTag()">
        <div class="ion-form-group m-b-15">
            <mat-checkbox formControlName="isParentActive" (change)="onDisableEnableParentTagAdding($event)">Add as
                parent tag</mat-checkbox>
        </div>
        <div *ngIf="!this.manageTagForm.controls['isParentActive'].value" class="ion-form-group">
            <label class="label-text">Parent Tag</label>
            <select formControlName="parent_id" (change)="onParentTagChange(selectedValue.value)" #selectedValue>
                <option value="null">Select parent tag</option>
                <option value="{{ tag._id }}" *ngFor="let tag of parentTagList">{{ tag.display_en }}</option>
            </select>
        </div>
        <div class="ion-form-group">
            <label class="label-text">Tag Name (en)</label>
            <input type="text" formControlName="display_en">
        </div>
        <div class="ion-form-group">
            <label class="label-text">Tag Name (fr)</label>
            <input type="text" formControlName="display_fr">
        </div>
        <div class="ion-form-group">
            <label class="label-text">Tag Name (esp)</label>
            <input type="text" formControlName="display_esp">
        </div>
        <div class="ion-form-group">
            <label class="label-text">Tag Name (de)</label>
            <input type="text" formControlName="display_de">
        </div>
        <div class="ion-form-group">
            <mat-slide-toggle formControlName="is_active">Activate / In-activate Tag</mat-slide-toggle>
        </div>
        <div class="d-flex justify-content-end">
            <button [disabled]="!manageTagForm.valid" type="submit" class="ion-theme-btn primary">Submit</button>
        </div>
    </form>
</div>