
import { UsertokenService } from './../../../http/usertoken.service';
import { UserInfo } from 'src/app/routes/account/models/user.model';
import { ManageusersService } from 'src/app/shared/http/manageusers.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-manageblockdialog',
  templateUrl: './manageblockdialog.component.html',
  styleUrls: ['./manageblockdialog.component.scss']
})
export class ManageblockdialogComponent implements OnInit {

  public receivedDataObj = {};
  public userForm: UntypedFormGroup;
  public currentUser: UserInfo;

  constructor(
    public dialogRef: MatDialogRef<ManageblockdialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedDataObj,
    private manageUserSVC: ManageusersService,
    private userTokenSVC: UsertokenService,
  ) {
    this.userTokenSVC.currentUser.subscribe(x => this.currentUser = x);
    this.userForm = new UntypedFormGroup({
      remark: new UntypedFormControl('', Validators.required),
    })
    if (ReceivedDataObj !== null) {
      this.receivedDataObj = { ...ReceivedDataObj };
      this.userForm.patchValue({
        remark: this.receivedDataObj['remark'],
      });
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  onCreateUpdateUser(): void {
    let payload={
              "user_id": this.receivedDataObj['_id'],
              "remark": this.userForm.value.remark
            }
    this.manageUserSVC.onblockUser(payload).subscribe(res => {
      this.close();
    })
  }

}