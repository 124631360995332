import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VerifyuserService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onVerifyUser(receivedToken: any) {
    return this.httpClient.post('user/v?t=' + receivedToken, '');
  }

}
