import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GoogleapiService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onGetLocationNameGoogleAPI(lat: number, long: number) {
    return this.httpClient.get
      (environment.corsurl + 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat + ',' + long +
        '&sensor=true&key=' + environment.googleAPIMapKey);
  }

  onTranslateChat(textToTranslate: string, sourceLang: string, targetLang: string) {
    return this.httpClient.get('https://translation.googleapis.com/language/translate/v2/?q=' + textToTranslate +
      '&source=' + sourceLang + '&target=' + targetLang + '&key=' + environment.googleAPIMapKey);
  }

  onGetSupportedLanguages() {
    return this.httpClient.get('https://translation.googleapis.com/language/translate/v2/languages/?target=en&key='
      + environment.googleAPIMapKey);
  }

}
