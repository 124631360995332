<div class="ion-form-group" [ngClass]="{'is-stacked': isStacked}">
    <div class="d-flex" [ngClass]="{'flex-column': isStacked}">
        <div class="label-input d-flex align-items-center"
            [ngClass]="{'flex-column align-items-start justify-content-start': isStacked}">
            <label class="label-text d-flex justify-content-between">
                {{ labelText }}
                <button 
                    *ngIf="isEnableClearAll"
                    (click)="onClearAllPills()"
                    class="ion-theme-btn primary xs" style="text-transform: none;">Clear all</button>
            </label>
            <input
                (focusout)="onFocusOutSearch()" 
                [disabled]="isDisabled" type="text" [placeholder]="placeHolderString"
                #triggerTypeAheadList (click)="hideTypeAheadList($event)" (keyup)="onTypeAhead($event)"
                [(ngModel)]="typeAheadSearch" [matMenuTriggerFor]="typeAheadSuggestions">
            <mat-menu #typeAheadSuggestions="matMenu" class="type-ahead-list-data" [overlapTrigger]="false">
                <ul class="search-list" *ngIf="dropDownData?.length > 0; else nodata">
                    <li *ngFor="let data of dropDownData" (click)="onBindSelectedValue($event, data)"
                        id="{{ keyToSend }}">{{ data.name }}
                    </li>
                </ul>
                <ng-template #nodata>
                    <p class="m-b-0">No data to display</p>
                </ng-template>
            </mat-menu>
        </div>
        <ul class="pill-holder" [ngClass]="{'m-l-0 m-t-10': isStacked}">
            <li *ngFor="let pill of selectedPillsArray">
                <span>{{ pill.name }}</span>
                <i class="fas fa-times cp" (click)="deletePillsFromList(pill)"></i>
            </li>
        </ul>
    </div>
</div>