import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManagetransactionsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onGetUnmappedTransactions(payload: object): Observable<object> {
    return this.httpClient.post('dashboard/entry/unmap/list', payload);
  }

  onValidateAuthKeyForVideoTransfer(payload: object): Observable<object> {
    return this.httpClient.post('authkey/detail', payload);
  }

  onForwardVideoAndImage(payload: object, type: string): Observable<object> {
    return this.httpClient.post(`${type}/forward`, payload);
  }

  onListTransactionKeys(payload: object): Observable<object> {
    return this.httpClient.post('authkey/list', payload);
  }

  onExportTransactionKeys(payload: object): Observable<string> {
    return this.httpClient.post(`authkey/export`, payload, { responseType: 'text' })
  }

  onDeleteTransactionKey(id: string): Observable<object> {
    return this.httpClient.delete(`authkey/id/${id}`);
  }

  onValidateTransactionRemapping(id: string): Observable<object> {
    return this.httpClient.get(`authkey/id/${id}/usage`);
  }

  onRemapTransactionKey(payload: object): Observable<object> {
    return this.httpClient.post('authkey/remap', payload)
  }

}
