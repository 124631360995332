<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>{{modeClass ? 'Manage Tag Labels' : 'Manage Team'}}</span>
    <i class="far fa-times-circle cp" (click)="close()"></i>
</h2>

<form *ngIf="teamForm" [formGroup]="teamForm" (ngSubmit)="onCreateUpdateTeams()" [ngClass]="modeClass">
    <div class="ion-form-group mt-0 io-team-details">
        <label class="label-text">Name</label>
        <input type="text" formControlName="name">
    </div>
    <div class="ion-form-group mt-0 io-team-details">
        <label class="label-text">Description</label>
        <textarea class="txt-area" formControlName="description"></textarea>
    </div>
    
    <div class="ion-form-group mt-0 tag-config">
        <mat-accordion>
            <mat-expansion-panel [formGroup]="tagForm" expanded="true">
              <mat-expansion-panel-header>
                <mat-panel-title>Main Tag Labels</mat-panel-title>
                <mat-panel-description>
                  
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div>
                <div class="ion-form-group mt-0">
                    <label class="label-text">Tag Title (en)</label>
                    <input type="text" formControlName="display_en">
                </div>
                <div class="ion-form-group mt-0">
                    <label class="label-text">Tag Title (fr)</label>
                    <input type="text" formControlName="display_fr">
                </div>
                <div class="ion-form-group mt-0">
                    <label class="label-text">Tag Title (esp)</label>
                    <input type="text" formControlName="display_esp">
                </div>
                <div class="ion-form-group mt-0">
                    <label class="label-text">Tag Title (de)</label>
                    <input type="text" formControlName="display_de">
                </div>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel [formGroup]="subTagForm">
              <mat-expansion-panel-header>
                <mat-panel-title>Sub Tag Labels</mat-panel-title>
                <mat-panel-description>
                  
                </mat-panel-description>
              </mat-expansion-panel-header>
              <div>
                <div class="ion-form-group mt-0">
                    <label class="label-text">Sub Tag Title (en)</label>
                    <input type="text" formControlName="display_en">
                </div>
                <div class="ion-form-group mt-0">
                    <label class="label-text">Sub Tag Title (fr)</label>
                    <input type="text" formControlName="display_fr">
                </div>
                <div class="ion-form-group mt-0">
                    <label class="label-text">Sub Tag Title (esp)</label>
                    <input type="text" formControlName="display_esp">
                </div>
                <div class="ion-form-group mt-0">
                    <label class="label-text">Sub Tag Title (de)</label>
                    <input type="text" formControlName="display_de">
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
    </div>

    <div class="ion-form-group io-team-details">
        <mat-slide-toggle formControlName="is_default">Make this team as default</mat-slide-toggle>
    </div>
    <div class="d-flex justify-content-end">
        <button type="submit" [disabled]="!teamForm.valid" class="ion-theme-btn primary">Submit</button>
    </div>
</form>