import { ToastrService } from 'ngx-toastr';
import { ManageteamsService } from './../../../http/manageteams.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-manageteamdialog',
  templateUrl: './manageteamdialog.component.html',
  styleUrls: ['./manageteamdialog.component.scss']
})
export class ManageteamdialogComponent implements OnInit {

  public receivedDataObj = {};
  public teamForm: UntypedFormGroup;
  modeClass: string = "";

  constructor(
    public dialogRef: MatDialogRef<ManageteamdialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedDataObj,
    private manageTeamSVC: ManageteamsService,
    private toastr: ToastrService
  ) {
    this.teamForm = new UntypedFormGroup({
      name: new UntypedFormControl(null, Validators.required),
      description: new UntypedFormControl(null),
      is_default: new UntypedFormControl(false),
      client_id: new UntypedFormControl(null),
      tag_config: new UntypedFormGroup({
        tag_title: new UntypedFormGroup({
          display_en: new UntypedFormControl(null),
          display_fr: new UntypedFormControl(null),
          display_esp: new UntypedFormControl(null),
          display_de: new UntypedFormControl(null),
        }),
        tag_subtitme: new UntypedFormGroup({
          display_en: new UntypedFormControl(null),
          display_fr: new UntypedFormControl(null),
          display_esp: new UntypedFormControl(null),
          display_de: new UntypedFormControl(null),
        }),
      })
    });
    console.log(ReceivedDataObj, "ReceivedDataObjReceivedDataObj");
    
    if (ReceivedDataObj !== null) {
      let {modeClass} = ReceivedDataObj;
      this.modeClass = modeClass;
      this.receivedDataObj = { ...this.receivedDataObj, ...ReceivedDataObj };
      this.teamForm.patchValue({
        client_id: this.receivedDataObj['client_id']
      });
      if (this.receivedDataObj['_id']) {
        this.teamForm.patchValue({
          name: this.receivedDataObj['name'],
          description: this.receivedDataObj['description'],
          is_default: this.receivedDataObj['is_default'],
          tag_config: this.receivedDataObj['tag_config']
        })
      }
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  onCreateUpdateTeams(): void {
    let payload = this.teamForm.value;
    if (this.receivedDataObj['_id']) {
      payload.id = this.receivedDataObj['_id'];
    }
    this.manageTeamSVC.onCreateUpdate(payload).subscribe(res => {
      if(this.receivedDataObj['_id']){

        if(this.modeClass){
          this.toastr.success('Tag Labels Updated successfully!');
        }else {
          this.toastr.success('Team Updated successfully!');
        }

      }else{
        this.toastr.success('Team created successfully!');
      }
      this.close();
    })
  }

  get tagForm() {
    return this.teamForm.get('tag_config').get('tag_title') as UntypedFormGroup;
  }

  get subTagForm() {
    return this.teamForm.get('tag_config').get('tag_subtitme') as UntypedFormGroup;
  }

}
