import { ViewCallInformationDialogComponent } from './components/matdialogs/viewcallinformationdialog/viewcallinformationdialog.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CustommatdatepickerComponent } from './components/custommatdatepicker/custommatdatepicker.component';
import { IontypeaheadComponent } from './components/iontypeahead/iontypeahead.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FooterComponent } from './components/footer/footer.component';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';
import { BarRatingModule } from 'ngx-bar-rating';
import { MatDialogModule } from '@angular/material/dialog';
import { SystemusageComponent } from './components/systemusage/systemusage.component';
import { CallfeedbackDialogComponent } from './components/matdialogs/callfeedback/callfeedback.component';
import { ManageclientsdialogComponent } from './components/matdialogs/manageclientsdialog/manageclientsdialog.component';
import { TablepaginationComponent } from './components/tablepagination/tablepagination.component';
import { LongarrowIconComponent } from './svgicons/longarrow/longarrow.component';
import { ManageuserdialogComponent } from './components/matdialogs/manageuserdialog/manageuserdialog.component';
import { ExportdatadialogComponent } from './components/matdialogs/exportdatadialog/exportdatadialog.component';
import { ManageteamdialogComponent } from './components/matdialogs/manageteamdialog/manageteamdialog.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserlistforteamsdialogComponent } from './components/matdialogs/userlistforteamsdialog/userlistforteamsdialog.component';
import { ManageunmappedtrandialogComponent } from './components/matdialogs/manageunmappedtrandialog/manageunmappedtrandialog.component';
import { InitiatecallfromwebComponent } from './components/initiatecallfromweb/initiatecallfromweb.component';
import { ChangelanguagedialogComponent } from './components/matdialogs/changelanguagedialog/changelanguagedialog.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ManagetagsdialogComponent } from './components/matdialogs/managetagsdialog/managetagsdialog.component';
import { FiltericonComponent } from './svgicons/filtericon/filtericon.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { RemaptransactiondialogComponent } from './components/matdialogs/remaptransactiondialog/remaptransactiondialog.component';
import { LocalsearchfilterpipePipe } from './pipes/localsearchfilterpipe.pipe';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatNativeDateModule } from '@angular/material/core';
import { UsernotificationdialogComponent } from './components/matdialogs/usernotificationdialog/usernotificationdialog.component';
import { ManageblockdialogComponent } from './components/matdialogs/manageblockdialog/manageblockdialog.component';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
  declarations: [
    CustommatdatepickerComponent,
    IontypeaheadComponent,
    FooterComponent,
    ViewCallInformationDialogComponent,
    SystemusageComponent,
    CallfeedbackDialogComponent,
    ManageclientsdialogComponent,
    TablepaginationComponent,
    LongarrowIconComponent,
    ManageuserdialogComponent,
    ManageblockdialogComponent,
    ExportdatadialogComponent,
    ManageteamdialogComponent,
    UserlistforteamsdialogComponent,
    ManageunmappedtrandialogComponent,
    InitiatecallfromwebComponent,
    ChangelanguagedialogComponent,
    ManagetagsdialogComponent,
    FiltericonComponent,
    RemaptransactiondialogComponent,
    LocalsearchfilterpipePipe,
    UsernotificationdialogComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatMenuModule,
    MatCheckboxModule,
    CarouselModule,
    NgxUiLoaderModule,
    ToastrModule.forRoot(),
    BarRatingModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatTooltipModule,
    SweetAlert2Module.forRoot(),
    MatExpansionModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    CustommatdatepickerComponent,
    IontypeaheadComponent,
    MatMenuModule,
    MatCheckboxModule,
    FooterComponent,
    CarouselModule,
    NgxUiLoaderModule,
    ToastrModule,
    BarRatingModule,
    MatDialogModule,
    ViewCallInformationDialogComponent,
    SystemusageComponent,
    TablepaginationComponent,
    LongarrowIconComponent,
    ExportdatadialogComponent,
    ManageuserdialogComponent,
    ManageblockdialogComponent,
    MatSlideToggleModule,
    InitiatecallfromwebComponent,
    MatTooltipModule,
    FiltericonComponent,
    SweetAlert2Module,
    RemaptransactiondialogComponent,
    LocalsearchfilterpipePipe,
    UsernotificationdialogComponent,
  ],
  providers: [],
})
export class SharedModule {}
