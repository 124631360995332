<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Remap Transaction Key</span>
    <i class="far fa-times-circle cp" (click)="close(false)"></i>
</h2>

<div class="scroll-holder">
    <form [formGroup]="remapForm" (ngSubmit)="onProceedRemap()">
        <div class="ion-form-group" *ngIf="this.currentUser.data.user.role === 'SYS_ADMIN'">
            <label class="label-text">Clients</label>
            <select formControlName="to_client_id" (change)="onClientChange($event)">
                <option value="null">Select Client</option>
                <option [value]="client._id" *ngFor="let client of clientListData">{{ client.name }}</option>
            </select>
        </div>
        <div class="ion-form-group">
            <label class="label-text">Teams</label>
            <select formControlName="to_team_id" (change)="onTeamsChange($event)">
                <option value="null">Select Team</option>
                <option [value]="team._id" *ngFor="let team of teamListData">{{ team.name }}</option>
            </select>
        </div>
        <ng-container *ngIf="validateMsg !== ''; else noDataToMove">
            <p style="font-size: 14px;
            margin: 20px 0;
            color: #4a85c5;">{{ validateMsg }}</p>
        </ng-container>
        <ng-template #noDataToMove>
            <p style="font-size: 14px;
            margin: 20px 0;
            color: #4a85c5;" *ngIf="validateMsg !== ''">There is no transaction attached to this transaction.</p>
        </ng-template>
        <div class="d-flex justify-content-end">
            <button type="submit" class="ion-theme-btn primary"
                [disabled]="isDisableConfirmBtn">Confirm</button>
        </div>
    </form>
</div>