import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UsertokenService } from './../../shared/http/usertoken.service';
import { VerifyuserService } from './../../shared/http/verifyuser.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verifyuser',
  templateUrl: './verifyuser.component.html',
  styleUrls: ['./verifyuser.component.scss'],
})
export class VerifyuserComponent implements OnInit {
  public receievedVerifyToken = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private verifyUserSVC: VerifyuserService,
    private userTokenSVC: UsertokenService,
    private ngxLoaderSVC: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.ngxLoaderSVC.start();
    setTimeout(() => {
      this.activatedRoute.queryParamMap.subscribe((params) => {
        this.receievedVerifyToken = params['params']['t'];
        this.onVerifyUser(this.receievedVerifyToken);
      });
    }, 4000);
  }

  onVerifyUser(receivedToken: any) {
    this.ngxLoaderSVC.start();
    this.verifyUserSVC.onVerifyUser(receivedToken).subscribe(
      (res) => {
        if (res['data']['response'] === '/reset') {
          const tempObj = {
            is_success: true,
            error: null,
            data: {
              user: {
                user_verified: null,
                _id: null,
                user_name: null,
                email: null,
                permission_profile: null,
                role: null,
                user_name_type: null,
                client_id: null,
                first_name: null,
                last_name: null,
                language_preference: {
                  code: null,
                },
              },
              access_token: receivedToken,
              refresh_token: null,
              user_token: null,
              theme_data: {
                fav_icon_path: null,
                logo_path: null,
                square_logo_path: null,
                color_palatte: {
                  background: null,
                  background_light: null,
                  gradient_dark: null,
                  gradient_light: null,
                  phone_text: null,
                  primary: null,
                  primary_dark: null,
                  progress_border: null,
                  secondary: null,
                  secondary_dark: null,
                  secondary_light: null,
                  settings_info_text: null,
                  table_row_color: null,
                  web_header_text_color: null,
                  web_header_color: null,
                },
              },
            },
          };
          sessionStorage.setItem('userInfo', JSON.stringify(tempObj));
          
          this.userTokenSVC.currentUserSubject.next(tempObj);
          this.router.navigate(['account/reset-password']);
        } else {
          this.router.navigate(['account/login']);
        }
      },
      (error) => {
        this.ngxLoaderSVC.stop();
      },
      () => {
        this.ngxLoaderSVC.stop();
      }
    );
  }
}
