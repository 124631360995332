import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FeedbacksService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onSubmitFeedback(payload: object): Observable<object> {
    return this.httpClient.post('session/feedback', payload);
  }

}
