<h2 
    [ngClass]="{'m-b-0': receivedDataObj.entry_type !== 'call'}"
    class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Feedback Form</span>
</h2>
<ng-container *ngIf="receivedDataObj.id !== null">
    <p *ngIf="receivedDataObj.entry_type === 'call' && receivedDataObj.callFrom !== null">
        Call from <b>{{ receivedDataObj.callFrom }}</b> (Team: <b>{{ receivedDataObj.teamName }}</b> Transaction <b>{{ receivedDataObj.auth_key }})</b>
    </p>
    <p *ngIf="receivedDataObj.entry_type === 'call' && receivedDataObj.callTo !== null">
        Call with <b>{{ receivedDataObj.callTo }}</b> (Transaction <b>{{ receivedDataObj.auth_key }}</b>)
    </p>
    <form [formGroup]="feedbackForm" (ngSubmit)="onSubmitFeedbackForm()">
        <div class="d-flex justify-content-between align-items-center star-label-holder">
            <p class="m-b-0" *ngIf="receivedDataObj.entry_type === 'call' && receivedDataObj.callFrom !== null">Rate your call with {{ receivedDataObj.callFrom }}</p>
            <p class="m-b-0" *ngIf="receivedDataObj.entry_type === 'call' && receivedDataObj.callTo !== null">Rate your call with {{ receivedDataObj.callTo }}</p>
            <bar-rating [max]="5" formControlName="star_count"></bar-rating>
        </div>
        <div class="ion-form-group">
            <label class="label-text">Remarks/Comments</label>
            <textarea formControlName="comments"></textarea>
        </div>
        <div class="d-flex justify-content-end">
            <button [disabled]="!feedbackForm.valid" class="ion-theme-btn primary" type="submit">Submit</button>
        </div>
    </form>
</ng-container>