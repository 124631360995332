import { UsertokenService } from './shared/http/usertoken.service';
import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { UserInfo } from './routes/account/models/user.model';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isUserLoggedIn = false;
  public currentUser: UserInfo;
  public favIcon: HTMLLinkElement = document.querySelector('#dynamicFavicon');

  constructor(
    private router: Router,
    public location: Location,
    public activatedRoute: ActivatedRoute,
    private userTokenSVC: UsertokenService
  ) {
    this.userTokenSVC.currentUser.subscribe((x) => {
      this.currentUser = x;
      if (this.currentUser) {
        this.isUserLoggedIn = true;
      } else {
        this.isUserLoggedIn = false;
      }
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    if (this.currentUser && this.currentUser.data != null) {
      switch (this.currentUser.data.user.role) {
        case 'SYS_ADMIN':
          this.router.navigate(['administrator/dashboard']);
          break;
        case 'CLIENT_ADMIN':
          if (this.currentUser.data.theme_data !== null) {
            this.favIcon.href = this.currentUser.data.theme_data.fav_icon_path;
            document.documentElement.style.setProperty(
              '--primaryColor',
              `${this.currentUser.data.theme_data.color_palatte.primary}`
            );
            document.documentElement.style.setProperty(
              '--headerColor',
              `${this.currentUser.data.theme_data.color_palatte.web_header_color}`
            );
            document.documentElement.style.setProperty(
              '--tableRowColor',
              `${this.currentUser.data.theme_data.color_palatte.table_row_color}`
            );
            document.documentElement.style.setProperty(
              '--webHeaderTextColor',
              `${this.currentUser.data.theme_data.color_palatte.web_header_text_color}`
            );
          }
          this.router.navigate(['administrator/dashboard']);
          break;
        case 'CENTRAL_SUPPORT_EXECUTIVE':
        case 'TEAM_MANAGER':
          if (this.currentUser.data.theme_data !== null) {
            this.favIcon.href = this.currentUser.data.theme_data.fav_icon_path;
            document.documentElement.style.setProperty(
              '--primaryColor',
              `${this.currentUser.data.theme_data.color_palatte.primary}`
            );
            document.documentElement.style.setProperty(
              '--headerColor',
              `${this.currentUser.data.theme_data.color_palatte.web_header_color}`
            );
            document.documentElement.style.setProperty(
              '--tableRowColor',
              `${this.currentUser.data.theme_data.color_palatte.table_row_color}`
            );
            document.documentElement.style.setProperty(
              '--webHeaderTextColor',
              `${this.currentUser.data.theme_data.color_palatte.web_header_text_color}`
            );
          }
          this.router.navigate(['call-management']);
          break;
        default:
          break;
      }
    } else {
      if (!this.location.path().includes('/v?t=')) {
        this.router.navigate(['account/login']);
      }
    }
  }
}
