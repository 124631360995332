import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManageclientsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onGetClients(): Observable<object> {
    return this.httpClient.get('client');
  }


  onCreateUpdateClient(payload: object): Observable<object> {
    if (payload['id']) {
      return this.httpClient.put('client', payload);
    } else {
      return this.httpClient.post('client', payload);
    }
  }

  onGetClientsDropdown(searchKey: string): Observable<object> {
    return this.httpClient.post(
      'dashboard/client', { search_key: searchKey }
    )
  }

  onGetTeamsDropdown(payload: object): Observable<object> {
    return this.httpClient.post('dashboard/team', payload);
  }

  onGetUsersDropdown(payload: object) {
    return this.httpClient.post('dashboard/user', payload).pipe(
      map((res: any) => {
        const data = res['data'].map(obj => ({
          name: `${obj.first_name} ${obj.last_name}`,
          id: obj['_id']
        }))
        return data;
      })
    )
  }

  onGetClientsMetaData(id: string): Observable<object> {
    return this.httpClient.get(`client/id/${id}`);
  }
  onGetClientsToken(id: string): Observable<object> {
    return this.httpClient.get(`client/generate/token/${id}`);
  }

}
