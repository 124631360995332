import { UtilityService } from './../../../http/utility.service';
import { FeedbacksService } from './../../../http/feedbacks.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-callfeedback',
  templateUrl: './callfeedback.component.html',
  styleUrls: ['./callfeedback.component.scss']
})
export class CallfeedbackDialogComponent implements OnInit {

  public receivedDataObj = {
    callFrom: null,
    callTo: null,
    teamName: null,
    id: null,
    entry_type: null,
    auth_key: null,
    star_count: null
  };
  public feedbackForm: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<CallfeedbackDialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedDataObj,
    private feedbackSVC: FeedbacksService,
    private router: Router,
    private utilitySVC: UtilityService
  ) {
    this.feedbackForm = new UntypedFormGroup({
      id: new UntypedFormControl(),
      star_count: new UntypedFormControl(null, Validators.required),
      entry_type: new UntypedFormControl(null),
      comments: new UntypedFormControl('')
    })
    if (ReceivedDataObj !== null) {
      this.receivedDataObj = { ...this.receivedDataObj, ...ReceivedDataObj };
      this.feedbackForm.patchValue({
        id: this.receivedDataObj.id,
        entry_type: this.receivedDataObj.entry_type
      });
      if (this.receivedDataObj.entry_type === 'image' || this.receivedDataObj.entry_type === 'video') {
        this.feedbackForm.patchValue({
          star_count: this.receivedDataObj.star_count
        })
      }
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  onSubmitFeedbackForm(): void {
    this.utilitySVC.onSetCallStartedStatus(false);
    this.feedbackSVC.onSubmitFeedback(this.feedbackForm.value).subscribe(res => {
      if (this.receivedDataObj.entry_type === 'call') {
        this.router.navigate(['/call-management']);
      }
      this.close();
    })
  }

}
