<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Manage User</span>
    <i class="far fa-times-circle cp" (click)="close()"></i>
</h2>

<div class="scroll-holder">
    <form [formGroup]="userForm" (ngSubmit)="onCreateUpdateUser()">
        <div class="ion-form-group">
            <label class="label-text">Firstname</label>
            <input type="text" formControlName="first_name">
        </div>
        <div class="ion-form-group">
            <label class="label-text">Lastname</label>
            <input type="email" formControlName="last_name">
        </div>
        <div class="ion-form-group">
            <label class="label-text">Email</label>
            <input type="email" formControlName="email">
        </div>
        <div class="ion-form-group">
            <label class="label-text">Phone</label>
            <div class="d-flex">
                <div class="holder m-r-15">
                    <input ype="search" list="countryCodes" autocomplete="off" formControlName="country_code">
                    <datalist id="countryCodes">
                        <option *ngFor="let item of countryCodesList" [value]="item.dial_code">{{item.name}}</option>
                    </datalist>
                </div>
                <input type="text" formControlName="phone">
            </div>
        </div>
        <div class="ion-form-group" *ngIf="this.currentUser.data.user.role === 'SYS_ADMIN'">
            <label class="label-text">Clients</label>
            <select formControlName="client_id">
                <option [value]="client._id" *ngFor="let client of clientListData">{{ client.name }}</option>
            </select>
        </div>
        <div class="ion-form-group">
            <label class="label-text">Role</label>
            <select formControlName="role">
                <option [value]="role.value" *ngFor="let role of userRoles">{{ role.disValue }}</option>
            </select>
        </div>
        <div class="ion-form-group" *ngIf="receivedDataObj['_id']">
            <label class="label-text">Reset Password</label>
            <button class="ion-theme-btn primary round sm" type="button" (click)="onResendInvite(receivedDataObj['_id'])">Resend Invite</button>
        </div>
        <div class="d-flex justify-content-end">
            <button type="submit" [disabled]="!userForm.valid" class="ion-theme-btn primary">Submit</button>
        </div>
    </form>
</div>