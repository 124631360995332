<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Manage User</span>
    <i class="far fa-times-circle cp" (click)="close()"></i>
</h2>

<div class="scroll-holder">
    <form [formGroup]="userForm" (ngSubmit)="onCreateUpdateUser()">
        <div class="ion-form-group">
            <label class="label-text">Remark</label>
            <input type="text" formControlName="remark">
        </div>
        <div class="d-flex justify-content-end">
            <button type="submit" [disabled]="!userForm.valid" class="ion-theme-btn primary">Submit</button>
        </div>
    </form>
</div>