import { JobdetailsService } from './../../../http/jobdetails.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ViewuploadedimagevideoService } from '../../../http/viewuploadedimagevideo.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

import Hls from 'hls.js';

@Component({
  selector: 'app-viewcallinformationdialog',
  templateUrl: './viewcallinformationdialog.component.html',
  styleUrls: ['./viewcallinformationdialog.component.scss']
})
export class ViewCallInformationDialogComponent implements OnInit {

  public owlCarouselOption: OwlOptions = {
    loop: false,
    dots: false,
    navSpeed: 400,
    nav: false,
    items: 1,
    margin: 0,
    autoWidth: false
  };
  public receivedDataObj = {
    dialogTitle: null,
    data: null,
    type: null,
    sessionId: null,
    forwardLog: null,
    chat: null
  };
  public capturedImageBlobUrl = null;
  public recordedVideoBlobUrl = null;
  public currentImageIndex = 0;
  public listOfRecordings = [];
  public activeM3u8VideoURL = '';
  public currentVideoIndex = 0;
  public chatData = [];

  constructor(
    public dialogRef: MatDialogRef<ViewCallInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedDataObj,
    private viewImageVideoSVC: ViewuploadedimagevideoService,
    private domSanitizer: DomSanitizer,
    private jobDetailsSVC: JobdetailsService
  ) {
    if (ReceivedDataObj !== null) {
      this.receivedDataObj = { ...this.receivedDataObj, ...ReceivedDataObj };
      if (this.receivedDataObj.type === 'VIDEO' || this.receivedDataObj.type === 'IMAGE') {
        this.onRenderCapturedImageVideo(this.receivedDataObj.data[0], this.receivedDataObj.type);
      }
      if (this.receivedDataObj.sessionId !== null) {
        this.onViewCallRecordings(this.receivedDataObj.sessionId);
        if (this.receivedDataObj.type === 'CHAT') {
          this.onGetChatDetails(this.receivedDataObj.sessionId);
        }
      }
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
    this.receivedDataObj = {
      dialogTitle: null,
      data: null,
      type: null,
      sessionId: null,
      forwardLog: null,
      chat: null
    };
  }

  onRenderCapturedImageVideo(url: string, type: string): void {
    this.viewImageVideoSVC.onViewUploadedVideosImages(url.substring(1)).subscribe((res: any) => {
      if (type === 'IMAGE') {
        const blob = new Blob([res], { type: 'image/jpg' });
        const url = window.URL.createObjectURL(blob);
        this.capturedImageBlobUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
      } else {
        const blob = new Blob([res], { type: 'video/mp4' });
        const url = window.URL.createObjectURL(blob);
        this.recordedVideoBlobUrl = this.domSanitizer.bypassSecurityTrustUrl(url);
      }
    })
  }

  onNextPrevImages(type: string): void {
    if (type === 'next') {
      this.currentImageIndex++;
    } else {
      this.currentImageIndex--
    }
    this.onRenderCapturedImageVideo(this.receivedDataObj.data[this.currentImageIndex], 'IMAGE');
  }

  onViewCallRecordings(sessionId: string) {
    this.viewImageVideoSVC.onViewCallRecordings(sessionId)
      .subscribe(res => {
        this.listOfRecordings = res['data'];
        this.activeM3u8VideoURL = this.listOfRecordings[0];
        this.onLoadHLSVideoStream(this.activeM3u8VideoURL);
      });
  }

  onViewVideo(videoUrl: string) {
    this.activeM3u8VideoURL = videoUrl;
  }

  onNextPrevVideo(type: string): void {
    this.activeM3u8VideoURL = '';
    if (type === 'next') {
      this.currentVideoIndex++;
    } else {
      this.currentVideoIndex--
    }
    this.activeM3u8VideoURL = this.listOfRecordings[this.currentVideoIndex];
    this.onLoadHLSVideoStream(this.activeM3u8VideoURL);
  }

  onGetChatDetails(id: string): void {
    this.jobDetailsSVC.onGetChatDetails(id).subscribe(res => {
      this.chatData = [...res['data']];
    })
  }

  onLoadHLSVideoStream(m3U8String: string): void {
    if (Hls.isSupported()) {
      var video = document.getElementById('cloudRecordingVideo') as HTMLVideoElement;
      var hls = new Hls();
      hls.loadSource(m3U8String);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    }
  }

}
