<div class="ion-form-group" [ngClass]="{'inline-pciker': isInlinePicker}">
    <label *ngIf="labelText" class="label-text">{{ labelText }}</label>
    <ng-container *ngIf="calendarType === 'normal'">
        <input class="cp" matInput [(ngModel)]="value" [matDatepicker]="picker" (click)="picker.open();"
            (focusout)="onFocusDatePickerInput($event)" (dateChange)="onDatevalueChange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker">
            <i class="far fa-calendar-alt" matDatepickerToggleIcon></i>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </ng-container>
    <ng-container *ngIf="calendarType === 'range'">
        <div class="range-holder">
            <div class="input-holder">
                <mat-date-range-input [rangePicker]="rangePickerType" [formGroup]="range" [min]="min" [max]="max">
                    <input formControlName="from" matStartDate placeholder="Start date" (dateChange)="onRangeChange()">
                    <input formControlName="to" matEndDate placeholder="End date" (dateChange)="onRangeChange()">
                </mat-date-range-input>
            </div>
            <mat-datepicker-toggle matSuffix [for]="rangePickerType">
                <i class="far fa-calendar-alt" matDatepickerToggleIcon></i>
            </mat-datepicker-toggle>
            <mat-date-range-picker #rangePickerType></mat-date-range-picker>
        </div>
    </ng-container>
</div>