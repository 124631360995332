<section class="job-details-holder" *ngIf="jobDetailsData !== null">
  <h2 class="d-flex justify-content-between sub-section with-border m-t-0 m-b-15 align-items-center"
    style="font-weight: 400; text-transform: none">
    <span>
      History for Job Id: <b>{{ jobDetailsData.auth_key }}</b>
    </span>
    <bar-rating [readOnly]="true" [(rate)]="jobDetailsData.rating" [max]="5"></bar-rating>
  </h2>
  <table class="table ion-table">
    <thead>
      <tr>
        <th>Type</th>
        <th>Initiated By</th>
        <th>Time</th>
        <th>Answered By</th>
        <th>Duration</th>
        <th style="width: 18%">Info</th>
        <th>Rating</th>
        <th class="text-end" *ngIf="currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE'">
          Call Back
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of jobDetailsData.history.rows">
        <tr>
          <td>
            <ng-container [ngSwitch]="item.type">
              <ng-container *ngSwitchCase="'INCOMING_CALL'">Incoming Call</ng-container>
              <ng-container *ngSwitchCase="'OUTGOING_CALL'">Outgoing Call</ng-container>
              <ng-container *ngSwitchCase="'MISSED_CALL'">Missed Call</ng-container>
              <ng-container *ngSwitchCase="'IMAGE'">Image</ng-container>
              <ng-container *ngSwitchCase="'VIDEO'">Video</ng-container>
            </ng-container>
          </td>
          <td>
            {{ item.initiated_user.full_name }}<br />
            {{ item.initiated_user.user_name }}
          </td>
          <td>{{ item.time_stamp | date: "short" }}</td>
          <td>
            <ng-container *ngIf="
                item.type === 'INCOMING_CALL' || item.type === 'OUTGOING_CALL';
                else showNAAnswered
              ">
              <ng-container *ngIf="item.answered_user !== null; else showNaUnAswered">
                {{ item.answered_user !== null ? item.answered_user.full_name : "NA" }}<br />
                {{ item.answered_user !== null ? item.answered_user.user_name : "NA" }}
              </ng-container>
              <ng-template #showNaUnAswered>
                <p>NA</p>
              </ng-template>
            </ng-container>
            <ng-template #showNAAnswered>
              <p>NA</p>
            </ng-template>
          </td>
          <td>
            <ng-container *ngIf="
                item.type === 'INCOMING_CALL' || item.type === 'OUTGOING_CALL';
                else showNA
              ">
              {{ item.additional_informaton.duration.minute }} Min
              {{ item.additional_informaton.duration.second }} Sec</ng-container>
            <ng-template #showNA>
              <p>NA</p>
            </ng-template>
          </td>
          <td>
            <div class="view-actions">
              <ul class="action-items">
                <ng-container [ngSwitch]="item.additional_informaton.is_attended">
                  <ng-container *ngSwitchCase="true">
                    <li *ngIf="item.additional_informaton.chat_found">
                      <span class="ion-anchor" (click)="
                          onViewCallInformations('Chats', null, 'CHAT', item.id)
                        ">
                        Chat
                      </span>
                    </li>
                    <li *ngIf="
                        item.additional_informaton.artifact.recording.count > 0
                      ">
                      <span class="ion-anchor" (click)="
                          onViewCallInformations(
                            'View Recordings',
                            item.additional_informaton.artifact.recording.urls,
                            'RECORDINGS',
                            item.id
                          )
                        ">
                        {{
                        item.additional_informaton.artifact.recording.count
                        }}
                        Recording{{
                        item.additional_informaton.artifact.recording
                        .count === 1
                        ? ""
                        : "s"
                        }}
                      </span>
                    </li>
                    <li *ngIf="
                        item.additional_informaton.artifact.snapshot.count > 0
                      ">
                      <span class="ion-anchor" (click)="
                          onViewCallInformations(
                            'View Screenshots',
                            item.additional_informaton.artifact.snapshot.urls,
                            'SCREENSHOTS'
                          )
                        ">
                        {{
                        item.additional_informaton.artifact.snapshot.count
                        }}
                        Screenshot{{
                        item.additional_informaton.artifact.snapshot.count ===
                        1
                        ? ""
                        : "s"
                        }}
                      </span>
                    </li>
                  </ng-container>
                  <ng-container *ngSwitchCase="false">
                    <span style="color: red">Missed</span>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="
                    item.additional_informaton.artifact &&
                    item.additional_informaton.artifact.count > 0 &&
                    item.type === 'VIDEO'
                  ">
                  <li>
                    <span class="ion-anchor" (click)="
                        onViewCallInformations(
                          'View Video',
                          item.additional_informaton.artifact.urls,
                          'VIDEO'
                        )
                      ">
                      {{ item.additional_informaton.artifact.count }}
                      {{
                      item.additional_informaton.artifact.type === "defective"
                      ? "Issue"
                      : (item.additional_informaton.artifact.type
                      | titlecase)
                      }}{{
                      item.additional_informaton.artifact.count > 1
                      ? "s"
                      : ""
                      }}<br />
                      <span *ngIf="
                          item.additional_informaton.new_tag &&
                          item.additional_informaton.new_tag !== null
                        " style="
                          font-weight: 400;
                          display: block;
                          line-height: 18px;
                        ">{{ item.additional_informaton.new_tag }}
                      </span>
                    </span>
                  </li>
                </ng-container>
                <ng-container *ngIf="
                    item.additional_informaton.artifact &&
                    item.additional_informaton.artifact.count > 0 &&
                    item.type === 'IMAGE'
                  ">
                  <li>
                    <span class="ion-anchor" (click)="
                        onViewCallInformations(
                          'View Images',
                          item.additional_informaton.artifact.urls,
                          'IMAGE'
                        )
                      ">
                      {{ item.additional_informaton.artifact.count }}
                      {{
                      item.additional_informaton.artifact.type === "defective"
                      ? "Issue"
                      : (item.additional_informaton.artifact.type
                      | titlecase)
                      }}{{
                      item.additional_informaton.artifact.count > 1
                      ? "s"
                      : ""
                      }}<br />
                      <span *ngIf="
                          item.additional_informaton.new_tag &&
                          item.additional_informaton.new_tag !== null
                        " style="
                          font-weight: 400;
                          display: block;
                          line-height: 18px;
                        ">{{ item.additional_informaton.new_tag }}
                      </span>
                    </span>
                  </li>
                </ng-container>
                <ng-container *ngIf="item.forward_log.length > 0">
                  <li>
                    <span class="ion-anchor" (click)="
                        onViewCallInformations(
                          'Forward Log',
                          item.forward_log,
                          'CALLLOG'
                        )
                      ">
                      Forward Log
                    </span>
                  </li>
                </ng-container>
                <ng-container *ngIf="item.additional_informaton.comments.length > 0">
                  <li>
                    <span class="ion-anchor" (click)="
                        onViewCallInformations(
                          'Comments',
                          item.additional_informaton.comments,
                          'COMMENT'
                        )
                      ">
                      Comments
                    </span>
                  </li>
                </ng-container>
              </ul>
            </div>
          </td>
          <td>
            <div class="d-flex">
              <ng-container *ngIf="
                  ['INCOMING_CALL', 'OUTGOING_CALL'].includes(item.type) &&
                    !item.additional_informaton.is_attended;
                  else showRating
                ">
              </ng-container>
              <ng-template #showRating>
                <bar-rating [readOnly]="
                    currentUser.data.user.role === 'SYS_ADMIN' ||
                    currentUser.data.user.role === 'CLIENT_ADMIN'
                  " (rateChange)="
                    onOpenFeedbackFormDialog($event, item.id, item.type)
                  " [(rate)]="item.additional_informaton.rating" [max]="5"></bar-rating>
              </ng-template>
            </div>
          </td>
          <td *ngIf="currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE'">
            <app-initiatecallfromweb [auth_key]="jobDetailsPayload.auth_key"
              [callTo]="item['initiated_user']['full_name']" [targetUserId]="item['initiated_user']['id']"
              *ngIf="item.initiated_user.role === 'FIELD_SUPPORT_EXECUTIVE'"></app-initiatecallfromweb>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
  <div class="paginator-right">
    <mat-paginator
      (page)="handlePageEvent($event)"
      [length]="length"
      [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLastButtons"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="pageIndex"
      aria-label="Select page">
    </mat-paginator>
  </div>
</section>