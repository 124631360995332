import { FormGroup, FormControl } from '@angular/forms';
import { UsertokenService } from './../../shared/http/usertoken.service';
import { UserInfo } from 'src/app/routes/account/models/user.model';
import { CallfeedbackDialogComponent } from './../../shared/components/matdialogs/callfeedback/callfeedback.component';
import { Location } from '@angular/common';
import { ViewCallInformationDialogComponent } from '../../shared/components/matdialogs/viewcallinformationdialog/viewcallinformationdialog.component';
import { JobdetailsService } from './../../shared/http/jobdetails.service';
import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-jobdetails',
  templateUrl: './jobdetails.component.html',
  styleUrls: ['./jobdetails.component.scss']
})
export class JobdetailsComponent implements OnInit {

  public owlCarouselOption: OwlOptions = {
    loop: false,
    dots: false,
    navSpeed: 400,
    nav: false,
    items: 6,
    margin: 20,
    autoWidth: true
  };
  public jobDetailsPayload = {
    auth_key: null
  };
  public jobDetailsData = null;
  public feedbackDialogData = {
    callFrom: null,
    teamName: null,
    id: null,
    entry_type: 'call',
    auth_key: null,
    star_count: null
  };
  public currentUser: UserInfo;
  length = 0;
  pageSize = 20;
  pageIndex = 0;
  pageSizeOptions = [20];
  showFirstLastButtons = true;

  constructor(
    private jobDetailsSVC: JobdetailsService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public location: Location,
    private userTokenSVC: UsertokenService,
  ) {
    this.userTokenSVC.currentUser.subscribe((x) => (this.currentUser = x));
    this.activatedRoute.queryParams.subscribe(params => {
      this.jobDetailsPayload.auth_key = params.authKey;
    });
  }

  ngOnInit(): void {
    this.onGetJobDetails();
  }

  onGetJobDetails(): void {
    this.jobDetailsSVC.onGetJobDetails(this.jobDetailsPayload,this.pageIndex,this.pageSize).subscribe(res => {
      this.jobDetailsData = res['data'];
          this.length=this.jobDetailsData['pagination']['count'];
    this.pageSize=this.jobDetailsData['pagination']['size'];
    this.pageIndex=this.jobDetailsData['pagination']['page'];
    })

  }

  onViewCallInformations(title: string, data: Array<any>, type: string, sessionId: string = null): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = (type === 'VIDEO' || type === 'RECORDINGS' || type === 'CALLLOG') ? '60%' : '30%';
    dialogConfig.panelClass = 'ion-dialog-wrapper';
    dialogConfig.data = {
      dialogTitle: title,
      data,
      type
    };
    if (type === 'RECORDINGS' || type === 'CHAT') {
      dialogConfig.data.sessionId = sessionId;
    }
    if (type === 'CALLLOG') {
      dialogConfig.data.forwardLog = data;
    }
    this.dialog.open(ViewCallInformationDialogComponent, dialogConfig);
  }

  onOpenFeedbackFormDialog(event, id: string, type: string): void {
    this.feedbackDialogData.id = id;
    this.feedbackDialogData.entry_type = ['INCOMING_CALL', 'OUTGOING_CALL'].includes(type) ? 'call' : type.toLowerCase();
    this.feedbackDialogData.star_count = event;
    this.feedbackDialogData.auth_key = this.jobDetailsPayload.auth_key;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '50%';
    dialogConfig.panelClass = 'ion-dialog-wrapper';
    dialogConfig.data = this.feedbackDialogData;
    this.dialog.open(CallfeedbackDialogComponent, dialogConfig);
  }

 handlePageEvent(event: PageEvent) {
    this.length = event.length;
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.onGetJobDetails();
  }

}
