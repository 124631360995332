import { VerifyuserComponent } from './routes/verifyuser/verifyuser.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { JobdetailsComponent } from './routes/jobdetails/jobdetails.component';
import { MyexportsComponent } from './routes/myexports/myexports.component';

const routes: Routes = [
  { path: 'account', loadChildren: () => import('./routes/account/account.module').then(m => m.AccountModule) },
  { path: 'administrator', loadChildren: () => import('./routes/administrator/administrator.module').then(m => m.AdministratorModule) },
  { path: 'call-management', loadChildren: () => import('./routes/callmanagement/callmanagement.module').then(m => m.CallManagementModule) },
  { path: 'my-exports', component: MyexportsComponent },
  { path: 'job-details', component: JobdetailsComponent },
  { path: 'v', component: VerifyuserComponent },
  { path: '**', redirectTo: 'account/login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
