import { DashboardService } from './../../http/dashboard.service';
import { ManageusersService } from './../../http/manageusers.service';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Howl } from 'howler';
import { ChangelanguagedialogComponent } from './../matdialogs/changelanguagedialog/changelanguagedialog.component';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UtilityService } from './../../http/utility.service';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { UserInfo } from './../../../routes/account/models/user.model';
import { UsertokenService } from './../../http/usertoken.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public currentUser: UserInfo;
  public brandLogo = './assets/img/logo.png';
  public clientDetails = null;
  public teamDetails = null;
  public showMute = false;
  public decrypted: UserInfo['data']['user']

  // Incoming Call Alert Component Variables
  public firebaseCallQueueSubscription: Subscription;
  public firebaseOneToOneCallQueueSubscription: Subscription;
  public howlAudio = new Howl({
    src: ['./assets/audio/call-ring-alert.mp3'],
    loop: true,
  });
  public isCallStartedSubscription: Subscription;
  public isCallStarted = false;
  public activeFirebaseKeyUnattendedCallManipulation = '';
  public incomingCallQueue: any;
  public incomingCallQueueOneToOne: any;
  public incomingCallCount = 0;
  public incomingCallOneToOneCount = 0;
  public showhideCallQueue = false;
  public showhideOneToOneCallQueue = false;
  public associatedTeamList = [];
  public actionCenterData = {
    field_agent: 0,
  };
  public clientInfo = null;
  public waitingCallIds = [];

  constructor(
    private router: Router,
    private userTokenSVC: UsertokenService,
    private utilitySVC: UtilityService,
    public location: Location,
    public dialog: MatDialog,
    private fireRealTimeDB: AngularFireDatabase,
    private manageUserSVC: ManageusersService,
    private dashboardSVC: DashboardService
  ) {
    this.userTokenSVC.currentUser.subscribe((x) => (this.currentUser = x));
    this.decrypted=JSON.parse(atob(this.currentUser.data.user_token.split('.')[1]))
    if (this.decrypted.role !== 'SYS_ADMIN') {
      if (this.currentUser.data.theme_data !== null) {
        this.brandLogo = this.currentUser.data.theme_data.logo_path;
      }
    }
    this.isCallStartedSubscription = this.utilitySVC
      .onGetCallStartedStatus()
      .subscribe((res) => {
        this.isCallStarted = res;
        if (this.isCallStarted) {
          this.howlAudio.mute(true);
        } else {
          this.howlAudio.mute(false);
        }
      });
  }

  ngOnInit(): void {
    this.manageUserSVC.onGetAssociatedTeams().subscribe((res) => {
      this.clientInfo = res['data']['client'];
      this.associatedTeamList = res['data'].teams;
    });
    this.onGetActionCenterData();
    // this.firebaseOneToOneCallQueueSubscription = this.fireRealTimeDB
    this.fireRealTimeDB
      .list(`/quikstep-root/one_to_one/${this.decrypted._id}`)
      .snapshotChanges()
      .subscribe((oneToCallData) => {
        let callQueeDataReceived = Object.keys(oneToCallData).map((key) =>
          oneToCallData[key]['payload'].val()
        );
        const tempArray = [];
        if (callQueeDataReceived.length > 0) {
          for (let i = 0; i < callQueeDataReceived.length; i++) {
            if (callQueeDataReceived[i]['status'] === 'WAITING') {
              this.activeFirebaseKeyUnattendedCallManipulation =
                callQueeDataReceived[i]['firebase_key'];
              tempArray.push(callQueeDataReceived[i]);
              this.incomingCallQueueOneToOne = tempArray;
              this.incomingCallOneToOneCount = tempArray.length;
              if (tempArray.length > 0) {
                this.showhideOneToOneCallQueue = true;
              }
              this.showMute = false;
              this.howlAudio.mute(false);
              if (!this.isCallStarted) {
                this.howlAudio.play();
              }
            } else {
              if (tempArray.length === 0) {
                this.howlAudio.pause();
                this.incomingCallOneToOneCount = tempArray.length;
                this.showhideOneToOneCallQueue = false;
              }
            }
          }
        }
      });
    if (this.decrypted.role === 'CENTRAL_SUPPORT_EXECUTIVE') {
      // this.firebaseCallQueueSubscription = this.fireRealTimeDB
      this.fireRealTimeDB
        .list(
          `/quikstep-root/one_to_many/${this.decrypted.client_id}`
        )
        .snapshotChanges()
        .subscribe((callQueeData) => {
          let callQueeDataReceived = [];
          let filteredCallQueeData = [];
          let defaultTeams = this.associatedTeamList.filter(
            (team) => team.is_default
          );
          if (defaultTeams && defaultTeams.length > 0) {
            callQueeData.filter((y) => {
              if (y.key === defaultTeams[0]['_id']) {
                filteredCallQueeData.push(y);
              }
            });
          } else {
            for (const team of this.associatedTeamList) {
              callQueeData.filter((y) => {
                if (y.key === team._id) {
                  filteredCallQueeData.push(y);
                }
              });
            }
          }
          for (const item of filteredCallQueeData) {
            callQueeDataReceived = [
              ...callQueeDataReceived,
              ...Object.keys(item.payload.val()).map(
                (key) => item.payload.val()[key]
              ),
            ];
          }
          const tempArray = [];
          if (callQueeDataReceived.length > 0) {
            for (let i = 0; i < callQueeDataReceived.length; i++) {
              if (
                callQueeDataReceived[i]['status'] === 'WAITING' &&
                callQueeDataReceived[i]['forwarded_user_name'] !==
                  this.decrypted.user_name
              ) {
                this.activeFirebaseKeyUnattendedCallManipulation =
                  callQueeDataReceived[i]['firebase_key'];
                tempArray.push(callQueeDataReceived[i]);
                this.waitingCallIds.push(callQueeDataReceived[i]['session_id']);
                this.incomingCallQueue = tempArray;
                this.showhideCallQueue = true;
                this.incomingCallCount = tempArray.length;
                this.showMute = false;
                this.howlAudio.mute(false);
                if (!this.isCallStarted) {
                  this.howlAudio.play();
                }
              } else {
                if (tempArray.length === 0) {
                  this.incomingCallCount = tempArray.length;
                  this.howlAudio.pause();
                  this.showhideCallQueue = false;
                  if (this.location.path() === '/call-management') {
                    callQueeDataReceived.map((item) => {
                      if (
                        this.waitingCallIds.includes(item.session_id) &&
                        !item.accepted_user_id
                      ) {
                        this.utilitySVC.onSetRefreshMissedCallStatus(true);
                        this.waitingCallIds = [];
                      }
                    });
                  }
                }
              }
            }
          }
        });
    }
  }

  onLogout() {
    this.userTokenSVC.currentUserSubject.next(null);
    sessionStorage.removeItem('userInfo');
    localStorage.removeItem('firebase:host:quikstep-integration.firebaseio.com');
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = 'account/login';
  }

  onNavigateToLandingPage(): void {
    switch (this.decrypted.role) {
      case 'SYS_ADMIN':
      case 'CLIENT_ADMIN':
        this.router.navigate(['/administrator/dashboard']);
        break;
      case 'CENTRAL_SUPPORT_EXECUTIVE':
        this.router.navigate(['/call-management']);
        break;
      default:
        break;
    }
  }

  onMuteUnmuteCallerRing(): void {
    this.showMute = !this.showMute;
    if (!this.showMute) {
      this.howlAudio.mute(false);
    } else {
      this.howlAudio.mute(true);
    }
  }

  onOpenLanguageSelectionDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = '35%';
    dialogConfig.panelClass = 'ion-dialog-wrapper';
    this.dialog.open(ChangelanguagedialogComponent, dialogConfig);
  }

  // Incoming Call Alert Functions
  startCall(
    receivedKey: any,
    activeDriverName: string,
    receivedLatitude: number,
    receivedLongitude: number,
    onGoingCallsTeamId: string,
    transactionKey: string,
    teamName: string,
    driverUserFullname: string,
    subSessionId: string
  ) {
    this.howlAudio.pause();
    this.utilitySVC.onSetCallStartedStatus(true);
    this.router.navigate(['call-management/active-call'], {
      queryParams: {
        receivedKey: receivedKey,
        activeDriverName: activeDriverName,
        receivedLatitude: receivedLatitude,
        receivedLongitude: receivedLongitude,
        teamId: onGoingCallsTeamId,
        key: transactionKey,
        teamName: teamName,
        fullName: driverUserFullname,
        subSessionId,
      },
    });
  }

  onGetActionCenterData(): void {
    let payload = {
      client_id: this.decrypted.client_id,
    };
    this.dashboardSVC.onGetDashboardActionCenter(payload).subscribe((res) => {
      this.actionCenterData = { ...res['data'] };
    });
  }
  // Incoming Call Alert Functions

  ngOnDestroy(): void {}
}
