import { ManageusersService } from './../../../http/manageusers.service';
import { UsertokenService } from './../../../http/usertoken.service';
import { UserInfo } from './../../../../routes/account/models/user.model';
import { ToastrService } from 'ngx-toastr';
import { ManageexportsService } from './../../../http/manageexports.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ManageclientsService } from './../../../http/manageclients.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SystemusageService } from 'src/app/shared/http/systemusage.service';
import { json2csv, Json2CsvOptions } from 'json-2-csv';


@Component({
  selector: 'app-exportdatadialog',
  templateUrl: './exportdatadialog.component.html',
  styleUrls: ['./exportdatadialog.component.scss']
})
export class ExportdatadialogComponent implements OnInit {

  public currentUser: UserInfo;
  public clientList = [];
  public typAheadData = [];
  public exportPayload = {
    from: '',
    to: '',
    client_ids: [],
    team_ids: [],
    items: [],
    auth_keys: [],
    main_tags: [],
    sub_tags: []
  };
  public exportPayload1 = {
    from: '',
    to: '',
    team_names: [],
    transaction_ids: [],
    items: [],
    tag:""
  };
  public payloadItems = [
    { checked: false, name: 'Include incoming Calls', id: 'INCOMING_CALL' },
    { checked: false, name: 'Include outgoing Calls', id: 'OUTGOING_CALL' },
    { checked: false, name: 'Include missed Calls', id: 'MISSED_CALL' },
    { checked: false, name: 'Include uploaded videos', id: 'VIDEO' },
    { checked: false, name: 'Include uploaded images', id: 'IMAGE' }
  ];
  public associatedTeamList = [];
  public showExportErrorMessage = false;
  public showCientExportErrorMessage= false;
  public selectedClientsFromComponent = [];
  public selectedTeamsFromComponent = [];
  public getAllTagsPayload = {
    client_ids: [],
    parent_ids: []
  };
  public allMainTagListDropdown = [];
  public mainTagModel = null;
  public allSubTagListDropdown = [];
  public subTagModel = null;

  constructor(
    public dialogRef: MatDialogRef<ExportdatadialogComponent>,
    private manageClientsSVC: ManageclientsService,
    private manageExportSVC: ManageexportsService,
    private toastr: ToastrService,
    private userTokenSVC: UsertokenService,
    private manageUserSVC: ManageusersService,
    private systemUsageSVC: SystemusageService,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    
    this.userTokenSVC.currentUser.subscribe((x) => this.currentUser = x);
    this.exportPayload.client_ids = [];
    this.exportPayload.team_ids = [];
    

    delete dialogData.page_size;
    
    delete dialogData.start_index;
    
    delete dialogData.user_ids;
    
    delete dialogData.search_key;
    
    this.exportPayload = dialogData;
    this.exportPayload.team_ids = [];
    
    this.getAllTagsPayload.client_ids = [...this.exportPayload.client_ids];
    this.payloadItems.map(item => {
      if (this.exportPayload.items.indexOf(item.id) !== -1) {
        item.checked = true;
      }
    })
    
  }

  ngOnInit(): void {
    
            this.manageClientsSVC.onGetClientsDropdown('').subscribe(res => {
        this.clientList = [...res['data']];
        });

    this.manageUserSVC.onGetAssociatedTeams().subscribe((res) => {
      // this.clientInfo = res['data']['client'];
      this.associatedTeamList = res['data'].teams;
    });
    this.onGetClientsDropdown();
    
    this.onGetAllTags(true);
    
    if (this.exportPayload.main_tags.length > 0) {
      this.mainTagModel = JSON.stringify(this.exportPayload.main_tags);
    }
    
    if (this.exportPayload.sub_tags.length > 0) {
      this.getAllTagsPayload.parent_ids = [...this.exportPayload.main_tags];
      this.subTagModel = JSON.stringify(this.exportPayload.sub_tags);
      this.onGetAllTags(false);
    }
    
  }

  close() {
    this.dialogRef.close();
  }
    downloadCsvFile(csvString: string) {
      try{

      
      var currentdate = moment().format('YYYY-MM-DD_HH-mm-ss'); 
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `transactions-${currentdate}.csv`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    this.toastr.success(`CSV File has been Exported`);
      }catch(e){
        this.toastr.warning(`CSV File Export Error ${e}`);
      }
  }

  onDatePickerChange(event): void {
    this.exportPayload.from = moment(event.from).clone().format('YYYY-MM-DD');
    this.exportPayload.to = moment(event.to).clone().format('YYYY-MM-DD');
  }

  onGetClientsDropdown(): void {
    this.selectedClientsFromComponent = [];
    this.selectedTeamsFromComponent = [];
    if (this.currentUser.data.user.role === 'SYS_ADMIN') {
      this.manageClientsSVC.onGetClientsDropdown('').subscribe(res => {
        this.clientList = [...res['data']];
        this.clientList.map(item => {
          if (this.exportPayload.client_ids.indexOf(item._id) >= 0) {
            this.selectedClientsFromComponent.push(item);
          }
        });
        if (this.exportPayload.client_ids.length > 0) {
          let payload = {
            client_ids: [...this.exportPayload.client_ids],
            search_key: ''
          }
          
          this.manageClientsSVC.onGetTeamsDropdown(payload).subscribe(res => {
            let teamListData = [...res['data']];
            teamListData.map(item => {
              if (this.exportPayload.team_ids.indexOf(item.name) >= 0) {
                this.selectedTeamsFromComponent.push(item);
              }
            })
          })
          
        }
        if (this.exportPayload.client_ids.indexOf(this.clientList[0]['_id']) !== -1) {
          
          this.exportPayload.client_ids.push(this.clientList[0]['_id']);
        }
        
      })
    } else {
      
      if (this.currentUser.data.user.role === 'CLIENT_ADMIN') {
        this.manageClientsSVC.onGetClientsDropdown('').subscribe(res => {
        this.clientList = [...res['data']];
        });
        if (this.exportPayload.client_ids.length > 0) {
          let payload = {
            client_ids: [...this.exportPayload.client_ids],
            search_key: ''
          }
          this.manageClientsSVC.onGetTeamsDropdown(payload).subscribe(res => {
            let teamListData = [...res['data']];
            teamListData.map(item => {
              if (this.exportPayload.team_ids.indexOf(item.name) >= 0) {
                this.selectedTeamsFromComponent.push(item);
              }
            })
          });
        }
      }
      
      if (this.currentUser.data.user.role === 'CENTRAL_SUPPORT_EXECUTIVE' || this.currentUser.data.user.role === 'TEAM_MANAGER') {
        
        this.manageUserSVC.onGetAssociatedTeams().subscribe(res => {
          let teamListData = [...res['data']['teams']];
          teamListData.map(item => {
            if (this.exportPayload.team_ids.indexOf(item.name) >= 0) {
              this.selectedTeamsFromComponent.push(item);
            }
          })
          // res['data']['teams'].map(team => {
          //   if (this.exportPayload.team_ids.indexOf(team['name']) !== -1) {
          //     this.exportPayload.team_ids.push(team['name'])
          //   }
          // });
          
        })
      }
    }
  }

onGetSelectedClient(event: any): void {
  
  const clientId = event.target.value;
  // Clear the client_ids array before adding a new client ID
  this.exportPayload.client_ids = [];
  if (clientId) {
    // Add the new client ID to the array
    this.exportPayload.client_ids.push(clientId);
    // Ensure only one client ID is added by taking the first element if multiple IDs are present
    this.exportPayload.client_ids = [this.exportPayload.client_ids[0]];
  }
  
}

  onUserTypeAheadSearch(event: string, type: string): void {
    
    let payload = {
      client_ids: [...this.exportPayload.client_ids],
      search_key: event
    }
    
    switch (type) {
      case 'clients':
        this.manageClientsSVC.onGetClientsDropdown(event).subscribe(res => {
          this.typAheadData = [...res['data']];
        })
        break;
      case 'teams':
        
        if (this.currentUser.data.user.role === 'CLIENT_ADMIN' || this.currentUser.data.user.role === 'SYS_ADMIN') {
          this.manageClientsSVC.onGetTeamsDropdown(payload).subscribe(res => {
            this.typAheadData = [...res['data']];
          })
        } else {
          this.manageUserSVC.onGetAssociatedTeams().subscribe(res => {
            this.typAheadData = [...res['data']['teams']];
          });
        }
        
        break;
      default:
        break;
    }
  }

  onGetUserSelectedTypeAheadPills(event, type: string): void {
    
    let teamIds = [];
    let clientIds = [];
    switch (type) {
      
      case 'clients':
        
        for (const item of event) {
          clientIds.push(item['id']);
        }
        this.exportPayload.client_ids = [...clientIds];
        
        break;
        
      case 'teams':
        
        for (const item of event) {
          if (item['name']) {
            teamIds.push(item['name']);
          }
          if (item['name']) {
            teamIds.push(item['name']);
          }
        }
        this.exportPayload.team_ids = [...teamIds];
        
        break;
      default:
        break;
    }
  }

  onAddRemovePayloadItems(value: string): void {
    
    let addFlag: boolean;
    this.exportPayload.items.forEach((item) => {
      if (value === item) {
        addFlag = true;
      }
    });
    
    if (addFlag === true) {
      this.exportPayload.items = this.exportPayload.items.filter(item => item !== value);
      return;
    } else {
      this.exportPayload.items.push(value);
      return;
    }
    
  }

  onValidateExportRequest(): void {
if (this.exportPayload.client_ids.length > 0) {
  this.showCientExportErrorMessage = false;
  let client = this.clientList.find(data => data._id === this.exportPayload.client_ids[0]);
  
  const uniqueTeamNames = [...new Set(this.exportPayload.team_ids.map(name => name.trim()))];
  if (client) {
    let tocken = client.token;
    let Payload = {
      "t": tocken,
      "team_names": uniqueTeamNames,
      "transaction_ids": [],
      "from": this.exportPayload.from,
      "to": this.exportPayload.to,
      "items": [],
      "tag": ""
    }
    if(Payload.team_names.length==0){
      let teamnames= this.associatedTeamList.map((data)=>{
        return data.name;
      });
      Payload.team_names=teamnames;
    }
        this.manageExportSVC.onValidateExportRequest(Payload).subscribe(res => {
            const transactions = res['data']['transactions'];
            this.convertJsonToCsv(transactions);
      // if (res['data']['total_auth_key_count'] < 1000) {
      //   this.showExportErrorMessage = false;
      //   this.onExport();
      // } else {
      //   this.showExportErrorMessage = true;
      // }
          
    })
  }
}else{
      this.showCientExportErrorMessage=true
    }
  }

  onExport(): void {
    this.manageExportSVC.onRequestExport(this.exportPayload).subscribe(res => {
      this.close();
      this.toastr.success('Export initiated successfully!')
    })
  }

  async convertJsonToCsv(data: any) {
  try {
    const options: Json2CsvOptions = {
    delimiter: {
        field: ',', // Field delimiter
        wrap: '"', // Wrap delimiter
        eol: '\n' // End of line delimiter
    },
    unwindArrays: false, // Unwind array values
      keys: [
        { field: 'transaction_id', title: 'Transaction ID' },
        { field: 'type', title: 'Type' },
        { field: 'status', title: 'Status' },
        { field: 'user.first_name', title: 'First Name' },
        { field: 'user.last_name', title: 'Last Name' },
        { field: 'user.country_code', title: 'Country Code' },
        { field: 'user.phone', title: 'Phone' },
        { field: 'artifacts.recordings', title: 'Recordings' },
        { field: 'artifacts.screenshots', title: 'Screenshots' },
        { field: 'artifacts.chats', title: 'Chats' },
        { field: 'clients', title: 'Clients' },
        { field: 'teams', title: 'Teams' },
        { field: 'call_log.start_time', title: 'Call Start Time' },
        { field: 'call_log.end_time', title: 'Call End Time' },
        { field: 'call_log.duration', title: 'Call Duration' },
        { field: 'call_log.attended_participants', title: 'Call Attended Participants' },
        { field: 'additional_info.location', title: 'Location' },
        { field: 'additional_info.participant_count', title: 'Participant Count' },
        { field: 'artifacts.images', title: 'Images' },
        { field: 'additional_info.new_tag', title: 'New Tag' },
        { field: 'additional_info.end_user_comments.comment', title: 'End User Comments' },
        // { field: 'additional_info.feedbacks', title: 'Feedbacks' },
        { field: 'additional_info.uploaded_at', title: 'Uploaded At' },
        { field: 'artifacts.video', title: 'Video' },
        { field: 'additional_info.feedbacks.type', title: ' Customer Support Inputs' },
        // { field: 'additional_info.feedbacks.user.id', title: 'Feedback User ID' },
        { field: 'additional_info.feedbacks.user.user_name', title: 'CS Email' },
        { field: 'additional_info.feedbacks.user.full_name', title: 'Customer support_Or_Team Manager Full Name' },
        { field: 'additional_info.feedbacks.rating', title: 'CS Rating' },
        { field: 'additional_info.feedbacks.comment', title: 'CS Comment' },
        { field: 'additional_info.feedbacks.time_stamp', title: 'CS Time Stamp' },
        { field: 'additional_info.feedbacks.tags', title: 'CS Tags' }
      ],
      

};
    const csvString = await json2csv(data,options);
    
      this.downloadCsvFile(csvString); 
  } catch (error) {
    console.error('Error converting JSON to CSV:', error);
    this.toastr.warning(`CSV File Export Error ${error}`);
    throw error; // Optionally handle or rethrow the error
  }
}


  onGetAllTags(isParent: boolean): void {
    
    this.systemUsageSVC.onGetAllTags(this.getAllTagsPayload).subscribe(res => {
      if (isParent) {
        this.allMainTagListDropdown = [...res['data']];
      } else {
        this.allSubTagListDropdown = [...res['data']];
      }
    })
  }

  onStringifyTagIds(ids: Array<string>): string {
    
    return JSON.stringify(ids);
  }

  onChangeTag(isParent: boolean): void {
    
    if (isParent) {
      if (this.mainTagModel !== 'null') {
        
        let mainTags = JSON.parse(this.mainTagModel);
        this.getAllTagsPayload.parent_ids = [...mainTags];
        this.exportPayload.main_tags = [...mainTags];
        this.onGetAllTags(!isParent);
      } else {
        this.getAllTagsPayload.parent_ids = [];
        this.exportPayload.main_tags = [];
      }
    } else {
      if (this.subTagModel !== 'null') {
        let subTags = JSON.parse(this.subTagModel);
        this.exportPayload.sub_tags = [...subTags];
      } else {
        this.exportPayload.sub_tags = [];
      }
    }
  }

}
