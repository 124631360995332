<app-header *ngIf="isUserLoggedIn && location.path() !== '/account/reset-password' && !location.path().includes('v?t=')"></app-header>
<div class="container-fluid" [ngClass]="{'m-t-80': currentUser && (currentUser.data.user.role === 'SYS_ADMIN' || currentUser.data.user.role === 'CLIENT_ADMIN' || location.path().includes('/active-call'))}">
    <div class="row">
        <div class="col-lg">
            <div class="content-area" [ngClass]="{'p-t-0 p-b-0': location.path().includes('/call-management/active-call')}">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
<app-footer
    [ngClass]="{'with-bg': location.path().includes('/call-management/active-call')}"
    *ngIf="(isUserLoggedIn || location.path().includes('/v?t=')) && location.path() !== '/account/reset-password'"></app-footer>

<ngx-ui-loader></ngx-ui-loader>