import { Injectable } from '@angular/core';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { UsertokenService } from './usertoken.service';
import { AuthenticationService } from './authentication.service';
import { UserInfo } from './../../routes/account/models/user.model';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, throwError } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';

@Injectable()
export class AuthorisationInterceptor implements HttpInterceptor {

    public token = '';
    public currentUser: UserInfo;

    constructor(
        private userTokenSVC: UsertokenService,
        private toastr: ToastrService,
        private authSVC: AuthenticationService,
        private router: Router,
        private ngxService: NgxUiLoaderService,
        private location: Location
    ) {
        this.userTokenSVC.currentUser.subscribe((x) => (this.currentUser = x));
    }

    private refreshSubject: Subject<any> = new Subject<any>();
    private ifTokenExpired() {
        this.refreshSubject.subscribe({
            complete: () => {
                this.refreshSubject = new Subject<any>();
            }
        });
        if (this.refreshSubject.observers.length === 1) {
            // Hit refresh-token API passing the refresh token stored into the request
            // to get new access token and refresh token pair
            this.authSVC.onGetNewAccessToken().subscribe(this.refreshSubject);
        }
        return this.refreshSubject;
    }
    private checkTokenExpiryErr(error: HttpErrorResponse): boolean {
        return (error.status === 401);
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const apiReq = req.clone({
            url: `${environment.baseUrl}${req.url}`,
        });
        if (!req.url.includes('googleapis.com')) {
            this.ngxService.start();
            if (this.currentUser) {

                let cloned = req.clone({
                    url: `${environment.baseUrl}${req.url}`,
                    headers: req.headers.set(
                        'Authorization',
                        'Bearer ' + this.currentUser.data.access_token
                    ),
                });
                if(req.url.includes('transactions')){
                   cloned = req.clone({
                    url: `${environment.baseUrl}${req.url}`,
                }); 
                }
                return next.handle(cloned).pipe(
                    tap(
                        (event) => {
                            if (event instanceof HttpResponse) {
                                this.ngxService.stop();
                            }
                        },
                        (error: HttpErrorResponse) => {
                            this.ngxService.stop();
                            throw error;
                        }
                    ),
                    catchError((error: HttpErrorResponse) => {
                        if (error instanceof HttpErrorResponse) {
                            if (this.checkTokenExpiryErr(error)) {
                                return this.ifTokenExpired().pipe(
                                    switchMap(() => {
                                        this.ngxService.stop();
                                        return next.handle(this.updateHeader(req));
                                    })
                                );
                            }
                        }
                        if (!error.error.is_success) {
                            if (error.error.error === 'jwt expired') {
                                sessionStorage.removeItem('access_token');
                                sessionStorage.removeItem('userInfo');
                                sessionStorage.clear();
                                localStorage.removeItem('firebase:host:quikstep-integration.firebaseio.com');
                                localStorage.removeItem('selectedEntityId');
                                localStorage.clear();
                                this.userTokenSVC.currentUserSubject.next(null);
                                this.router.navigate(['account/login']);
                            }
                            this.toastr.error(error.error.error);
                        }
                        return throwError(error);
                    })
                );
            } else {
                return next.handle(apiReq).pipe(
                    tap(
                        (event) => {
                            if (event instanceof HttpResponse) {
                                this.ngxService.stop();
                            }
                        },
                        (error: HttpErrorResponse) => {
                            this.ngxService.stop();
                            throw error;
                        }
                    ),
                    catchError((error: HttpErrorResponse) => {
                        if (!error.error.is_success) {
                            if (this.location.path().includes('v?t=')) {
                                this.toastr.error('Activation link expired, please contact admin!');
                                sessionStorage.removeItem('access_token');
                                sessionStorage.removeItem('userInfo');
                                sessionStorage.clear();
                                localStorage.removeItem('firebase:host:quikstep-integration.firebaseio.com');
                                localStorage.removeItem('selectedEntityId');
                                localStorage.clear();
                                this.userTokenSVC.currentUserSubject.next(null);
                                this.router.navigate(['account/login']);
                            } else {
                                this.toastr.error(error.error.error);
                            }
                        }
                        return throwError(error);
                    })
                );
            }
        } else {
            this.ngxService.stop();
            return next.handle(req);
        }
    }

    updateHeader(req): any {
        const authToken = this.currentUser.data.access_token;
        req = req.clone({
            url: `${environment.baseUrl}${req.url}`,
            headers: req.headers.set('Authorization', `Bearer ${authToken}`)
        });
        return req;
    }

}