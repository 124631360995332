import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  AngularFireStorage,
  AngularFireUploadTask,
} from '@angular/fire/compat/storage';
import { ManageclientsService } from './../../../http/manageclients.service';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as countryCodes from '../../../countryCode.json';
import { UUID } from 'angular2-uuid';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manageclientsdialog',
  templateUrl: './manageclientsdialog.component.html',
  styleUrls: ['./manageclientsdialog.component.scss'],
})
export class ManageclientsdialogComponent implements OnInit {
  public receivedClientId = null;
  public countryCodesList: any;
  public disabled = false;
  public color: ThemePalette = 'primary';
  public touchUi = false;
  public clientForm: UntypedFormGroup;
  public firebaseFileUploadtask: AngularFireUploadTask;
  public clientMeta = null;
  public theme_data = null;

  constructor(
    public dialogRef: MatDialogRef<ManageclientsdialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedClientId,
    private fbSVC: UntypedFormBuilder,
    private manageClientSVC: ManageclientsService,
    private fireStorage: AngularFireStorage,
    private ngxLoaderSVC: NgxUiLoaderService,
    private toastr: ToastrService
  ) {
    this.clientForm = this.fbSVC.group({
      name: new UntypedFormControl(null, Validators.required),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      country_code: new UntypedFormControl(null, Validators.required),
      phone: new UntypedFormControl(null, Validators.required),
      description: new UntypedFormControl(null),
      logo_path: new UntypedFormControl(environment.logo_path),
      fav_icon_path: new UntypedFormControl(environment.fav_icon_path),
      square_logo_path: new UntypedFormControl(environment.square_logo_path),
      color_palatte: this.fbSVC.group({
        primary: new UntypedFormControl('#1da67c', Validators.required),
        primary_dark: new UntypedFormControl('#1da67c', Validators.required),
        secondary: new UntypedFormControl('#838383', Validators.required),
        secondary_light: new UntypedFormControl('#F2F2F2', Validators.required),
        secondary_dark: new UntypedFormControl('#262626', Validators.required),
        background: new UntypedFormControl('#EFF6FF', Validators.required),
        background_light: new UntypedFormControl('#E0EEFF', Validators.required),
        gradient_light: new UntypedFormControl('#4A85C5', Validators.required),
        gradient_dark: new UntypedFormControl('#254363', Validators.required),
        phone_text: new UntypedFormControl('#4A85C5', Validators.required),
        progress_border: new UntypedFormControl('#A2C4E8', Validators.required),
        settings_info_text: new UntypedFormControl('#A2C4E8', Validators.required),
        table_row_color: new UntypedFormControl('#EFF6FF', Validators.required),
        web_header_text_color: new UntypedFormControl('#1d5c8e', Validators.required),
        web_header_color: new UntypedFormControl('#FFFFFF', Validators.required),
      }),
    });
    if (ReceivedClientId !== null) {
      this.receivedClientId = ReceivedClientId;
      this.onGetClientsMetaData(this.receivedClientId);
    }
  }

  ngOnInit(): void {
    this.countryCodesList = countryCodes['default'];
  }

  close() {
    this.dialogRef.close();
  }

  onManageClient(): void {
    this.manageClientSVC
      .onCreateUpdateClient(this.clientForm.value)
      .subscribe((res) => {
        this.toastr.success('Client created successfully!');
        this.close();
      });
  }

  async onClientAssetsUpload(event: any, type: string) {
    this.ngxLoaderSVC.start();
    const file = event.target.files[0];
    if (file) {
      this.firebaseFileUploadtask = this.fireStorage.upload(
        `/ClientAssets/${file.name}`,
        file
      );
      (await this.firebaseFileUploadtask).ref.getDownloadURL().then(
        (url) => {
          if (type === 'logo') {
            this.clientForm.patchValue({
              logo_path: url,
            });
          } else if (type === 'square') {
            this.clientForm.patchValue({
              square_logo_path: url,
            });
          } else {
            this.clientForm.patchValue({
              fav_icon_path: url,
            });
          }
          this.ngxLoaderSVC.stop();
        },
        (error) => {
          this.ngxLoaderSVC.stop();
        }
      );
    }
  }

  onGetClientsMetaData(id: string): void {
    this.manageClientSVC.onGetClientsMetaData(id).subscribe((res) => {
      this.clientMeta = res['data']['meta'];
      this.theme_data = res['data']['theme_data'];
      this.clientForm.patchValue({
        name: this.clientMeta['name'],
        email: this.clientMeta['email'],
        country_code: this.clientMeta['country_code'],
        phone: this.clientMeta['phone'],
        description: this.clientMeta['description'],
        logo_path:
          this.theme_data !== null
            ? this.theme_data['logo_path']
            : environment.logo_path,
        fav_icon_path:
          this.theme_data !== null
            ? this.theme_data['fav_icon_path']
            : environment.fav_icon_path,
        square_logo_path:
          this.theme_data !== null
            ? this.theme_data['square_logo_path']
            : environment.square_logo_path,
        color_palatte: {
          primary:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['primary']
              : '#4A85C5',
          primary_dark:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['primary_dark']
              : '#32548A',
          secondary: '#838383',
          secondary_light: '#F2F2F2',
          secondary_dark: '#262626',
          background:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['background']
              : '#EFF6FF',
          background_light:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['background_light']
              : '#E0EEFF',
          gradient_light:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['gradient_light']
              : '#4A85C5',
          gradient_dark:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['gradient_dark']
              : '#254363',
          phone_text:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['phone_text']
              : '#4A85C5',
          progress_border:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['progress_border']
              : '#A2C4E8',
          settings_info_text:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['settings_info_text']
              : '#A2C4E8',
          table_row_color:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['table_row_color']
              : '#EFF6FF',
          web_header_text_color:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['web_header_text_color']
              : '#EFF6FF',
          web_header_color:
            this.theme_data !== null
              ? this.theme_data['color_palatte']['web_header_color']
              : '#EFF6FF',
        },
      });
      this.clientForm.addControl('id', new UntypedFormControl(this.clientMeta['_id']));
      this.clientForm.addControl(
        'active',
        new UntypedFormControl(this.clientMeta['active'])
      );
    });
  }
}
