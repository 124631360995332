import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManageauthkeysService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onValidateAuthKey(auth_key: string): Observable<object> {
    let payload = {
      auth_key,
      validate_user: true
    };
    return this.httpClient.post('authkey/validate', payload);
  }

}
