<footer>
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg">
                <div class="poweredby d-flex align-items-center justify-content-end">
                    <p class="m-b-0">Powered By</p>
                    <img src="./assets/img/logo2.png" class="img-fluid" alt="">
                </div>
            </div>
        </div>
    </div>
</footer>