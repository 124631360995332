import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-longarrowicon',
  template: `
      <svg xmlns="http://www.w3.org/2000/svg" width="21.28" height="12.087" viewBox="0 0 21.28 12.087">
      <defs>
          <style>
              .longArrowIcon {
                  fill: none;
                  stroke: #fff;
                  stroke-linecap: round;
                  stroke-linejoin: round;
                  stroke-width: 1.28px;
              }
          </style>
      </defs>
      <g transform="translate(0.64 0.905)">
          <path class="longArrowIcon" d="M66.636,54.177,61.4,49.038,66.636,43.9" transform="translate(-61.4 -43.9)" />
          <line class="longArrowIcon" x2="20" transform="translate(0 5.138)" />
      </g>
    </svg>
  `
})
export class LongarrowIconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
