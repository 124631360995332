export const environment = {
  production: false,
  environmentName: 'local',
  baseUrl: 'https://dev.quikstep.co/core/api/',
  corsurl: 'https://cors-anywhere.herokuapp.com/',
  firebase: {
    apiKey: "AIzaSyA-iMmbC-zYwqL3RUSrJAlbyrCDnkWEOQk",
    authDomain: "quikstep-development.firebaseapp.com",
    databaseURL: "https://quikstep-development-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "quikstep-development",
    storageBucket: "quikstep-development.appspot.com",
    messagingSenderId: "413617832180",
    appId: "1:413617832180:web:96d5c5d19b332db1e25ea1",
    measurementId: "G-8ZNR92KGXT"
},
  googleAPIMapKey: 'AIzaSyAQxny2RuJhCTLwfbOQy_zbTVfS8kCqXIQ',
  agorAppId: '2ea336f50f064d63909bd0b699ddbcb8',
  logo_path:
    'https://firebasestorage.googleapis.com/v0/b/ionassist-integration.appspot.com/o/ClientAssets%2Fion-logo.svg?alt=media&token=6fb120e2-7453-4c44-bb24-0e0f1dbdff53',
  fav_icon_path:
    'https://firebasestorage.googleapis.com/v0/b/ionassist-integration.appspot.com/o/ClientAssets%2Fion-favicon.png?alt=media&token=31372167-048e-4ef6-9cfa-ee88463e37df',
  square_logo_path:
    'https://firebasestorage.googleapis.com/v0/b/ionassist-integration.appspot.com/o/ClientAssets%2Fion-square.png?alt=media&token=4757219b-c3b7-4466-bc4e-68a1d23ee80c',
};
