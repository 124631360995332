import { UsertokenService } from './../../../http/usertoken.service';
import { UserInfo } from './../../../../routes/account/models/user.model';
import { ManageteamsService } from './../../../http/manageteams.service';
import { ManagetransactionsService } from './../../../http/managetransactions.service';
import { ManageclientsService } from './../../../http/manageclients.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-manageunmappedtrandialog',
  templateUrl: './manageunmappedtrandialog.component.html',
  styleUrls: ['./manageunmappedtrandialog.component.scss'],
})
export class ManageunmappedtrandialogComponent implements OnInit {
  public receivedDataObj = null;
  public forwardVideoAndImageForm: UntypedFormGroup;
  public clientListDropdown = [];
  public selectedAuthKey = null;
  public isUpdateAuthKeyEnabled = true;
  public clientTeamListDropdown = [];
  public currentUser: UserInfo;

  constructor(
    public dialogRef: MatDialogRef<ManageunmappedtrandialogComponent>,
    @Inject(MAT_DIALOG_DATA) ReceivedDataObj,
    private toastr: ToastrService,
    private manageClientsSVC: ManageclientsService,
    private manageTransSVC: ManagetransactionsService,
    private manageTeamsSVC: ManageteamsService,
    private userTokenSVC: UsertokenService
  ) {
    this.userTokenSVC.currentUser.subscribe((x) => (this.currentUser = x));
    this.forwardVideoAndImageForm = new UntypedFormGroup({
      auth_key: new UntypedFormControl(null, Validators.required),
      id: new UntypedFormControl(null),
      to_client_id: new UntypedFormControl(null, Validators.required),
      to_team_id: new UntypedFormControl(''),
    });
    if (ReceivedDataObj !== null) {
      this.receivedDataObj = { ...ReceivedDataObj };
      this.forwardVideoAndImageForm.patchValue({
        auth_key: this.receivedDataObj['authKey'],
        id: this.receivedDataObj['id'],
      });
      if (this.currentUser.data.user.role === 'CLIENT_ADMIN') {
        this.forwardVideoAndImageForm.patchValue({
          to_client_id: this.currentUser.data.user.client_id,
        });
        this.onValidateAuthKeyForVideoTransfer(
          this.receivedDataObj['authKey'],
          this.receivedDataObj['id']
        );
      }
    }
  }

  ngOnInit(): void {
    this.onGetClientsDropdown();
    
  }

  close(isData: boolean) {
    this.dialogRef.close(isData);
  }

  onGetClientsDropdown(): void {
    this.manageClientsSVC.onGetClientsDropdown('').subscribe((res) => {
      this.clientListDropdown = [...res['data']];
      this.sortByName(this.clientListDropdown);
    });
    console.log(this.clientListDropdown);
    this.onValidateAuthKeyForVideoTransfer(
      this.receivedDataObj['authKey'],
      this.receivedDataObj['id']
    );
  }
  sortByName(data): void {
    data.sort((a, b) => a.name.localeCompare(b.name));
    console.log(data);
  }

  onValidateUpdatedAuthKey(): void {
    this.onValidateAuthKeyForVideoTransfer(
      this.forwardVideoAndImageForm.get('auth_key').value,
      this.receivedDataObj['id']
    );
  }

  onValidateAuthKeyForVideoTransfer(key: string, videoId: string): void {
    this.manageTransSVC
      .onValidateAuthKeyForVideoTransfer({
        key,
      })
      .subscribe((res) => {
        this.selectedAuthKey = key;
        this.forwardVideoAndImageForm.patchValue({
          auth_key: key,
          id: videoId,
        });
        if (res['data']['client'] !== null) {
          this.forwardVideoAndImageForm.patchValue({
            to_client_id: res['data']['client']['_id'],
          });
          this.clientTeamListDropdown = [];
          this.onChangeClientDropdown(res['data']['client']['_id']);
          if (res['data']['team'] !== null) {
            this.forwardVideoAndImageForm.patchValue({
              to_team_id: res['data']['team']['_id'],
            });
          }
          this.isUpdateAuthKeyEnabled = false;
          this.forwardVideoAndImageForm.controls['to_client_id'].enable();
          this.forwardVideoAndImageForm.controls['to_team_id'].enable();
        } else {
          if (this.currentUser.data.user.role === 'SYS_ADMIN') {
            this.forwardVideoAndImageForm.controls['to_client_id'].enable();
            this.forwardVideoAndImageForm.controls['to_team_id'].enable();
            this.forwardVideoAndImageForm.patchValue({
              to_client_id: null,
              to_team_id: '',
            });
          }
        }
      });
  }

  onChangeAuthKey(event: any): void {
    if (event.target.value !== this.selectedAuthKey) {
      this.isUpdateAuthKeyEnabled = false;
    } else {
      this.isUpdateAuthKeyEnabled = true;
    }
  }

  onChangeClientDropdown(clientId: string) {
    this.clientTeamListDropdown = [];
    this.manageTeamsSVC.onGetTeams(clientId).subscribe((res) => {
      this.clientTeamListDropdown = res['data'];
      this.sortByName(this.clientTeamListDropdown);
    });
  }

  onForwardVideoAndImage(): void {
    this.manageTransSVC
      .onForwardVideoAndImage(
        this.forwardVideoAndImageForm.value,
        this.receivedDataObj['type']
      )
      .subscribe((res) => {
        let type = this.receivedDataObj['type'] === 'video' ? 'Video' : 'Image';
        this.toastr.success(`${type} forwarded to the selected entity`);
        this.forwardVideoAndImageForm.reset();
        this.close(true);
      });
  }
}
