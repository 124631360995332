import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ViewuploadedimagevideoService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onViewUploadedVideosImages(apiUrl: string): Observable<object> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-disposition', 'attachment');
    return this.httpClient.get(apiUrl, {
      headers: headers,
      responseType: 'arraybuffer'
    });
  }

  onViewCallRecordings(callId: string) {
    return this.httpClient.get(`session/id/${callId}/record`);
  }

}
