import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ManageexportsService {

  constructor(
    private httpClient: HttpClient
  ) { }

  onGetExportList(): Observable<object> {
    return this.httpClient.get('session/me/history/export');
  }

  onRequestExport(payload: object): Observable<object> {
    return this.httpClient.post('session/history/export/request', payload);
  }

  // old export api
  //   onValidateExportRequest(payload: object): Observable<object> {
  //   return this.httpClient.post('session/history/export/auth_key/count', payload);
  // }

  onValidateExportRequest(payload: object): Observable<object> {
    return this.httpClient.post('integration/transactions', payload);
  }

  onDownloadExport(id: string) {
    return this.httpClient.get(`session/history/export/id/${id}/download`);
  }

}
