<h2 class="section-title with-border sm text-uppercase d-flex align-items-center justify-content-between p-l-0">
    <span>Export Data</span>
    <i class="far fa-times-circle cp" (click)="close()"></i>
</h2>

<div class="">
    <div class="row">
        <div class="col-lg-5">
            <app-custommatdatepicker [labelText]="'Choose Date Range'" [from]="exportPayload.from"
                [to]="exportPayload.to" [calendarType]="'range'" (rangeChange)="onDatePickerChange($event)" [dateRangeRestricted]="true">
            </app-custommatdatepicker>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6" *ngIf="currentUser.data.user.role === 'SYS_ADMIN'">
            <app-iontypeahead [isStacked]="true" [dropDownData]="typAheadData" [keyToSend]="'_id'"
            [maxSelections]="1"
            (userSelectedPills)="onGetUserSelectedTypeAheadPills($event, 'clients')"
            (userTypeAheadKey)="onUserTypeAheadSearch($event, 'clients')" [labelText]="'Clients'"
                [placeHolderString]="'Search Clients'" [existPills]="selectedClientsFromComponent" ></app-iontypeahead>
        </div>
        <div class="col-lg-6">
            <app-iontypeahead [isStacked]="true" [dropDownData]="typAheadData" [keyToSend]="'name'"
                (userSelectedPills)="onGetUserSelectedTypeAheadPills($event, 'teams')"
                [isEnableClearAll]="true"
                (userTypeAheadKey)="onUserTypeAheadSearch($event, 'teams')" [labelText]="'Teams'"
                [placeHolderString]="'Search Teams'" [existPills]="selectedTeamsFromComponent">
            </app-iontypeahead>
        </div>
    </div>
    <!-- main tag sub tag -->
    <!-- <div class="row">
        <div class="col-lg-4">
            <div class="ion-form-group">
                <label class="label-text">Main Tag</label>
                <select (change)="onChangeTag(true)" [(ngModel)]="mainTagModel">
                    <option value="null">Select Main Tag</option>
                    <option [value]="onStringifyTagIds(maintag.ids)" *ngFor="let maintag of allMainTagListDropdown">{{
                        maintag.name | titlecase }}</option>
                </select>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="ion-form-group">
                <label class="label-text">Sub Tag</label>
                <select [(ngModel)]="subTagModel" (change)="onChangeTag(false)">
                    <option value="null">Select Sub Tag</option>
                    <option [value]="onStringifyTagIds(subtag.ids)" *ngFor="let subtag of allSubTagListDropdown">{{
                        subtag.name | titlecase }}</option>
                </select>
            </div>
        </div>
    </div> -->
    <!-- <div class="row">
        <div class="col-lg">
            <ul class="d-flex item-checkboxes flex-wrap">
                <li *ngFor="let check of payloadItems">
                    <mat-checkbox [checked]="check.checked" (click)="onAddRemovePayloadItems(check.id)">{{ check.name }}
                    </mat-checkbox>
                </li>
            </ul>
        </div>
    </div> -->
    <div class="d-flex justify-content-end m-t-10 align-items-center">
        <p class="m-b-0 records-count m-r-20" *ngIf="showExportErrorMessage">
            <span style="color: red;">Please change your filter criteria. Max transactions that can be exported at 1
                time is 1000</span>
        </p>
        <p class="m-b-0 records-count m-r-20" *ngIf="showCientExportErrorMessage">
            <span style="color: red;">Please Select The Client</span>
        </p>
        <button class="ion-theme-btn primary sm" type="button"
            [disabled]="exportPayload.from === '' || exportPayload.from === 'Invalid date' || exportPayload.to === '' || exportPayload.to === 'Invalid date' "
            (click)="onValidateExportRequest()">Export</button>
    </div>
</div>