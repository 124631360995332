import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localsearchfilterpipe'
})
export class LocalsearchfilterpipePipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    return items.filter((it: string) => {
      return it['name'].toLowerCase().includes(searchText);
    });
  }

}
